import { LoadingButton } from '@mui/lab';
import { Box, Divider, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import * as xlsx from 'xlsx';
import { MatchProperties } from '../../../../common/components/DataSync/MatchProperties/MatchProperties';
import { Dropzone } from '../../../../common/components/Dropzone/Dropzone';
import { FSModal } from '../../../../common/components/modal/Modal';
import { CommonEventNames } from '../../../../common/constants/events/common';
import { DataSyncStrings, ImportFileModalStrings } from '../../../../common/localization/en';
import { useTrackEvent } from '../../../../hooks/useTrackEvent';
import { getFileExtension } from '../../../../services/utilities';
import { useAppDispatch, useTypedSelector } from '../../../../store';
import { useStyles } from './ImportFileModal.styles';

// import ImportModalExceedLimit from '../../../../common/components/DataSync/MatchProperties/ImportModalExceedLimit';
// import { useModal } from '../../../../hooks/useModal';
import { findMatch, getValidationSchema } from '../../../webappSettings/DataSync/DataSync.helper';
import {
  getActiveInvitations,
  getCampaignCountData,
  getCampaignInvitations,
  getUnsentInvitations,
} from '../../../../store/slices/campaignsSlice';
import { SMSInvitationType } from '../../../../common/components/SMSInvitationTypeSelector/SMSInvitationTypeSelector';
import { ReviewAndFinalize } from '../../../../common/components/DataSync/ReviewAndFinalize/ReviewAndFinalize';
import Navigation from './Navigation';
import { ToggleBlock } from '../../../../common/components/ToggleBlock/ToggleBlock';
import importFileExample from './assets/import_file_example.png';
import { SuccessScreen } from '../../../../common/components/DataSync/SuccessScreen/SuccessScreen';

export type ImportRecord = {
  phoneNumber: string;
  firstName: string;
  lastName: string;
  transactionDate?: string;
};

export type InvalidImportRecord = {
  record: ImportRecord;
  reason: string;
  tip?: string;
};

interface ImportFileModalProps {
  isOpen: boolean;
  onClose: () => void;
  onFinish?: () => void;
  campaignId: string;
}

export const ImportFileModal: FC<ImportFileModalProps> = ({
  isOpen,
  onClose,
  campaignId,
  onFinish,
}) => {
  const classes = useStyles();
  const { isLoading } = useTypedSelector((state) => state.dataSyncSlice.data);

  const { trackEvent } = useTrackEvent();
  const dispatch = useAppDispatch();
  const { addToast } = useToasts();

  // const {
  //   isOpen: isImportEventsModalExceedLimit,
  //   close: closeImportEventsModalExceedLimit,
  //   open: openImportEventsModalExceedLimit,
  // } = useModal();

  const [uploadedCSVHeaders, setUploadedCSVHeaders] = useState<string[] | null>(null);
  const [uploadedCSVData, setUploadedCSVData] = useState<string[][] | null>(null);
  const [step, setStep] = useState(0);
  const [isLimitError, setIsLimitError] = useState(false);

  const { page, size, sort } = useTypedSelector(
    (state) => state.campaigns.campaignUnsentInvitations,
  );

  const validationSchema = getValidationSchema();

  const formik = useFormik({
    initialValues: {
      firstName: uploadedCSVHeaders ? findMatch(uploadedCSVHeaders, 'firstName') : '',
      lastName: uploadedCSVHeaders ? findMatch(uploadedCSVHeaders, 'lastName') : '',
      cell: uploadedCSVHeaders ? findMatch(uploadedCSVHeaders, 'cell') : '',
      smsInvitationType: SMSInvitationType.SAVE_FOR_LATER,
      transactionDate: uploadedCSVHeaders ? findMatch(uploadedCSVHeaders, 'transactionDate') : '',
    },
    enableReinitialize: true,
    onSubmit: () => {},
    validationSchema: validationSchema,
    validateOnChange: true,
  });
  const getFields = useMemo(() => {
    const fieldsEntries = Object.entries(validationSchema.describe().fields);

    return [
      fieldsEntries
        .filter(
          ([key, _]) =>
            (validationSchema.describe().fields[key] as any).tests.findIndex(
              ({ name }: { name: string }) => name === 'required',
            ) >= 0,
        )
        .map((el) => el[0]),
      fieldsEntries
        .filter(([key, _]) => (validationSchema.describe().fields[key] as any).tests.length === 0)
        .map((el) => el[0]),
    ];
  }, [validationSchema]);
  const getRecords = useCallback((): ImportRecord[] => {
    if (!uploadedCSVData || !uploadedCSVHeaders) {
      return [];
    }
    const findCorrespondingHeaderIndex = (name: string) => uploadedCSVHeaders.indexOf(name);

    const result = (uploadedCSVData[uploadedCSVData.length - 1].length === 1
      ? uploadedCSVData.slice(0, -1)
      : uploadedCSVData
    ).map((row: string[]) => {
      return {
        phoneNumber: formik.values.cell
          ? row[findCorrespondingHeaderIndex(formik.values.cell)]
          : '',
        firstName: Boolean(formik.values.firstName)
          ? row[findCorrespondingHeaderIndex(formik.values.firstName as string)]
          : '',
        lastName: formik.values.lastName
          ? row[findCorrespondingHeaderIndex(formik.values.lastName)]
          : '',
        transactionDate: formik.values.transactionDate
          ? row[findCorrespondingHeaderIndex(formik.values.transactionDate)]
          : '',
      };
    });

    return result;
  }, [uploadedCSVData, uploadedCSVHeaders, formik.values]);

  const parseCsv = (csvData: string) => {
    const lines = csvData.trim().split('\n');
    const MAX_FILE_ROWS = 301;

    if (lines.length > MAX_FILE_ROWS) {
      setIsLimitError(true);
      return;
    }

    const csvPattern = /(?:,|\r?\n|^)(?:"([^"]*)"|([^",]*))/g;
    const [headers, ...tableRows] = lines.map((line) => {
      const result = [];
      let match;
      while ((match = csvPattern.exec(line)) !== null) {
        result.push(match[1] || match[2] || '');
      }
      return result;
    });

    setUploadedCSVHeaders(headers);
    setUploadedCSVData(tableRows);
  };

  const onDrop = useCallback(
    (acceptedFiles) => {
      trackEvent(CommonEventNames.data_sync_file_uploaded, { value: acceptedFiles });
      setIsLimitError(false);

      const file = acceptedFiles[0];
      const fileExtension = getFileExtension(file.name);

      const isExcelFile = fileExtension ? ['xlsx', 'xls'].includes(fileExtension) : false;
      const isCsv = file.type === 'text/csv';

      if (!isExcelFile && !isCsv) {
        addToast(DataSyncStrings.SelectedWrongFileType, {
          appearance: 'error',
          autoDismiss: true,
        });
        return;
      }

      const reader = new FileReader();
      reader.readAsBinaryString(file);
      reader.onload = function (e: any) {
        const data = e.target.result;

        let csvData = data;

        // convert excel file to csv
        if (isExcelFile) {
          const wb = xlsx.read(data, { type: 'binary' });
          const ws = wb.Sheets[wb.SheetNames[0]]; // Get first worksheet
          csvData = xlsx.utils.sheet_to_csv(ws, { forceQuotes: true });
        }

        parseCsv(csvData);
      };

      return file;
    },
    [addToast, trackEvent],
  );

  const resetState = () => {
    setStep(0);
    setUploadedCSVData(null);
    setUploadedCSVHeaders(null);
    setIsLimitError(false);
  };

  useEffect(() => {
    return () => {
      resetState();
    };
  }, []);

  return (
    <FSModal
      onClose={() => {
        onClose();
        resetState();
      }}
      modalIsOpen={isOpen}
      width="640px"
      rounded="15px"
      closeBtn
      padding="0"
    >
      <Box className={classes.inputsWrapper}>
        <Typography className={classes.title}>{ImportFileModalStrings.Title}</Typography>
        <Box className={classes.stepper}>
          <Navigation step={step} />
        </Box>

        <Box className={classes.content}>
          {step === 0 && (
            <>
              <Box mx="24px" mb="10px">
                <Typography fontWeight="500" textAlign="start" fontSize="16px" mb="9px">
                  {ImportFileModalStrings.InfoA}
                  <b>{ImportFileModalStrings.InfoB}</b>
                  {ImportFileModalStrings.InfoC}
                </Typography>
                <Typography fontSize="16px" mb="5px">
                  {ImportFileModalStrings.FileExampleTitle}
                </Typography>
                <Box my="5px">
                  <ToggleBlock>
                    <img src={importFileExample} alt="Import file example" />
                  </ToggleBlock>
                </Box>

                <Box
                  component="ul"
                  sx={{
                    listStyleType: 'disc',
                    pl: '24px',
                    fontSize: '14px',
                  }}
                >
                  {[
                    {
                      key: 'First Name',
                      value: '(text format)',
                    },
                    {
                      key: 'Last Name',
                      value: '(text format)',
                    },
                    {
                      key: 'Cell Phone',
                      value: '(U.S. and International; text format)',
                    },
                    {
                      key: 'Transaction Date',
                      value: '(MM/DD/YYYY)',
                    },
                  ].map(({ key, value }) => (
                    <li>
                      <Box component="span" fontWeight="700">
                        {key}
                      </Box>{' '}
                      <Box component="span" fontWeight="400">
                        {value}
                      </Box>
                    </li>
                  ))}
                </Box>
                <Dropzone
                  onDrop={onDrop}
                  accept={['excel', 'csv']}
                  borderRadius={'10px'}
                  height="177px"
                  title={ImportFileModalStrings.DropzoneTitle}
                  description={ImportFileModalStrings.DropzoneDesc}
                  invalidFileMessage={ImportFileModalStrings.InvalidFileMessage}
                  isError={isLimitError}
                  errorComponent={
                    <Box className={classes.errorWrapper}>
                      <Typography className={classes.errorTitle}>
                        {ImportFileModalStrings.ErrorTitle}
                      </Typography>
                      <Typography className={classes.errorText}>
                        {ImportFileModalStrings.ErrorText}
                      </Typography>
                      <Typography className={classes.errorInfo}>
                        <span className={classes.tryAgain}>
                          {ImportFileModalStrings.PleaseTryAgain}
                        </span>
                        {ImportFileModalStrings.FewerCustomers}
                      </Typography>
                    </Box>
                  }
                />
              </Box>

              <Divider color="#DCDBE4" />

              <Box style={{ margin: '20px 24px 18px' }}>
                <LoadingButton
                  disabled={!uploadedCSVData}
                  variant="contained"
                  color="primary"
                  size="large"
                  style={{ borderRadius: '8px' }}
                  fullWidth
                  onClick={() => {
                    trackEvent(CommonEventNames.add_users_manually_import_data_button_click);
                    setStep(1);
                  }}
                  id="match-properties-button"
                >
                  {ImportFileModalStrings.NextMatchPropertiesBtn}
                </LoadingButton>
              </Box>
            </>
          )}
          {step === 1 && (
            <MatchProperties
              isLoading={isLoading}
              onImportClick={() => {
                setStep(2);
                trackEvent(CommonEventNames.data_sync_next_review_click);
              }}
              formik={formik}
              uploadedCSV={uploadedCSVHeaders ?? []}
              getFields={getFields}
              records={getRecords()}
              quickStartLayout
              moveToPrevStep={() => {}}
              newCampaignLayoutMenuImport
            />
          )}
          {step === 2 && (
            <ReviewAndFinalize
              records={getRecords()}
              onStartOverClick={() => {
                resetState();
              }}
              campaignId={campaignId}
              onImportFinished={() => {
                dispatch(getCampaignCountData({ campaignId }));
                dispatch(getUnsentInvitations({ campaignId, pageable: { page, size, sort } }));
                dispatch(getActiveInvitations({ campaignId }));
                dispatch(getCampaignInvitations({ campaignId }));
                setStep(3);
              }}
            />
          )}

          {step === 3 && (
            <SuccessScreen
              onFinishClick={() => {
                resetState();
                onClose();
                onFinish?.();
              }}
            />
          )}
        </Box>
      </Box>
    </FSModal>
  );
};
