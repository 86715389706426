import { Box, Typography } from '@mui/material';
import React from 'react';
import { CampaignInvitation } from '../../../../../../../../api/models/campaigns';
import { useReviewInvitesTableStyles } from './ReviewInvitesTable.styles';
import { formatPhoneNumber } from '../../../../../../../../services/utilities';
import formatDate from 'date-fns/format';

interface ReviewInvitesTableProps {
  invites: CampaignInvitation[];
}

export const ReviewInvitesTable: React.FC<ReviewInvitesTableProps> = ({ invites }) => {
  const classes = useReviewInvitesTableStyles();
  return (
    <Box>
      <TableHeader />
      <Box className={classes.tableBodyContainer}>
        {invites.map((invite) => (
          <TableRow key={invite.id} invite={invite} />
        ))}
      </Box>
    </Box>
  );
};

const TableCell: React.FC = ({ children }) => {
  const classes = useReviewInvitesTableStyles();
  return <Box className={classes.tableCell}>{children}</Box>;
};

const TableHeader: React.FC = () => {
  const classes = useReviewInvitesTableStyles();
  return (
    <Box className={classes.tableRowContainer}>
      {['First Name', 'Last Name', 'Cell Phone', 'Transaction Date'].map((item) => {
        return (
          <TableCell>
            <Typography className={classes.headerCell}>{item}</Typography>
          </TableCell>
        );
      })}
    </Box>
  );
};

const TableRow: React.FC<{ invite: CampaignInvitation }> = ({ invite }) => {
  const classes = useReviewInvitesTableStyles();

  return (
    <Box className={classes.tableRowContainer}>
      {[
        invite.firstName || '---',
        invite.lastName || '---',
        formatPhoneNumber(invite.phoneNumber, { showPlusOne: false }) || '---',
        invite.transactionDate
          ? formatDate(new Date(invite.transactionDate || 0), 'MMM dd, yyyy')
          : '---',
      ].map((item) => {
        return (
          <TableCell>
            <Typography className={classes.rowCell}>{item}</Typography>
          </TableCell>
        );
      })}
    </Box>
  );
};
