import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    width: '100%',
    backgroundColor: theme.palette.common.white,
  },
  checkbox: {
    position: 'absolute',
    top: '14px',
    left: '21px',
    zIndex: 10,
  },
  btnWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '24px',
    marginBottom: '22px',
  },
  detailsTextWrapper: {
    marginRight: '30px',
  },
  detailsText: {
    color: '#667085',
    fontSize: '13px',
    letterSpacing: ' -0.26px',
  },
  tableSelectWrapper: {
    margin: '12px 10px 14px 12px',
    height: 'calc(812px - 42px)',
  },
  tableWrapper: {
    width: '100%',
    paddingRight: '12px',
  },
  topWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    height: '92px',
    marginTop: '15px',
    paddingRight: '10px',
  },
  table: {
    position: 'relative',
    '& #common-table-wrapper': {
      maxHeight: '630px',
      height: '630px',
    },

    '& table': {
      borderSpacing: '0 !important',
    },
    '& th': {
      '& > div': {
        padding: '5px !important',
        fontSize: '12px',
      },

      '&:nth-child(1)': {
        width: '0px !important',

        '& div': {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        },
      },
      '&:nth-child(2) div': {
        display: 'flex',
        minWidth: '200px',
      },
      '&:nth-child(3) div': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingRight: '20px',
      },
      '&:nth-child(4) div': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingRight: '20px',
      },
      '&:nth-child(5) div': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingRight: '8px',
      },
      '&:nth-child(6) div': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      '&:nth-child(7) div': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      '&:nth-child(8) div': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      '&:nth-child(9) div': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
    },
    '& tr': {
      height: '48px !important',
      fontSize: '2px !important',
      borderSpacing: 'none !important',
    },
    '& td': {
      fontSize: '13px !important',
      '& span': { fontSize: '13px !important' },
      '& p': { fontSize: '13px' },
      '& > div': {
        padding: '5px',
        fontSize: '13px !important',
      },
      '&:nth-child(1)': {
        width: '0',
        paddingLeft: '0 !important',
      },
      '&:nth-child(2)': {
        paddingLeft: '5px !important',
        display: 'flex',
        alignItems: 'center',
        height: '48px',
        minWidth: '220px',
      },
      '&:nth-child(3)': {
        paddingLeft: 0,
        textAlign: 'center',
      },
      '&:nth-child(4)': {
        textAlign: 'center',
        paddingLeft: 0,
      },
      '&:nth-child(5)': {
        textAlign: 'center',
        paddingLeft: 0,
        width: '100px',
      },
      '&:nth-child(6)': {
        textAlign: 'center',
        paddingLeft: 0,
      },
      '&:nth-child(7)': {
        textAlign: 'center',
        paddingLeft: 0,
      },
      '&:nth-child(8)': {
        textAlign: 'center',
        paddingLeft: 0,
      },
      '&:nth-child(9)': {
        textAlign: 'center',
        paddingLeft: 0,
        '& p': {
          fontSize: '11px !important',
        },
      },
    },
  },
  row: {
    cursor: 'default !important',
  },
}));
