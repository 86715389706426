import React, { FC, useEffect } from 'react';
import { Grid, Typography, Stack, Box } from '@mui/material';
import { QuickStartStrings, SocialVerseStrings } from '../../common/localization/en';
import { useClientType } from '../../services/hooks/useClientType';
import { useTrackEvent } from '../../hooks/useTrackEvent';
import { SocialVerseEventNames } from '../../common/constants/events/socialVerseEvents';

import { useNoSocialVersesStyles } from './NoSocialVerses.style';
import { SVButton, SVTabs, SocialverseFormatsCarousel } from './CommonComponents/CommonComponents';
import { Add } from '@mui/icons-material';
import { TypeSocialVerse } from '../../common/constants/constants';
import { useTypedSelector } from '../../store';
import {
  MAX_STEP_INDEX,
  useQuickStartLaunchPad,
} from '../QuickStart/QuickStartLaunchPadModal/useQuickStartLaunchPad';
import AnimatedQsMarker, {
  QSStep,
} from '../../common/components/AnimatedQsMarker/AnimatedQsMarker';

interface NoSocialVersesProps {
  onSelectSocialvViewMode: (viewMode: TypeSocialVerse) => void;
}

export const NoSocialVerses: FC<NoSocialVersesProps> = ({ onSelectSocialvViewMode }) => {
  const classes = useNoSocialVersesStyles();

  const { isHealthCare } = useClientType();
  const { trackEvent } = useTrackEvent();
  const {
    isQSMarkerVisible,
    hideQSMarker,
    moveToNextItem,
    moveToNextTopicStep,
    currentTopicStep,
    isStarted: isQsStarted,
    isFinished,
    currentTargetIndex,
    isLaunchpadVisible,
  } = useQuickStartLaunchPad();
  const [value, setValue] = React.useState(0);

  const { totalItems } = useTypedSelector((state) => state.videos);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    const tabName = SVTabs[newValue].text;
    const eventName = `${tabName}_${SocialVerseEventNames.social_verse_tab_click}`;
    trackEvent(eventName);
  };

  const canCreate = () => {
    if (SVTabs[value].type === TypeSocialVerse.Perspective && totalItems < 3) return false;

    if (SVTabs[value].type === TypeSocialVerse.Grid && totalItems < 5) return false;

    return true;
  };

  useEffect(() => {
    if (!isQsStarted || isFinished || currentTargetIndex === MAX_STEP_INDEX || isLaunchpadVisible)
      return;

    const elementsToBlock: NodeListOf<
      HTMLAnchorElement | HTMLButtonElement | HTMLDivElement
    > = document.querySelectorAll('button, a, [data-qs-button="false"], div[role="button"]');

    elementsToBlock.forEach((element) => {
      if (element.dataset.qsButton === 'true') return;

      element.onmouseover = () => {
        element.style.cursor = 'not-allowed';
      };

      if (element instanceof HTMLAnchorElement) {
        element.onclick = (event) => {
          event.preventDefault();
        };
        return;
      }

      if (element instanceof HTMLButtonElement) {
        element.disabled = true;
        return;
      }
    });
  }, [isQsStarted, isFinished, currentTopicStep, currentTargetIndex, isLaunchpadVisible]);

  const qsSteps: QSStep[] = [
    {
      title: QuickStartStrings.SvStep1Title,
      text: QuickStartStrings.SvStep1Text,
      onClick: () => {
        setValue(currentTopicStep + 1);
        moveToNextTopicStep();
      },
      width: '169px',
    },
    {
      title: QuickStartStrings.SvStep2Title,
      text: QuickStartStrings.SvStep2Text,
      onClick: () => {
        setValue(currentTopicStep + 1);
        moveToNextTopicStep();
      },
      width: '169px',
    },
    {
      title: QuickStartStrings.SvStep3Title,
      text: QuickStartStrings.SvStep3Text,
      onClick: () => {
        setValue(currentTopicStep + 1);
        moveToNextTopicStep();
      },
      width: '169px',
    },
    {
      title: QuickStartStrings.SvStep4Title,
      text: QuickStartStrings.SvStep4Text,
      onClick: () => {
        setValue(currentTopicStep + 1);
        moveToNextTopicStep();
      },
      width: '169px',
    },
    {
      title: QuickStartStrings.SvStep5Title,
      text: QuickStartStrings.SvStep5Text,
      onClick: () => {
        moveToNextItem();

        const elementsToUnblock: NodeListOf<
          HTMLAnchorElement | HTMLButtonElement | HTMLDivElement
        > = document.querySelectorAll('button, a, [data-qs-button="false"], div[role="button"]');

        elementsToUnblock.forEach((element) => {
          element.onmouseover = () => {
            element.style.cursor = 'pointer';
          };

          if (element instanceof HTMLButtonElement) {
            element.disabled = false;
            return;
          }
        });
      },
      width: '169px',
      isLastStep: true,
    },
  ];

  return (
    <>
      <Grid container className={classes.containerNewSV}>
        <Stack className={classes.wrapperNewSV}>
          <Box className={classes.carouselWrapper}>
            <SocialverseFormatsCarousel
              isHealthCare={isHealthCare}
              value={value}
              setValue={setValue}
              handleChange={handleChange}
              title={SocialVerseStrings.CreateSocialVerse}
              description={SocialVerseStrings.CreateNewSocialVerseText}
            />
            {isQSMarkerVisible && (
              <Box className={classes.qsMarkerWrapper}>
                <AnimatedQsMarker steps={qsSteps} arrowPosition="right" direction="column" />
              </Box>
            )}
          </Box>
          {canCreate() ? (
            <Box className={classes.svButtonWrapper}>
              <SVButton
                className={classes.svButtonCreate}
                startIcon={<Add />}
                variant="contained"
                handleClick={() => {
                  onSelectSocialvViewMode(SVTabs[value].type);
                  trackEvent(SocialVerseEventNames.createNewSocialVerse);
                  if (isQSMarkerVisible) hideQSMarker();
                }}
                id="create-sv-button"
              >
                {SocialVerseStrings.CreateButtonText}
              </SVButton>
            </Box>
          ) : (
            <Typography className={classes.noVideosButton}>
              Minimum {SVTabs[value].type === TypeSocialVerse.Perspective ? 3 : 5} Clips Required
            </Typography>
          )}
        </Stack>
      </Grid>
    </>
  );
};
