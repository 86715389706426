import { Box, Grid } from '@mui/material';
import { FC, useEffect, useMemo, useState } from 'react';
// import { ContentSubNavigation } from '../ContentSubNavigation/ContentSubNavigation';
import { ClientTypes } from '../../../api/models/common';
import { SearchTargets } from '../../../api/models/searchFilter';
/* import { StoryApiModel } from '../../../api/models/videos'; */
import { ActionBar, ActiveModal } from '../../../common/components/ActionBar/ActionBar';
import { activeModalLiteral } from '../../../common/components/ActionBar/ActionBarModals';
import {
  DateRangeFilter,
  ReturnedDate,
} from '../../../common/components/datePicker/DateRangeFilter';
import { useAppDispatch, useTypedSelector } from '../../../store';
import { reset } from '../../../store/slices/analyticsSlice';
import { getSearchFilters } from '../../../store/slices/searchFiltersSlice';
import { DateFilterMobileContainer } from '../../dashboard/Dashboard.style';
import { ContentTable } from './contentTable/ContentTable';
import { ContentContainer, useVideosTableStyles } from './TableSection.style';
import { TopgolfContentTable } from './topgolfContentTable/topGolfContentTable';
import { useTrackEvent } from '../../../hooks/useTrackEvent';
import { VideosEventNames } from '../../../common/constants/events/videosEvents';
import { SortingData, TableToolbar } from './TableToolbar/TableToolbar';
import {
  goToSelectedPage,
  setVideosSorting,
  setLoadMore,
  fetchVideosBySearchFilterApiCall,
  getVideosBySearchFilter,
  updateSize,
} from '../../../store/slices/videosSlice';
import { useJsonToCSV } from '../../../hooks/useJsonToCSV';
import formatDate from 'date-fns/format';
import { videoHeaders } from '../../filters/CreatedFilter.helper';
import { resetActiveVideoFilterAttributes } from '../../../store/slices/searchFilterSlice';
import { useLocation } from 'react-router-dom';
import { WebsiteRoutes } from '../../../common/constants/routes';
import { useClientType } from '../../../services/hooks/useClientType';
import { GetCampaignVideosApiModel } from '../../../api/models/campaigns';

interface TabeSectionProps {
  handleOpenDeleteModal: (id: string, deleted: boolean) => void;
  handleOpenConfirmationModal: (id: string) => void;
  handleOpenAddNewTagModal: (id: string) => void;
  handleAddVideoToCard?: (
    event: React.MouseEvent<HTMLButtonElement>,
    id: string,
    addUsingRowBtn: boolean,
  ) => void;
  statusFilter?: string;
  archivedFilter: boolean;
  showActionBar: boolean;
  isSocialVerseAddVideo?: boolean;
  onCreateFilterClick?: () => void;
  onSavedFiltersClick?: () => void;
  onVideoKeywordsClick?: () => void;
  handleVideoReplace?: (addIdParams: {
    event: React.MouseEvent<HTMLButtonElement>;
    id: string;
    addUsingRowBtn: boolean;
  }) => void;
  videoStateOverride?: GetCampaignVideosApiModel;
  onTableViewChange?: (view: 'grid' | 'list') => void;
  onTableSortingUpdate?: (sorting: string) => void;
  onToolbarSortingUpdate?: (sorting: string) => void;
  disablePagination?: boolean;
  isCampaignSummaryPage?: boolean;
  onUpdateThumbnail?: () => void;
}

export const TableSection: FC<TabeSectionProps> = ({
  handleOpenDeleteModal,
  handleOpenConfirmationModal,
  handleOpenAddNewTagModal,
  handleAddVideoToCard,
  statusFilter,
  archivedFilter,
  showActionBar,
  isSocialVerseAddVideo,
  onCreateFilterClick,
  onSavedFiltersClick,
  onVideoKeywordsClick,
  handleVideoReplace,
  videoStateOverride,
  onTableViewChange,
  onTableSortingUpdate,
  onToolbarSortingUpdate,
  disablePagination,
  isCampaignSummaryPage,
  onUpdateThumbnail,
}) => {
  const styles = useVideosTableStyles();
  const { pathname } = useLocation();

  const dispatch = useAppDispatch();
  const { clientType, id: venueId } = useTypedSelector((state) => state.venue.venue);
  const videosState = useTypedSelector((state) => state.videos);

  const { items, totalItems, selectedRows, size, page, sort } = {
    ...videosState,
    ...videoStateOverride,
  };

  const activeCampaign = useTypedSelector((state) => state.campaigns.activeCampaign);
  const { trackEvent } = useTrackEvent();
  const { isTopGolfClient, isLoading } = useClientType();
  const { search, activeVideoFilterAttributes } = useTypedSelector((state) => state.searchFilter);

  const [date, setDate] = useState<ReturnedDate | null>(null);
  const [activeModal, setActiveModal] = useState<ActiveModal | null>(null);
  const isNotTopgolf = clientType !== ClientTypes.TOPGOLF;
  const updateFilters = (date: ReturnedDate | null) => {
    setDate(date);
  };

  useEffect(() => {
    if (venueId) {
      dispatch(getSearchFilters({ accountId: venueId, target: SearchTargets.VIDEOS }));
    }
  }, [venueId, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(reset());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleBtnClick = (name: typeof activeModal) => {
    setActiveModal(name);
  };

  const handleClose = () => {
    setActiveModal(null);
  };

  const headers = [
    { label: 'User Name', key: 'userName' },
    { label: 'Date Created', key: 'createdAt' },
    { label: 'Clicks', key: 'ctaClicks' },
    { label: 'Views', key: 'views' },
    // { label: 'Conversions', key: 'conversion' },
    { label: 'Keyword Score', key: 'keywordScore' },
    { label: 'Keywords', key: 'keywordsCount' },
    { label: 'Clip Status', key: 'status' },
    { label: 'Add clip', key: 'addVideo' },
  ];

  const hasActiveCampaignBonusPoints = false;
  const isAwardsBonusPointsActive = activeCampaign && hasActiveCampaignBonusPoints ? true : false;

  const [tableView, setTableView] = useState<'list' | 'grid'>(
    pathname.startsWith(WebsiteRoutes.SocialVerse) ? 'list' : 'grid',
  );
  const isGridLayout = tableView === 'grid';

  const sorting = useMemo(() => {
    const [field, direction] = sort[0].split(',');
    return {
      field,
      direction: direction.toLowerCase(),
    } as SortingData;
  }, [sort]);

  const { jsonToCSV } = useJsonToCSV();

  return (
    <>
      {/* <ContentSubNavigation /> */}
      <Box
        display="flex"
        alignItems="center"
        paddingLeft="40px"
        paddingRight="38px"
        paddingTop="18px"
      >
        {showActionBar && (
          <ActionBar
            setActiveModal={(name) => handleBtnClick(name)}
            selectedRowsLength={selectedRows?.length}
            isAwardsBonusPointsActive={isAwardsBonusPointsActive}
          />
        )}

        {/* could be refactored in the end ↓ */}
        {activeModal === 'DOWNLOAD_CSV' &&
          activeModalLiteral[activeModal](Boolean(activeModal), handleClose, headers)}
        {activeModal === 'ASSIGN_BONUS_POINTS' &&
          activeModalLiteral[activeModal](Boolean(activeModal), handleClose, selectedRows ?? [])}
        {activeModal === 'DOWNLOAD_CSV_LIMIT' &&
          activeModalLiteral[activeModal](Boolean(activeModal), handleClose)}
        {activeModal === 'ASSIGN_TO_PLUGIN_LIMIT' &&
          activeModalLiteral[activeModal](Boolean(activeModal), handleClose)}
        {activeModal === 'ASSIGN_BONUS_POINTS_LIMIT' &&
          activeModalLiteral[activeModal](Boolean(activeModal), handleClose)}
      </Box>
      <Box paddingLeft="24px" paddingRight="24px" width={isGridLayout ? 'auto' : 'unset'}>
        {!pathname.startsWith(WebsiteRoutes.SocialVerse) && !isTopGolfClient && !isLoading && (
          <Box px="20px">
            <TableToolbar
              itemsShowing={items.length}
              itemsTotal={totalItems}
              sorting={sorting}
              onSortingUpdate={({ field, direction }) => {
                const newSorting = `${field},${direction.toUpperCase()}`;
                onToolbarSortingUpdate?.(newSorting);
                dispatch(setVideosSorting(newSorting));
              }}
              tableView={tableView}
              onTableViewChange={(view) => {
                dispatch(goToSelectedPage(0));
                setTableView(view);
                onTableViewChange?.(view);
                if (view === 'list') {
                  dispatch(setLoadMore(false));
                } else {
                  dispatch(updateSize(25));
                  dispatch(goToSelectedPage(0));
                }
              }}
              searchMode={!!search || !!activeVideoFilterAttributes.length}
              onSearchResetClick={() => {
                dispatch(
                  getVideosBySearchFilter({
                    venueId,
                    filter: activeVideoFilterAttributes,
                    pageable: {
                      page,
                      size,
                      sort,
                    },
                    search,
                  }),
                );
              }}
              searchExportEnabled={totalItems < 100}
              onSearchExportClick={async () => {
                const data = await fetchVideosBySearchFilterApiCall({
                  venueId,
                  filter: activeVideoFilterAttributes,
                  pageable: {
                    page: 0,
                    size: 100,
                    sort,
                  },
                  search,
                });

                jsonToCSV({
                  data: data.items ?? [],
                  headers: videoHeaders,
                  filename: `SocialV_Clips_${formatDate(new Date(), 'MMM-dd-y')}`,
                });
              }}
              onClearSearchClick={() => {
                dispatch(resetActiveVideoFilterAttributes());
              }}
            />
          </Box>
        )}
        <ContentContainer isGridLayout={isGridLayout}>
          {isNotTopgolf ? (
            <div className={styles.SVVideosTableStyles}>
              <ContentTable
                videoStateOverride={videoStateOverride}
                handleOpenDeleteModal={handleOpenDeleteModal}
                statusFilter={statusFilter}
                handleOpenConfirmationModal={handleOpenConfirmationModal}
                handleOpenAddNewTagModal={handleOpenAddNewTagModal}
                archivedFilter={archivedFilter}
                isSocialVersePage={isSocialVerseAddVideo}
                handleAddVideoToCard={handleAddVideoToCard ? handleAddVideoToCard : () => {}}
                onRowClick={(videoId) => {
                  trackEvent(VideosEventNames.openVideoDetailsClicked, { videoId: videoId });
                }}
                handleVideoReplace={handleVideoReplace}
                isGridLayout={isGridLayout}
                disablePagination={disablePagination}
                isCampaignSummaryPage={isCampaignSummaryPage}
                onSortingUpdate={onTableSortingUpdate}
                onUpdateThumbnail={onUpdateThumbnail}
              />
            </div>
          ) : (
            <div className={styles.SVTopGolfVideosTableStyles}>
              <Grid container justifyContent="flex-end" alignItems="center">
                <Grid item xs={4} style={{ display: 'none' }}>
                  <DateFilterMobileContainer>
                    <DateRangeFilter updateRange={updateFilters} width="100%" noFormatDate={true} />
                  </DateFilterMobileContainer>
                </Grid>

                <Grid item xs={12}>
                  <TopgolfContentTable
                    handleOpenDeleteModal={handleOpenDeleteModal}
                    statusFilter={archivedFilter ? 'ARCHIVED' : statusFilter}
                    handleOpenConfirmationModal={handleOpenConfirmationModal}
                    handleOpenAddNewTagModal={handleOpenAddNewTagModal}
                    archivedFilter={archivedFilter}
                    date={date}
                    isSocialVersePage={isSocialVerseAddVideo}
                    handleAddVideoToCard={handleAddVideoToCard ? handleAddVideoToCard : () => {}}
                    handleVideoReplace={handleVideoReplace}
                  />
                </Grid>
              </Grid>
            </div>
          )}
        </ContentContainer>
      </Box>
    </>
  );
};
