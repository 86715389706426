import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  inputsWrapper: {
    position: 'relative',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '640px',
    borderRadius: '12px',
    backgroundColor: theme.palette.common.white,
    padding: '24px 0',
  },
  closeIcon: {
    position: 'absolute',
    top: '25px',
    right: '22px',
    width: '24px',
    color: '#667085',
    cursor: 'pointer',
  },
  title: {
    color: '#101828',
    fontSize: '20px',
    fontWeight: '500',
    marginBottom: '20px',
    textAlign: 'center',
  },
  fieldsWrapper: {
    padding: '20px 48px 0 48px',
    borderTop: '1px solid #EAECF0',
    borderRight: 0,
    borderLeft: 0,
  },
}));
