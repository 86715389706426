import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '24px 0',
  },
  title: {
    color: '#101828',
    fontSize: '25px',
    fontWeight: 600,
    marginBottom: '10px',
    padding: '0 34px',
  },
  text: {
    color: '#475467',
    fontSize: '14px',
    maxWidth: '722px',
    textAlign: 'center',
  },
  divider: {
    margin: '20px 0',
    borderColor: '#EAECF0',
    height: '1px',
    width: '100%',
  },
  contentWrapper: {
    width: '100%',
    padding: '0 62px 0 48px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  leftSideWrapper: {
    width: '100%',
    position: 'relative',
  },
  rightSideWrapper: {
    width: '330px',
    marginLeft: '36px',
  },
  methodText: {
    color: theme.palette.common.gray,
  },
  videoWrapper: {
    width: '289px',
    height: '514px',
    border: '1px solid #D9D9D9',
    backgroundColor: '#D9D9D9',
    borderRadius: '8px',
    overflow: 'hidden',
    position: 'relative',
  },
  playIconWrapper: {
    width: '48px',
    height: '48px',
    borderRadius: '50%',
    backdropFilter: 'blur(8px)',
    display: 'grid',
    placeItems: 'center',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    cursor: 'pointer',
  },
  videoText: {
    fontSize: '13px',
    color: '#8B89A0',
    textAlign: 'center',
    marginTop: '10px',
  },
  buttonsWrapper: {
    display: 'flex',
    alignSelf: 'start',
    margin: '34px 0 0 24px',
    borderColor: '#D0D5DD',
  },
  button: {
    padding: '10px 23px',
    height: '44px',
    fontWeight: 600,
    borderRadius: '8px',

    '&:hover': {
      backgroundColor: 'white',
    },
  },
  cancelButton: {
    color: '#344054',
    border: '2px solid #D0D5DD',

    '&:hover': {
      borderColor: '#344454',
    },
  },
  confirmButton: {
    color: '#fff !important',
    border: 'none',
    marginLeft: '12px',
    background: theme.palette.primary.main,
    '&:hover': {
      borderColor: theme.palette.primary.main,
      background: theme.palette.primary.main,
    },
    '&:disabled': {
      opacity: '0.6',
    },
  },
  progressWrapper: {
    marginTop: '10px',
    width: '321px',
  },
  progress: {
    width: '100%',
    height: '8px',
    borderRadius: '4px',
    backgroundColor: '#EAECF0',
  },
}));
