import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  inputsWrapper: {
    position: 'relative',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '640px',
    borderRadius: '12px',
    backgroundColor: theme.palette.common.white,
    padding: '24px 0',
  },
  closeIcon: {
    position: 'absolute',
    top: '25px',
    right: '22px',
    width: '24px',
    color: '#667085',
    cursor: 'pointer',
  },
  title: {
    color: '#101828',
    fontSize: '20px',
    fontWeight: '500',
    marginBottom: '20px',
    textAlign: 'center',
  },
  fieldsWrapper: {
    padding: '20px 48px 0 48px',
    borderTop: '1px solid #EAECF0',
    borderRight: 0,
    borderLeft: 0,
  },
  fieldWrapper: {
    display: 'flex',
    gap: '8px',
    width: '100%',
    marginBottom: '16px',
  },
  inputWrapper: {
    width: '100%',
  },
  inputLabel: {
    fontSize: '13px !important',
    color: '#344054 !important',
    fontWeight: 500,
    marginBottom: '6px',
  },
  input: {
    width: '100% !important',
  },
  phoneInput: {
    paddingLeft: '15px',
    height: '42px',
  },
  phoneLabel: {
    '& p': {
      fontSize: '12px',
      lineHeight: '24px',
      letterSpacing: '0.02em',
      marginBottom: '8px',
      color: theme.palette.common.black,
    },
  },
  toggle: {
    '&.MuiSwitch-root': {
      height: '34px',
      width: '54px',
    },
    '& .MuiSwitch-thumb': {
      height: '28px !important',
      width: '28px !important',
    },
    '& .Mui-checked': {
      transform: 'translateX(18px) !important',
    },
    '& .MuiSwitch-switchBase': {
      padding: '3px 0 0 3px',
    },
  },
  btnAdd: {
    fontWeight: 400,
    width: '220px',
    height: '42px',
    marginBottom: '5px',
    fontSize: '14px',
    '&:disabled': {
      background: `${theme.palette.primary.main}4d`,
      color: 'white',
    },
  },
  optInWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    '& .MuiFormControlLabel-root': {
      alignSelf: 'flex-end',
    },
  },
  optInInviteWrapper: {
    display: 'flex',
    marginTop: '26px',
    marginRight: '-54px',
    marginLeft: '5px',
    '& p': {
      width: '195px',
    },
  },
  optInLabel: {
    fontSize: '14px',
    color: '#344054',
    fontWeight: 500,
    marginRight: '15px',
    lineHeight: '20px',
  },
}));
