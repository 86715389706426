import React, { FC, useEffect, useState } from 'react';
import { useStyles } from './InviteCreatorModal.styles';
import { Box, Button, Modal, Typography } from '@mui/material';
import { InviteCreatorModalStrings } from '../../localization/en';
import { Close } from '@mui/icons-material';
import { useClientType } from '../../../services/hooks/useClientType';
import InviteOption from './InviteOption';
import { useDetectClickOutside } from 'react-detect-click-outside';
import ArrowRightSmall from '../../assets/ArrowRightSmall';
import { CampaignsApiModel } from '../../../api/models/campaigns';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onNextStep: () => void;
  selectedCampaign: CampaignsApiModel;
  campaigns: CampaignsApiModel[];
  onCampaignSelect: (campaign: CampaignsApiModel) => void;
  activeInviteOption: number;
  onInviteOptionChange: (index: number) => void;
  isCampaignModal?: boolean;
}

const InviteCreatorModal: FC<Props> = ({
  isOpen,
  onClose,
  onNextStep,
  selectedCampaign,
  campaigns,
  onCampaignSelect,
  activeInviteOption,
  onInviteOptionChange,
  isCampaignModal,
}) => {
  const styles = useStyles();

  const [isCampaignListOpen, setIsCampaignListOpen] = useState(false);

  const { isTrubluClient } = useClientType();

  const inviteOptions = [
    {
      title: InviteCreatorModalStrings.WithInvite,
      text: isTrubluClient
        ? InviteCreatorModalStrings.WithInviteTextTrublu
        : InviteCreatorModalStrings.WithInviteText,
    },
    {
      title: isTrubluClient
        ? InviteCreatorModalStrings.AddPatient
        : InviteCreatorModalStrings.AddCustomer,
      text: isTrubluClient
        ? InviteCreatorModalStrings.WithoutInviteTextTrublu
        : InviteCreatorModalStrings.WithoutInviteText,
    },
  ];

  const refDetectClickOutside = useDetectClickOutside({
    onTriggered: () => {
      setIsCampaignListOpen(false);
    },
  });

  const handleCampaignListOpen = () => {
    setIsCampaignListOpen(!isCampaignListOpen);
  };

  useEffect(() => {
    onInviteOptionChange(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      style={{
        background: 'rgba(26, 21, 56, 0.5)',
        backdropFilter: 'blur(2.5px)',
        width: '100%',
      }}
      sx={{
        '& .MuiBackdrop-root': {
          backgroundColor: 'transparent !important',
        },
      }}
    >
      <Box className={styles.container}>
        <button onClick={onClose} className={styles.closeIcon}>
          <Close />
        </button>
        <Typography className={styles.title}>
          {isTrubluClient
            ? InviteCreatorModalStrings.InviteCreatorTitleTrublu
            : InviteCreatorModalStrings.InviteCreatorTitle}
        </Typography>
        <Box className={styles.optionsWrapper}>
          {inviteOptions.map((option, index) => (
            <InviteOption
              title={option.title}
              text={option.text}
              isActive={activeInviteOption === index}
              onClick={() => onInviteOptionChange(index)}
              textWidth={index === 0 ? '225px' : '244px'}
              key={option.title}
            />
          ))}
        </Box>
        {(campaigns.length > 1 || isCampaignModal) && (
          <Box className={styles.campaignsWrapper}>
            <Typography className={styles.campaignsTitle}>
              {isTrubluClient
                ? InviteCreatorModalStrings.AssignCreatorTrublu
                : InviteCreatorModalStrings.AssignCreator}
            </Typography>
            <Box ref={refDetectClickOutside} width="fit-content">
              <div className={styles.select} onClick={handleCampaignListOpen}>
                <Typography className={styles.selectName}>{selectedCampaign?.name}</Typography>
                <Box style={{ transform: 'rotate(90deg)' }}>
                  <ArrowRightSmall stroke="#667085" />
                </Box>
              </div>
              {isCampaignListOpen && (
                <Box className={styles.campaignsSelectWrapper}>
                  {campaigns.map((campaign) => (
                    <div
                      className={styles.campaign}
                      onClick={() => {
                        onCampaignSelect(campaign);
                        setIsCampaignListOpen(false);
                      }}
                    >
                      <Typography className={styles.campaignName}>{campaign.name}</Typography>
                    </div>
                  ))}
                </Box>
              )}
            </Box>
          </Box>
        )}
        <Button variant="contained" className={styles.button} onClick={onNextStep}>
          {isTrubluClient
            ? InviteCreatorModalStrings.NextStepTrublu
            : InviteCreatorModalStrings.NextStep}
        </Button>
      </Box>
    </Modal>
  );
};

export default InviteCreatorModal;
