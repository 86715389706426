import React, { FC, useEffect, useRef, useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import { useFormik } from 'formik';

import { LoginPageStrings, SettingsPageStrings, SingUpPage } from '../../../common/localization/en';
import { castURLToHttps, getValidationSchemaCompleteProfileForm } from './SignUpForm.helper';
import { useSignUpFormStyles } from './SignUpForm.style';
import { useAppDispatch, useTypedSelector } from '../../../store';
// import { convertStripePriceToDollars } from '../../../services/utilities';
import { states } from '../../../common/constants/constants';
import {
  DEFAULT_PHONE_VALIDATION_STATE,
  PhoneField,
  PhoneValidationState,
} from '../../../common/components/PhoneField/PhoneField';
import { CustomTextField } from '../../../common/components/customTextField/CustomTextField';
import { CustomSelectWithTextField } from '../../../common/components/customSelect/CustomSelectWithTextField';
import { CustomButton } from '../../../common/components/customButton/customButton';
import { Signature } from '../../../common/components/Signature/Signature';
import { useQuery } from '../../../hooks/common';
import { PricingPlanPreview } from './components/PricingPlanPreview';

import { URLTextField } from '../../../common/components/TextField/URLTextField';
import { PlanBackgroundTopIcon } from '../../../common/assets/newDesign/PlanBackgroundTopIcon';
import { PlanBackgroundSideIcon } from '../../../common/assets/newDesign/PlanBackgroundSideIcon';
import { useTrackEvent } from '../../../hooks/useTrackEvent';
import { SignUpEventNames } from '../../../common/constants/events/signUpEventNames';
import { useHandleChangeWithTracking } from '../../../services/hooks/useHandleChangeWithTracking';
import { authRequest, getUserAccounts, ResetPassword } from '../../../store/slices/meSlice';
import { useToasts } from 'react-toast-notifications';
import { SVenterPrisePlan } from '../../plans/PlansPage';
import {
  getProperties,
  getVenue,
  patchVenue,
  patchVenueProperties,
  setSelectedVenue,
} from '../../../store/slices/venueSlice';
import { getVenueList } from '../../../store/slices/organizationVenueSlice';
import { useHistory } from 'react-router-dom';
import { WebsiteRoutes } from '../../../common/constants/routes';
import { isMobile } from 'react-device-detect';
import { useCreateDefaultCampaign } from '../useCreateDefaultCampaign';
import PlaceAutoComplete from '../../../common/components/PlaceAutoComplete/PlaceAutoComplete';

interface Props {}

export const CompleteProfile: FC<Props> = () => {
  const query = useQuery();
  const email = query.get('email');
  const token = query.get('token');
  const phone = query.get('phoneNumber');
  const customerName = query.get('customerName');
  const accountName = query.get('accountName');

  const { trackEvent } = useTrackEvent();
  // const currentPlan = planId && plans ? plans[planId as PricingPlansTypes] : null;
  const classes = useSignUpFormStyles();
  // const planName = currentPlan?.name || '';
  // const planPrice = convertStripePriceToDollars(currentPlan?.priceAmount || 0);
  const dispatch = useAppDispatch();
  // const currentPlanId = currentPlan ? backendPricingPlans[planId as PricingPlansTypes] : '';
  const { auth } = useTypedSelector((state) => state.me);
  const { items } = useTypedSelector((state) => state.OrganizationVenueSlice);
  const { addToast } = useToasts();
  const [transformedUrl, setTransformedUrl] = useState('');
  const [businessAdress, setBusinessAdress] = useState('');
  const [address, setAddress] = useState({ address: '', city: '', state: '', zip: '' });
  const [isEnabled, setIsEnabled] = useState(true);
  const history = useHistory();
  const { createDefaultCampaign } = useCreateDefaultCampaign();

  useEffect(() => {
    if (auth) {
      dispatch(setSelectedVenue({ id: null }));
      dispatch(getUserAccounts({}));
      dispatch(getVenueList({}));
      // history.push(WebsiteRoutes.RewardCampaigns);
    }
  }, [auth, dispatch]);

  useEffect(() => {
    if (auth && items && items.length && accountName) {
      const [account] = items.filter((item) => item.name === accountName);
      if (account && account.id) {
        dispatch(
          patchVenue({
            id: account.id,
            update: {
              websiteUrl: transformedUrl,
              businessAddress: businessAdress,
            },
          }),
        );
        createDefaultCampaign(account.id);
        dispatch(setSelectedVenue({ id: account.id }));
        dispatch(getVenue({ id: account.id }));
        dispatch(
          patchVenueProperties({
            id: account.id,
            alert: false,
            update: {
              name: 'webapp.default-story-details',
              value: {
                ctaBtnText: 'Visit Us!',
                ctaBtnLinkUrl: transformedUrl,
              },
            },
          }),
        );
        dispatch(
          patchVenueProperties({
            id: account.id,
            alert: false,
            update: {
              name: 'company-address',
              value: address,
            },
          }),
        );
        dispatch(getProperties({ id: account.id })).then(() => {
          history.push(WebsiteRoutes.QuickStartWelcome);
        });
      }
    }
  }, [
    items,
    accountName,
    auth,
    businessAdress,
    transformedUrl,
    dispatch,
    history,
    address,
    createDefaultCampaign,
  ]);

  // useEffect(() => {
  //   if (isSVAdmin && isEnterPrisePlan) {
  //     const a = document.createElement('a');
  //     a.href = `${origin}/signup/success`;
  //     a.click();
  //   }
  // }, [isSVAdmin, origin, isEnterPrisePlan]);

  const phoneValidationRef = useRef<PhoneValidationState>(DEFAULT_PHONE_VALIDATION_STATE);

  const [countryCode, setCountryCode] = useState('');
  console.log(countryCode);

  const [firstName, lastName] = customerName ? customerName.split(' ') : ['', ''];

  const resetPasswordAction = async (resetPasswordValues: { password: string; email: string }) => {
    if (token) {
      try {
        const ResetPasswordResponse = await ResetPassword({
          token,
          newPassword: resetPasswordValues.password,
        });

        if (ResetPasswordResponse.success) {
          dispatch(
            authRequest({
              username: resetPasswordValues.email,
              password: resetPasswordValues.password,
            }),
          );
        }
      } catch (error) {
        addToast(LoginPageStrings.InvalidToken, { appearance: 'error', autoDismissTimeout: 25000 });
      }
    }
  };

  const initialValues = {
    firstName: firstName,
    lastName: lastName,
    email: email,
    password: '',
    confirmedPassword: '',
    phone: phone?.substring(1) || '',
    address: '',
    city: '',
    state: '',
    zip: '',
    signature: '',
    companyName: accountName,
    practiceName: '',
    companyWebsite: '',
    terms: true,
    planCode: '',
  };

  const {
    values,
    handleChange,
    errors,
    setFieldValue,
    isSubmitting,
    handleSubmit,
    touched,
    handleBlur,
    isValid: isFormValid,
    setFieldError,
  } = useFormik({
    initialValues,
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: true,
    validationSchema: getValidationSchemaCompleteProfileForm({ phoneValidationRef }),
    onSubmit: async (values) => {
      trackEvent(SignUpEventNames.submitButtonClicked, {
        //@ts-ignore
        value: values,
      });
      const url = castURLToHttps(values.companyWebsite);
      const addrss = `${values.address} ${values.city}, ${values.state} ${values.zip}`;
      setBusinessAdress(addrss);
      setTransformedUrl(url);
      setIsEnabled(false);
      setAddress({
        address: values.address,
        state: values.state,
        zip: values.zip,
        city: values.city,
      });

      resetPasswordAction({ email: values.email || '', password: values.password });
    },
  });

  const { handleChangeWithTracking } = useHandleChangeWithTracking(handleChange);

  useEffect(() => {
    const el: HTMLInputElement | null = document.querySelector('.Mui-error');
    (el?.parentElement ?? el)?.scrollIntoView({ behavior: 'smooth' });
  }, [isSubmitting]);

  const getLabelWithDescription = (title: string, description: string) => {
    return (
      <Typography display="inline">
        {title}
        <Typography display="inline" color="#8B89A0 !important">
          {description}
        </Typography>
      </Typography>
    );
  };

  const getFieldError = (field: keyof typeof initialValues) => {
    return touched[field] ? errors[field] : undefined;
  };

  return (
    <Grid
      container
      sx={{ margin: '0 auto', height: '100vh' }}
      style={{
        width: '100%',
        backgroundColor: '#ffff',
        height: '100vh',
        overflowY: 'scroll',
        backgroundPosition: ' 0px -300px',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        zoom: 1.1,
      }}
    >
      <Grid
        item
        xs={isMobile ? 12 : 6.5}
        style={{
          backgroundColor: '#fff',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Grid item xs={12} className={isMobile ? classes.mobileMainSide : classes.mainSide}>
          <Grid xs={12} className={classes.content}>
            <Typography className={classes.title} component="h1">
              {SingUpPage.Title}
            </Typography>
            <form onSubmit={handleSubmit} className={classes.form}>
              <Box mt="15px">
                <Typography fontSize="12px" color="black" mb="10px">
                  {SingUpPage.FullName}
                </Typography>
                <Typography fontSize="12px" color="#667085" mb="10px" marginLeft={'10px'}>
                  {customerName}
                </Typography>
              </Box>

              <Box mt="15px">
                <Typography fontSize="12px" color="black" mb="10px">
                  {SingUpPage.businessName}
                </Typography>
                <Typography fontSize="12px" color="#667085" mb="10px" marginLeft={'10px'}>
                  {accountName}
                </Typography>
              </Box>
              <Box className={classes.marginTop} sx={{ mt: '0px !important' }}>
                <PhoneField
                  name="phone"
                  label={SingUpPage.Phone}
                  value={values.phone}
                  onChange={handleChangeWithTracking}
                  className={classes.marginTop}
                  placeholder={'(555) 555-5555'}
                  error={getFieldError('phone')}
                  validateOnMount={false}
                  setCountryCode={setCountryCode}
                  countryCodeEditable={true}
                  onValidationChange={(v) => {
                    phoneValidationRef.current = v;
                  }}
                  onlyCountries={['us']}
                  onBlur={handleBlur}
                  isCountryCodeDisabled={true}
                  autoComplete="on"
                  disabled
                />
              </Box>
              <Box mt="15px">
                <Typography fontSize="12px" color="black" mb="10px">
                  {SingUpPage.Email} {SingUpPage.LoginUserName}
                </Typography>
                <Typography fontSize="12px" color="#667085" mb="10px" marginLeft={'10px'}>
                  {email}
                </Typography>
              </Box>
              <Box mt="15px">
                <Typography fontSize="12px" color="black" mb="10px">
                  {SingUpPage.CompanyWebsite}
                </Typography>
                <URLTextField
                  value={values.companyWebsite}
                  name="companyWebsite"
                  onChange={handleChangeWithTracking}
                  errorText={getFieldError('companyWebsite')}
                  placeholder={SingUpPage.SvWebsitePlaceholder}
                  onBlur={handleBlur}
                  fullWidth
                />
              </Box>

              <Box mt="15px">
                <PlaceAutoComplete
                  label={SingUpPage.CompanyAddress}
                  value={`${values.address}`}
                  onChange={handleChangeWithTracking}
                  onSelect={({ address, city, state, zip }) => {
                    if (!address || !city || !state || !zip) {
                      setFieldError('address', SingUpPage.AddressError);
                      return;
                    }
                    setFieldValue('address', address);
                    setFieldValue('city', city);
                    setFieldValue('state', state);
                    setFieldValue('zip', zip);
                  }}
                  onBlur={(e) => {
                    handleBlur(e);
                    if (errors.address) setFieldError('address', SingUpPage.AddressError);
                  }}
                  placeholder={SingUpPage.FirstLineOfAddress}
                  error={getFieldError('address')}
                />
              </Box>
              <Grid container justifyContent="space-between" alignItems={'flex-start'}>
                <Grid item xs={4.7}>
                  <CustomTextField
                    label={''}
                    value={values.city}
                    name="city"
                    handleChange={handleChangeWithTracking}
                    error={getFieldError('city')}
                    placeholder={SingUpPage.City}
                    onBlur={handleBlur}
                  />
                </Grid>
                <Grid item xs={4.1}>
                  <CustomSelectWithTextField
                    className={classes.full}
                    name={'state'}
                    value={values.state}
                    label={''}
                    onChange={handleChange}
                    options={states}
                    setFieldValue={setFieldValue}
                    optional={false}
                    state
                    onBlur={handleBlur}
                    height="43px"
                    margin="0"
                    error={getFieldError('state')}
                  />
                </Grid>
                <Grid item xs={2.9}>
                  <CustomTextField
                    label={''}
                    value={values.zip}
                    name="zip"
                    handleChange={(event) => {
                      const val = event.target.value;
                      if ((/^\d+$/.test(val) || val === '') && val.length < 6) {
                        handleChangeWithTracking(event);
                      }
                    }}
                    error={getFieldError('zip')}
                    placeholder={SingUpPage.ZipCode}
                    onBlur={handleBlur}
                  />
                </Grid>
              </Grid>

              <CustomTextField
                label={getLabelWithDescription(SingUpPage.Password, SingUpPage.LoginPassword)}
                value={values.password}
                name="password"
                handleChange={(event) => {
                  handleChangeWithTracking(event, true);
                }}
                error={getFieldError('password')}
                labelClassName={classes.marginTop}
                placeholder={SingUpPage.Enter + ' ' + SingUpPage.Password}
                isPasswordField
                onBlur={handleBlur}
                autoComplete="new-password"
              />
              <CustomTextField
                label={SettingsPageStrings.ConfirmPassword}
                name="confirmedPassword"
                isPasswordField={true}
                value={values.confirmedPassword}
                handleChange={(event) => {
                  handleChangeWithTracking(event, true);
                }}
                error={getFieldError('confirmedPassword')}
                labelClassName={classes.marginTop}
                placeholder={SettingsPageStrings.EnterPassword}
                onBlur={handleBlur}
                autoComplete="new-password"
              />
              <Signature
                signature={values.signature}
                onChange={handleChangeWithTracking}
                error={getFieldError('signature')}
                clientName={`${values.firstName} ${values.lastName}`}
                onBlur={handleBlur}
              />

              <Box className={classes.SubmitBtn}>
                <CustomButton
                  title="Continue"
                  type="submit"
                  colorType="primary"
                  fullWidth
                  disabled={!isEnabled || (!isFormValid && isEnabled)}
                  endIcon={<ArrowForwardIosRoundedIcon />}
                />
              </Box>
            </form>
          </Grid>
        </Grid>
      </Grid>
      {!isMobile && (
        <Grid item xs={5.5}>
          <Box
            sx={{
              height: '100%',
              background: '#1E0035',
              position: 'relative',
            }}
          >
            <PlanBackgroundTopIcon />
            <Box className={classes.BackgroundSideIcon}>
              <PlanBackgroundSideIcon />
            </Box>
            <Box position="sticky" top="50px">
              <>
                <PricingPlanPreview
                  plan={SVenterPrisePlan}
                  referralPlan={SVenterPrisePlan}
                  isTrubluPlan={false}
                  isEnterPrisePlan={false}
                  isCompleteProfile={true}
                />
              </>
            </Box>
          </Box>
        </Grid>
      )}
    </Grid>
  );
};
