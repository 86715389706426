import { Box, Button, Divider, Typography, useTheme } from '@mui/material';
import React from 'react';
import { useTypedSelector } from '../../../../store';
import { SendInvitesStrings } from '../../../localization/en';

interface SuccessScreenProps {
  onFinishClick: () => void;
}

export const SuccessScreen: React.FC<SuccessScreenProps> = ({ onFinishClick }) => {
  const theme = useTheme();

  const { validRecords } = useTypedSelector((state) => state.dataSyncSlice.importValidation);

  return (
    <Box sx={{ padding: '20px 44px 24px' }}>
      <Box
        sx={{
          marginBottom: '20px',
          border: `1px solid ${theme.palette.primary.main}`,
          borderRadius: '9px',
        }}
      >
        <Typography
          sx={{
            textAlign: 'center',
            fontWeight: '700',
            fontSize: '72px',
            color: theme.palette.primary.main,
          }}
        >
          {validRecords.length}
        </Typography>
        <Typography
          sx={{ textAlign: 'center', fontWeight: '600', fontSize: '42px', color: '#444E50' }}
        >
          Customers Added!
        </Typography>
      </Box>

      <Box>
        <ul
          style={{
            marginTop: '11px',
            listStyleType: 'disc',
            paddingLeft: '22px',
            fontWeight: '500',
          }}
        >
          <li>{SendInvitesStrings.YouCanInvite}</li>
          <li>{SendInvitesStrings.CustomerMustBeInvitedBefore}</li>
          <li>
            {SendInvitesStrings.AfterCustomizing} <b>{SendInvitesStrings.Essentials}</b>
          </li>
        </ul>
      </Box>

      <Divider color="#DCDBE4" sx={{ margin: '10px 0 24px' }} />

      <Button
        fullWidth
        style={{ height: '45px' }}
        variant="contained"
        onClick={onFinishClick}
        size="large"
      >
        Next: Invitation Text Message
      </Button>
    </Box>
  );
};
