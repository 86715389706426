import React, { FC } from 'react';
import { Box, Typography } from '@mui/material';
import { useStyles } from './InvitationMessageTab.styles';
import {
  CustomizeInvitationsModalStrings,
  ElementsStrings,
} from '../../../../../../common/localization/en';
import { useTypedSelector } from '../../../../../../store';
import {
  constructMessageFromTemplate,
  LocalMessageVariables,
  replaceMessageBodyWithLocalVariables,
} from '../../../../../../common/components/SMS/SMS.helper';
import { useClientType } from '../../../../../../services/hooks/useClientType';
import { TextareaAutosize } from '@mui/base';
import { RoundedButton } from '../../../../../../common/components/Button/RoundedButton';
import { SendTestMessageModal } from '../../../../../../common/components/SMS/SendTestMessageModal';
import { useModal } from '../../../../../../hooks/useModal';
import { useSmsTemplateVariables } from '../../../../../../services/hooks/useSmsTemplateVariables';
import { IPhoneHalfPreview } from '../../../../../../common/components/IPhonePreview/IPhoneHalfPreview';
import { MessageBox } from '../../../../../../common/components/IPhonePreview/IPhoneSMSPreview';
import { CustomizeInvitationsModal } from '../../../../../../common/components/CusomizeInvitationsModal/CustomizeInvitationsModal';
import { Tooltip } from '../../../../../../common/components/Tooltip/Tooltip';
import { useTrackEvent } from '../../../../../../hooks/useTrackEvent';
import { useParams } from 'react-router-dom';
import { RewardCampaignsEvents } from '../../../../../../common/constants/events/rewardCampaignsEvents';
import { postInvitationSelfInviteAPICall } from '../../../../../../store/slices/campaignsSlice';
import { useToasts } from '../../../../../../services/hooks/useToast';
import { formatPhoneNumber } from '../../../../../../services/utilities';

export const InvitationMessageTab: FC = () => {
  const classes = useStyles();
  const { id: campaignId } = useParams<{ id: string }>();

  const { trackEvent } = useTrackEvent();
  const { isHealthCare } = useClientType();
  const { addToast } = useToasts();

  const { currentCampaign } = useTypedSelector((state) => state.campaigns);
  const { phoneNumber, name } = useTypedSelector((store) => store.me);

  const inviteImage = currentCampaign?.invitationMsgConfig?.mediaUrl;
  const inviteMessage = replaceMessageBodyWithLocalVariables(
    currentCampaign?.invitationMsgConfig?.body || '',
    isHealthCare,
    true,
  );

  const templateVariables = useSmsTemplateVariables({
    ignoreVariables: currentCampaign?.isNullReward ? [LocalMessageVariables.RewardName] : [],
  });

  const constructedTestPostMessage = constructMessageFromTemplate(
    inviteMessage,
    templateVariables,
    true,
  );

  const {
    isOpen: isTestMessageModalOpen,
    open: openTestMessageModal,
    close: closeTestMessageModal,
  } = useModal();

  const {
    isOpen: isCustomizeInviteModalOpen,
    open: openCustomizeInviteModal,
    close: closeCustomizeInviteModal,
  } = useModal(false);

  const formattedNumber = phoneNumber?.startsWith('+1')
    ? formatPhoneNumber(phoneNumber, { showPlusOne: false })
    : phoneNumber;

  return (
    <Box className={classes.container}>
      <CustomizeInvitationsModal
        isOpenModal={isCustomizeInviteModalOpen}
        onClose={closeCustomizeInviteModal}
      />
      <SendTestMessageModal
        modalIsOpen={isTestMessageModalOpen}
        onClose={() => {
          closeTestMessageModal();
        }}
        testMessage={constructedTestPostMessage}
        onTestMessageSendClick={async () => {
          postInvitationSelfInviteAPICall({ campaignId })
            .then(() => {
              addToast(`${ElementsStrings.InviteSent}${formattedNumber}`, {
                appearance: 'success',
              });
            })
            .catch(() => {
              addToast(ElementsStrings.InviteSent, {
                appearance: 'success',
              });
            });
        }}
        phoneNumber={phoneNumber}
        name={name}
      />
      <Box className={classes.leftSideWrapper}>
        <Typography className={classes.title}>{ElementsStrings.InvitationText}</Typography>
        <Typography className={classes.subtitle}>
          {isHealthCare ? ElementsStrings.InvitationSentTrublu : ElementsStrings.InvitationSent}
          <Tooltip
            content={<Box>{ElementsStrings.VerifiedLink}</Box>}
            icon={
              <Typography display="inline" className={classes.linkText}>
                {ElementsStrings.PersonalizedLink}.
              </Typography>
            }
          ></Tooltip>
        </Typography>
        <Typography className={classes.settingsText}>{ElementsStrings.Settings}</Typography>
        <Box className={classes.previewWrapper}>
          <Box className={classes.imageWrapper}>
            <Typography className={classes.previewText}>{ElementsStrings.Image}</Typography>
            <img
              className={classes.inviteImage}
              src={inviteImage || '/image-preview.png'}
              alt="invite"
            />
          </Box>
          <Box className={classes.textPreviewWrapper}>
            <Typography className={classes.previewText}>{ElementsStrings.Text}</Typography>
            <TextareaAutosize
              defaultValue={inviteMessage}
              disabled={true}
              className={classes.inviteMessage}
              value={inviteMessage}
            />
          </Box>
        </Box>
        {!currentCampaign?.endedAt && (
          <Box className={classes.buttonsWrapper}>
            <RoundedButton
              className={classes.customizeButton}
              title={ElementsStrings.Customize}
              onClick={() => {
                openCustomizeInviteModal();
                trackEvent(RewardCampaignsEvents.OpenCustomizeInviteModal, {
                  campaignId,
                });
              }}
            />

            <RoundedButton
              className={classes.sendMessageButton}
              title={ElementsStrings.SendSampleMessage}
              outlined={true}
              onClick={() => {
                openTestMessageModal();
                trackEvent(RewardCampaignsEvents.OpenTestMessageModal, {
                  campaignId,
                });
              }}
            />
          </Box>
        )}
      </Box>
      <Box className={classes.rightSideWrapper}>
        <Typography className={classes.howAppearText}>
          {ElementsStrings.HowTextWillAppear}
        </Typography>
        <Box className={classes.phonePreviewWrapper}>
          <IPhoneHalfPreview height={900}>
            {inviteImage && (
              <img className={classes.inviteImagePhone} src={inviteImage} alt="invite" />
            )}
            <Box className={classes.messageBoxWrapper}>
              <MessageBox
                width="330px"
                fontSize={18}
                message={inviteMessage}
                linkTitle={CustomizeInvitationsModalStrings.PersonalizeLink}
                boldSubstrings={Object.values(LocalMessageVariables)}
                showStartBlock={false}
              />
            </Box>
          </IPhoneHalfPreview>
        </Box>
      </Box>
    </Box>
  );
};
