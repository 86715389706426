import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useModalsStyles = makeStyles((theme: Theme) => ({
  modalContent: {
    position: 'relative',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '890px',
    height: '690px',
    borderRadius: '12px',
    backgroundColor: theme.palette.common.white,
    padding: '24px',
  },
  closeIcon: {
    position: 'absolute',
    top: '25px',
    right: '22px',
    width: '24px',
    color: '#667085',
    cursor: 'pointer',
  },
  title: {
    color: '#101828',
    fontSize: '18px',
    fontWeight: '600',
    lineHeight: '28px',
    marginBottom: '20px',
  },
  invitationTitle: {
    color: '#101828',
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: '20px',
    marginBottom: '32px',
  },
  content: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  invitationContent: {
    marginRight: '195px',
  },
  buttons: {
    marginTop: '90px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '12px',
  },

  phonePreviewWrapper: {
    transform: 'scale(0.6)',
    position: 'absolute',
    top: '-120px',
    right: '-56px',
  },
  messageBoxWrapper: {
    pointerEvents: 'all',
    zIndex: 2,
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
  },
  inviteImagePhone: {
    width: '300px',
  },
  sendSamleButton: {
    height: '560px',
    width: '300px',
    display: 'flex',
    alignItems: 'end',
    marginLeft: '-26px',
  },
  messageWrapper: {
    marginTop: '20px',
    height: '220px',
  },
  messageBuilder: {
    minHeight: '217px',
    maxHeight: '217px',
  },
}));
