import { Box, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { FC, MouseEvent as ReactMouseEvent, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import clsx from 'clsx';
import { ClientTypes } from '../../../../../api/models/common';
import { useTypedSelector } from '../../../../../store';
import { ExpandLessIcon } from '../../../../assets/newDesign/ExpandLessIcon';
import { ExpandMoreIcon } from '../../../../assets/newDesign/ExpandMoreIcon';
import { SocialvenueManagerRoutes, WebsiteRoutes } from '../../../../constants/routes';
import {
  checkIsActiveSubItem,
  checkIsActiveSubMenuItem,
  IMenuItem,
  useStyles,
} from '../Menu.helper';
import { SVBadge } from '../Menu.style';
import { SubMenu } from '../subMenu/SubMenu';
import { SubMenuArrowsWrapper, SubMenuWrapper, topGolfMenuUsersItemStyles } from './MenuItem.style';
import { PulseNotification } from '../../../../components/PulseNotification/PulseNotification';
import { useQuickStartLaunchPad } from '../../../../../pages/QuickStart/QuickStartLaunchPadModal/useQuickStartLaunchPad';
import { AllAccountsIcon } from '../../../../assets/AllAccountsIcon';
import { BackButtonIcon } from '../../../../assets/newDesign/BackButtonIcon';
import { isUWMClient } from '../../../../../utils/isUWMClient';

interface MenuItemProps {
  item: IMenuItem;
  itemClickHandler: () => void;
  blur: boolean;
  FTEIsFinished: boolean;
  showPulseNotification?: boolean;
}

export const MenuItem: FC<MenuItemProps> = ({
  item,
  itemClickHandler,
  blur,
  FTEIsFinished,
  showPulseNotification,
}) => {
  const { pathname } = useLocation();
  const history = useHistory();

  const { clientType } = useTypedSelector((state) => state.venue.venue);

  const IsSubMenu = item.isSubMenu;

  const [isActiveItem, setIsActiveItem] = useState<boolean>(false);
  const [isSubMenuCollapsed, setIsSubMenuCollapsed] = useState<boolean>(
    FTEIsFinished ? false : true,
  );

  const { isStarted: isQuickstartFlow, isLaunchpadVisible } = useQuickStartLaunchPad();

  const classes = useStyles({
    active: isActiveItem,
    isSuperAdmin: item.isSuperAdmin ?? false,
    subItems: item.subMenuItems?.length ?? 0,
    isSubMenuOpened: isSubMenuCollapsed,
    blur,
    isGoogleButton: item.route === WebsiteRoutes.Google,
    isQSFlow: isQuickstartFlow && !isLaunchpadVisible,
  });

  const styles = topGolfMenuUsersItemStyles();

  useEffect(() => {
    const isSubMenuOpened = checkIsActiveSubMenuItem(pathname, item);
    setIsActiveItem(
      checkIsActiveSubItem(pathname, item.route, item.nested || false) || isSubMenuOpened,
    );

    if (FTEIsFinished) {
      setIsSubMenuCollapsed(isSubMenuOpened);
    }
  }, [IsSubMenu, item, pathname, blur, FTEIsFinished]);

  const onClickArrowItemHandler = (event?: ReactMouseEvent<HTMLDivElement>) => {
    event?.stopPropagation();
    setIsSubMenuCollapsed(!isSubMenuCollapsed);
  };

  const onClickItemHandler = () => {
    if (!blur && !isQuickstartFlow) {
      history.push(item.route);
      itemClickHandler();
    }
  };

  const getSubMenuArrows = () => {
    if (!IsSubMenu) {
      return null;
    }

    return (
      <SubMenuArrowsWrapper onClick={(event) => onClickArrowItemHandler(event)}>
        {isSubMenuCollapsed ? <ExpandMoreIcon /> : <ExpandLessIcon />}
      </SubMenuArrowsWrapper>
    );
  };

  const BadgeBlock = item.notification ? <SVBadge badgeContent={item.notification} /> : null;

  const isTopGolf = clientType === ClientTypes.TOPGOLF;
  const isUsersRoute =
    item.route === WebsiteRoutes.Heroes || item.route === WebsiteRoutes.Integrations;
  const isDisabled = (isTopGolf && isUsersRoute) || item.disabled;
  const showSubMenu = (item.subMenuItems?.length ?? 0) && isSubMenuCollapsed;

  return (
    <div
      className={`${classes.menueItemWrapper} ${
        item.route === SocialvenueManagerRoutes.Performance &&
        pathname !== SocialvenueManagerRoutes.AdminOrgManagement
          ? classes.accountItem
          : ''
      } `}
      style={{
        height: showSubMenu ? `${(item.subMenuItems?.length ?? 0) * 50}px` : '50px',
      }}
    >
      {
        <ListItemButton
          disabled={isDisabled}
          classes={{ root: classes.root, selected: classes.selected }}
          className={clsx({
            [styles.disabled]: isDisabled ? 'disabled' : '',
          })}
          selected={isActiveItem}
          disableGutters
          onClick={onClickItemHandler}
          sx={{
            '@keyframes shimmer': {
              '0%': {
                transform: 'translateX(-100%)',
              },
              '100%': {
                transform: 'translateX(50%)',
              },
            },
          }}
        >
          <ListItemIcon className={classes.icon}>{item.icon}</ListItemIcon>
          <ListItemText disableTypography className={classes.text} primary={item.text} />
          {showPulseNotification && <PulseNotification />}
          {BadgeBlock}
          {getSubMenuArrows()}
        </ListItemButton>
      }
      {IsSubMenu && isSubMenuCollapsed && (
        <SubMenuWrapper>
          <SubMenu
            item={item}
            isSubMenuCollapsed={isSubMenuCollapsed}
            onClickCollapseManagerHandler={onClickArrowItemHandler}
          />
        </SubMenuWrapper>
      )}
    </div>
  );
};

interface IAccountsButtonProps {
  handleClick: () => void;
  title: string;
  label: string;
  isActive: boolean;
  textAlign?: string;
  isCollapsed: boolean;
  isAllAccountsButton?: boolean;
}

export const AccountsButton: FC<IAccountsButtonProps> = ({
  handleClick,
  title,
  label,
  isActive,
  isCollapsed,
  isAllAccountsButton,
}) => {
  const classes = useStyles({
    active: isActive,
    isSuperAdmin: true,
    subItems: 0,
    isSubMenuOpened: true,
    blur: false,
    isAccountsButton: !isAllAccountsButton,
  });

  return (
    <Box
      sx={{
        width: isCollapsed ? '45px' : '90%',
      }}
      className={classes.accountsButtonWrapper}
    >
      <ListItemButton
        classes={{ root: classes.root, selected: classes.selected }}
        selected={isActive}
        disableGutters
        onClick={handleClick}
        sx={{
          display: 'flex',
          justifyContent: isAllAccountsButton ? 'start' : 'center',
          alignItems: 'center',
          px: '8px',
          paddingLeft: isAllAccountsButton ? '10px' : '0',
          '&:hover': {
            background: isUWMClient() ? 'rgba(0, 0, 0, 0.04)' : 'rgba(255, 255, 255, 0.1)',
          },
        }}
      >
        {isAllAccountsButton && (
          <Box className={classes.allAccountsIcon}>
            <AllAccountsIcon />
          </Box>
        )}
        <p>{title}</p>
        <p>{label}</p>
      </ListItemButton>
    </Box>
  );
};

interface IBackButtonProps {
  isMenuExpanded: boolean;
}

export const BackButton: FC<IBackButtonProps> = ({ isMenuExpanded }) => {
  const history = useHistory();
  const classes = useStyles({
    active: true,
    isSuperAdmin: true,
    subItems: 0,
    isSubMenuOpened: true,
    blur: false,
    isAccountsButton: true,
  });
  const onClickItemHandler = () => {
    history.goBack();
  };

  return (
    <Box
      sx={{
        flexShrink: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '90%',
        margin: '0 auto',
        marginTop: '20px',
      }}
    >
      <ListItemButton
        disabled={false}
        classes={{ root: classes.root, selected: classes.selected }}
        disableGutters
        onClick={onClickItemHandler}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box className={classes.buttonBackWrapper}>
          <Box
            className={classes.iconBack}
            style={{
              marginLeft: isMenuExpanded ? '' : '10px',
            }}
          >
            <BackButtonIcon />
          </Box>
          {isMenuExpanded && (
            <ListItemText disableTypography className={classes.iconBackText} primary={'Back'} />
          )}
        </Box>
      </ListItemButton>
    </Box>
  );
};
