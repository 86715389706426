import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DistributorEndpoints } from '../../api/endpoints';
import { httpClient } from '../../services/httpClient/httpClient';
import {
  DistributorModel,
  GetDistributorByIdOrCodeRequest,
  GetDistributorByIdOrCodeResponse,
  GetDistributorsRequest,
  GetDistributorsResponse,
  PostDistributorRequest,
  PostDistributorResponse,
  PutDistributorResponse,
  PutDistributorsRequest,
} from '../../api/models/distributors';

const initialState = {
  distributors: [] as DistributorModel[],
  activeDistributor: undefined as DistributorModel | undefined,
  isLoading: false,
  isError: false,
};

export const getDistributors = createAsyncThunk(
  'distributors/getDistributors',
  async (_request: GetDistributorsRequest, { rejectWithValue }) => {
    try {
      return httpClient.get<GetDistributorsRequest, GetDistributorsResponse>({
        url: DistributorEndpoints.GetDistributors,
        requiresToken: true,
      });
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const getDistributorByIdOrCode = createAsyncThunk(
  'distributor/getDistributorByIdOrCode',
  async ({ idOrCode }: GetDistributorByIdOrCodeRequest, { rejectWithValue }) => {
    try {
      return httpClient.get<GetDistributorByIdOrCodeRequest, GetDistributorByIdOrCodeResponse>({
        url: DistributorEndpoints.GetDistributorByIdOrCode.replace(':idOrCode', idOrCode),
        requiresToken: true,
      });
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const createNewDistributor = createAsyncThunk(
  'distributors/createNewDistributor',
  async ({ payload }: PostDistributorRequest, { rejectWithValue }) => {
    try {
      return httpClient.post<PostDistributorRequest['payload'], PostDistributorResponse>({
        url: DistributorEndpoints.PostDistributors,
        requiresToken: true,
        payload,
      });
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const updateDistributor = createAsyncThunk(
  'distributors/updateDistributor',
  async ({ id, payload }: PutDistributorsRequest, { rejectWithValue }) => {
    try {
      return httpClient.put<PutDistributorsRequest['payload'], PutDistributorResponse>({
        url: DistributorEndpoints.PutDistributors.replace(':id', id),
        requiresToken: true,
        payload,
      });
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

const distributorsSlice = createSlice({
  name: 'distributors',
  initialState,
  reducers: {
    reset(state) {
      state = initialState;
    },
    setActiveDistributor(state, action: PayloadAction<DistributorModel | undefined>) {
      state.activeDistributor = action.payload;
    },
  },
  extraReducers: (reducersBuilder) => {
    reducersBuilder.addCase(getDistributors.pending, (state) => {
      state.isLoading = true;
    });
    reducersBuilder.addCase(getDistributors.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });
    reducersBuilder.addCase(getDistributors.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.isError = false;
      state.distributors = payload;
    });
  },
});

export const { reset, setActiveDistributor } = distributorsSlice.actions;

export default distributorsSlice.reducer;
