export enum SearchOperationType {
  Equals = 'EQUALS',
  In = 'IN',
  GreaterThan = 'GREATER_THAN',
  LessThan = 'LESS_THAN',
  InRange = 'IN_RANGE',
  Exists = 'EXISTS',
}

export enum SearchTarget {
  USERS = 'USERS',
  VIDEOS = 'VIDEOS',
}

export interface SearchFilterApiModel {
  id: string;
  accountId: string;
  name: string;
  target: SearchTarget;
  searchText: string;
  createdAt: string;
  updatedAt: string;
  createdBy: string;
  lastModifiedBy: string;
}

export enum VideoAttributeName {
  CreatedAt = 'createdAt',
  CampaignId = 'campaignId',
  Length = 'duration',
  Status = 'status',
  VideosCount = 'videos',
}

export interface CreatedAtFilterAttribute {
  name: VideoAttributeName.CreatedAt;
  operation: SearchOperationType.GreaterThan | SearchOperationType.LessThan;
  value: string;
}

export interface CampaignsFilterAtAttribute {
  name: VideoAttributeName.CampaignId;
  operation: SearchOperationType.In;
  value: string[];
}

export interface VideoLengthFilterAtAttribute {
  name: VideoAttributeName.Length;
  operation: SearchOperationType.GreaterThan | SearchOperationType.LessThan;
  value: number;
}

export enum VideoStatusType {
  Published = 'PUBLISHED',
  Unpublished = 'UNPUBLISHED',
  Moderated = 'MODERATED',
  PendingModeration = 'PENDING_MODERATION',
}

export interface VideoStatusFilterAtAttribute {
  name: VideoAttributeName.Status;
  operation: SearchOperationType.Equals;
  value: VideoStatusType;
}

export type VideoFilterAttribute =
  | CreatedAtFilterAttribute
  | CampaignsFilterAtAttribute
  | VideoLengthFilterAtAttribute
  | VideoStatusFilterAtAttribute;

export interface VideoFilterApiModel extends SearchFilterApiModel {
  filter: {
    attributes: VideoFilterAttribute[];
  };
}

export enum CreatorAttributeName {
  ClipsCount = 'videos',
  PhoneNumberVerified = 'phoneNumberVerified',
  OptedIn = 'smsOptedIn',
  LastVideoCreatedAt = 'lastVideoCreatedAt',
  RewardsFulfilled = 'rewardsFulfilled',
  Status = 'status',
}

export enum CreatorStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
}

export interface ClipsCountFilterAttribute {
  name: CreatorAttributeName.ClipsCount;
  operation: SearchOperationType.GreaterThan | SearchOperationType.LessThan;
  value: number;
}

export interface VerifiedFilterAttribute {
  name: CreatorAttributeName.PhoneNumberVerified;
  operation: SearchOperationType.Equals;
  value: boolean;
}

export interface IsOptedOutFilterAttribute {
  name: CreatorAttributeName.OptedIn;
  operation: SearchOperationType.Equals;
  value: false;
}

export interface LastVideoCreatedAtFilterAttribute {
  name: CreatorAttributeName.LastVideoCreatedAt;
  operation: SearchOperationType.GreaterThan | SearchOperationType.LessThan;
  value: string;
}

export interface RewardedFilterAttribute {
  name: CreatorAttributeName.RewardsFulfilled;
  operation: SearchOperationType.GreaterThan | SearchOperationType.LessThan;
  value: number;
}

export interface UserStatusFilterAttribute {
  name: CreatorAttributeName.Status;
  operation: SearchOperationType.Equals;
  value: CreatorStatus;
}

export type CreatorFilterAttribute =
  | ClipsCountFilterAttribute
  | VerifiedFilterAttribute
  | IsOptedOutFilterAttribute
  | LastVideoCreatedAtFilterAttribute
  | RewardedFilterAttribute
  | UserStatusFilterAttribute;

export interface CreatorFilterApiModel extends SearchFilterApiModel {
  filter: {
    attributes: CreatorFilterAttribute[];
  };
}
