export enum RewardCampaignsEvents {
  RewardCampaignsPageLoad = 'reward_campaigns_page',
  RewardCampaignsTableSort = 'reward_campaigns_table_sort_by_name_click',
  RewardCampaignsTablePreviewButtonClick = 'reward_campaigns_table_preview_button_click',
  reward_campaigns_table_campaign_link_copy = 'reward_campaigns_table_campaign_link_copy',
  reward_campaigns_table_download_branded_qr_code = 'reward_campaigns_table_download_branded_qr_code',
  reward_campaigns_table_download_default_qr_code = 'reward_campaigns_table_download_default_qr_code',

  RewardCampaignsNewRewardClick = 'reward_campaigns_new_reward_click',
  RewardCampaignsDetails = 'reward_campaigs_details',
  RewardCampaignsDetailsBackwardClick = 'reward_campaigs_details_backward_click',
  RewardCampaignsDetailsAutoFulfillClick = 'reward_campaigs_details_auto_fulfill_click',
  RewardCampaignsDetailsManualFulfillClick = 'reward_campaigs_details_manual_fulfill_click',
  RewardCampaignsDetailsManualFulfillCloseClick = 'reward_campaigs_details_manual_fulfill_close_click',
  RewardCampaignsDetailsAutoFulfillCloseClick = 'reward_campaigs_details_auto_fulfill_close_click',
  RewardCampaignsDetailsChangeAutoToManualFulfillConfirmClick = 'reward_campaigs_details_change_auto_to_manual_fulfill_confirm_click',
  RewardCampaignsDetailsChangeManualToAutoFulfillConfirmClick = 'reward_campaigs_details_change_manual_to_auto_fulfill_confirm_click',
  RewardCampaignsDetailsEndCampaignClick = 'reward_campaigs_details_end_campaign_click',
  RewardCampaignsDetailsReuseCampaignClick = 'reward_campaigs_details_reuse_campaign_click',
  RewardCampaignsDetailsReuseCampaignCloseClick = 'reward_campaigs_details_reuse_campaign_close_click',
  RewardCampaignsDetailsEndCampaignCloseClick = 'reward_campaigs_details_end_campaign_close_click',
  RewardCampaignsDetailsEndCampaignConfirmClick = 'reward_campaigs_details_end_campaign_confirm_click',
  RewardCampaignsDetailsReuseCampaignConfirmClick = 'reward_campaigs_details_reuse_campaign_confirm_click',

  RewardCampaignsAddCustomerClick = 'reward_campaigs_add_customer_click',
  RewardCampaignsAddPatientClick = 'reward_campaigs_add_patient_click',
  RewardCampaignsAddPatientConfirmClick = 'reward_campaigs_add_patient_confirm_click',
  RewardCampaignsAddCustomerConfirmClick = 'reward_campaigs_add_customer_confirm_click',
  RewardCampaignsAddCustomerCloseClick = 'reward_campaigs_add_customer_close_click',
  RewardCampaignsAddPatientCloseClick = 'reward_campaigs_add_patient_close_click',
  RewardCampaignsAddCustomerFirstNameTyped = 'reward_campaigs_add_customer_firstname_typed',
  RewardCampaignsAddCustomerLastNameTyped = 'reward_campaigs_add_customer_lastname_typed',
  RewardCampaignsAddCustomerEmailTyped = 'reward_campaigs_add_customer_email_typed',
  RewardCampaignsAddCustomerCellTyped = 'reward_campaigs_add_customer_cell_typed',
  RewardCampaignsAddCustomerChangeOptIn = 'reward_campaigs_add_customer_change_optIn',
  RewardCampaignsAddCustomerDateTyped = 'reward_campaigs_add_customer_date_typed',

  RewardCampaginRewardNowClick = 'reward_campaigs_reward_now_click',
  RewardCampaginRewardNowConfirmClick = 'reward_campaigs_reward_now_confirm_click',
  RewardCampaginRewardNowCloseClick = 'reward_campaigs_reward_now_close_click',
  RewardCampaginAcknowledgeNow = 'reward_campaigs_acknowledge_now_click',

  RewardCampaginActiveCustomersTableViewAllTabClick = 'reward_campaigs_tab_table_subtab_click',

  RewardCampaginActiveCustomersTabClick = 'reward_campaigs_active_customers_tab_click',
  RewardCampaginInvitedTabClick = 'reward_campaigs_invited_customers_tab_click',

  RewardCampaginTableRowClick = 'reward_campaigs_table_row_click',

  RewardCampaignsDetailsDownloadReportClick = 'reward_campaigs_details_download_report_click',
  RewardCampaignsTableSearchbarSubmitClick = 'reward_campaigs_table_searchbar_submit_click',
  RewardCampaignsTableSearchbarResetClick = 'reward_campaigs_table_searchbar_reset_click',
  RewardCampaignsDetailsModalEditCampaignClick = 'reward_campaigs_table_details_modal_edit_campaign_click',
  RewardCampaignsDetailsModalSaveCampaignClick = 'reward_campaigs_table_details_modal_save_campaign_click',
  RewardCampaignsDetailsModalSelectCampaign = 'reward_campaigs_table_details_modal_select_campaign',
  RewardCampaignsTableDetailsModalRewardTabRewarded = 'reward_campaigs_table_details_modal_reward_tab_rewarded',
  RewardCampaignsDetailsModalRewardTabFulfillRewardClick = 'reward_campaigs_table_details_modal_reward_tab_fulfill_reward_click',
  RewardCampaignsDetailsModalRewardTabRewardFulfillModalCloseClick = 'reward_campaigs_table_details_modal_reward_tab_reward_fulfill_modal_close_click',
  RewardCampaignsDetailsModalConfirmFulfillRewardClick = 'reward_campaigs_table_details_modal_reward_tab_confirm_reward_click',
  RewardCampaignsDetailsModalVideosTabClick = 'reward_campaigs_table_details_modal_videos_tab_click',
  RewardCampaignsDetailsModalRewardTabClick = 'reward_campaigs_table_details_modal_reward_tab_click',

  RewardCampaignsDetailsModalAddBonusPointsClick = 'reward_campaigns_table_details_modal_reward_tab_add_bonus_points_click',
  RewardCampaignsDetailsModalAddBonusPointsConfirmClick = 'reward_campaigns_table_details_modal_reward_tab_add_bonus_points_confirm_click',
  RewardCampaignsDetailsModalBonusPointsAdded = 'reward_campaigns_table_details_modal_reward_tab_bonus_points_added',
  RewardCampaignsDetailsModalConfirmAddBonusPointsModalClose = 'reward_campaigns_table_details_modal_reward_tab_add_bonus_points_modal_close',
  RewardCampaignsRewardNowClick = 'reward_campaigns_table_reward_now_click',
  RewardCampaignsRewardNowCloseClick = 'reward_campaigns_table_reward_now_close_click',
  RewardCampaignsRewardNowConfirmClick = 'reward_campaigns_table_reward_now_confirm_click',

  RewardCampaignsDetailsModalStoryClick = 'reward_campaigns_table_details_modal_story_click',
  RewardCampaignsDetailsModalStoryCloseClick = 'reward_campaigns_table_details_modal_story_close_click',
  RewardCampaignsDetailsModalStoryPauseClick = 'reward_campaigns_table_details_modal_story_pause_click',
  RewardCampaignsDetailsModalStoryPlayClick = 'reward_campaigns_table_details_modal_story_play_click',

  RewardCampaignsActiveCustomersTableSortClick = 'reward_campaigns_table_active_customers_sort_by_column_click',
  RewardCampaignsInvitedCustomersTableSortClick = 'reward_campaigns_table_invited_customers_sort_by_column_click',

  RewardCampaignsInvitedCustomersTableResendInviteClickClick = 'reward_campaigns_table_invited_customers_resend_invite_click',

  RewardCampaignSummaryPageQRCodeButtonClick = 'reward_campaign_summary_page_qr_code_button_click',
  RewardCampaignSummaryPageImportDataButtonClick = 'reward_campaign_summary_page_import_data_button_click',
  RewardCampaignSummaryPageGoToSendInvitesTabClick = 'reward_campaign_summary_page_go_to_send_invites_tab_click',
  RewardCampaignSummaryPageImportFileModalClose = 'reward_campaign_summary_page_import_file_modal_close',
  RewardCampaignSummaryImportManuallyButtonClick = 'reward_campaign_summary_page_import_manually_button_click',
  RewardCampaignSummaryEditVideoClick = 'reward_campaign_summary_page_edit_video_button_click',
  RewardCampaignSummaryPageImportManuallyModalClose = 'reward_campaign_summary_page_import_manually_modal_close',
  RewardCampaignSummaryPageEditButtonClick = 'reward_campaign_summary_page_edit_button_click',

  reward_campaign_summary_page_no_invites_button_click = 'reward_campaign_summary_page_no_invites_button_click',
  reward_campaign_summary_page_import_data_no_invites_button_click = 'reward_campaign_summary_page_import_data_no_invites_button_click',
  reward_campaign_summary_page_import_manually_no_invites_button_click = 'reward_campaign_summary_page_import_manually_no_invites_button_click',
  reward_campaign_summary_page_import_manually_no_invites_modal_close = 'reward_campaign_summary_page_import_manually_no_invites_modal_close',
  reward_campaign_summary_page_import_file_no_invites_modal_close = 'reward_campaign_summary_page_import_file_no_invites_modal_close',

  RewardEnableClick = 'reward_campaigns_table_details_modal_enable_reward',

  RewardCampaignSummaryPageVideosSliderPrevButtonClick = 'reward_campaign_summary_page_video_slider_prev_button_click',
  RewardCampaignSummaryPageVideosSliderNextButtonClick = 'reward_campaign_summary_page_video_slider_next_button_click',

  RewardCampaignSummaryPageVideosSliderVideoClick = 'reward_campaign_summary_page_video_slider_video_click',
  RewardCampaignSummaryPageActionsMenuOpen = 'reward_campaign_summary_page_actions_menu_open',
  RewardCampaignSummaryPageActionsMenuClose = 'reward_campaign_summary_page_actions_menu_close',
  RewardCampaignSummaryPageActionsMenuItemClick = 'reward_campaign_summary_page_actions_menu_item_click',

  RewardCampaignSummaryPageLimitCampaignUsersModalClose = 'reward_campaign_summary_page_limit_campaign_users_modal_close',
  RewardCampaignSummaryPageLimitCampaignUsersModalConfirmButtonClick = 'reward_campaign_summary_page_limit_campaign_users_modal_confirm_button_click',

  RewardCampaignSummaryPageUnlockLastCampaignModalClose = 'reward_campaign_summary_page_unlock_last_campaign_modal_close',
  RewardCampaignSummaryPageUnlockLastCampaignModalConfirmButtonClick = 'reward_campaign_summary_page_unlock_last_campaign_modal_confirm_button_click',

  RewardCampaignSummaryPageNotificationModalChangeMethodClick = 'reward_campaign_summary_page_notification_modal_change_method_click',
  RewardCampaignSummaryPageNotificationModalClose = 'reward_campaign_summary_page_notification_modal_close',

  RewardCampaignPendingTabClick = 'reward_campaign_pending_tab_click',
  RewardCampaignUnsentInvitesTabClick = 'reward_campaign_unsent_invites_tab_click',
  RewardCampaignSummaryUnsentInvitesSelectAll = 'reward_campaign_summary_unsent_invites_select_all_click',
  RewardCampaignSummaryUnsentInvitesDeselectAll = 'reward_campaign_summary_unsent_invites_deselect_all_click',
  RewardCampaignSummaryInviteSelectedClick = 'reward_campaign_summary_invite_selected_transactions_click',
  RewardCampaignSummaryTransactionSelect = 'reward_campaign_summary_unsent_invite_transaction_select',
  RewardCampaignSummaryTransactionDeselect = 'reward_campaign_summary_unsent_invite_transaction_deselect',
  RewardCampaignSummaryNameChange = 'reward_campaign_summary_name_change',
  RewardCampaignSummaryNameChangeByKeyboard = 'reward_campaign_summary_name_change_by_keyboard',
  RewardCampaignSummaryCancelNameChangeByKeyboard = 'reward_campaign_summary_cancel_name_change_by_keyboard',
  InviteCustomersTabOpen = 'invite_customers_tab_open',
  InvitationMessageTabOpen = 'invitation_message_tab_open',
  WelcomeVideoTabOpen = 'welcome_video_tab_open',
  RewardMessageTabOpen = 'reward_message_tab_open',
  VideoSharingTabOpen = 'video_sharing_tab_open',
  SendInvitesTabOpen = 'send_invites_tab_open',
  SelectQrCodeType = 'select_qr_code_type',
  OpenCustomizeInviteModal = 'open_customize_invite_modal',
  OpenTestMessageModal = 'open_test_message_modal',
  OpenRewardTestMessageModal = 'open_reward_test_message_modal',
  OpenWelcomeVideoModal = 'open_welcome_video_modal',
  OpenCustomizeRewardModal = 'open_customize_reward_modal',
  OpenCallToActionModal = 'open_call_to_action_modal',
}
