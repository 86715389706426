import { CampaignUserApiModel, UserApiModel, UserMetricsAPIModel } from '../api/models/users';
import { VideoApiModel } from '../api/models/videos';
import { VenueAPIModel, VenueProperties } from '../api/models/venue';
import {
  KPIApiModel,
  KPIValueApiModel,
  KPIApiNames,
  GlobalAnalyticsModel,
} from '../api/models/analytics';
import {
  ActivityApiModel,
  RewardsCalculationApiModel,
  RewardsListApiModel,
} from '../api/models/rewards';
import { ETSCampaignData } from '../api/models/venueCreation';
import { VideoFilterApiModel } from '../api/models/filters';
import { Frame } from '../common/components/EditThumbnail/EditThumbnail';
import { CampaignsApiModel } from '../api/models/campaigns';

export interface TableDTO<T> {
  items: T[];
  itemsSplit?: {
    active: T[];
    pending: T[];
    deactivated: T[];
  };
  totalPages: number;
  totalItems: number;
  page: number;
  size: number;
  sort: string[];
  search?: string;
  itemId?: string;
  isLoading: boolean;
  isLoaded?: boolean;
  error: boolean;
  lastUpdated: string;
  // adding a custom property to be reused for any tableDTO if needed
  property_1?: string;
  teeTime?: string;
  property_2?: {
    email: string;
    name: string;
  };
  selectedRows?: VideoApiModel[] | undefined;
  previousPage?: number;
  previousSize?: number;
  selectedRow?: any;
  pagination?: any;
  activeUsersCount?: number;
  pendingUsersCount?: number;
  deactivatedUsersCount?: number;
}

export interface Loadable<T> {
  isLoading: boolean;
  isError: boolean;
  items: T[];
  lastUpdated: string;
}

export interface UserDetailsDataDTO {
  isLoadingUserDetails: boolean;
  isLoadingCampaignUser: boolean;
  userDetails: UserApiModel | null;
  campaignUser: CampaignUserApiModel | null;
  isUserDetailsError: boolean;
  userMetrics?: {
    isLoadingUserMetrics: boolean;
    isErrorUserMetrics: boolean;
    metrics: UserMetricsAPIModel | null;
  };
  activities: Loadable<ActivityApiModel>;
  lastUpdated: string;
  rewardApproved: boolean;
  isLoadingRewardApproval: boolean;
  isErrorRewardApproval: boolean;
  isLoadingAddBonusPoints: boolean;
  isErrorAddBonusPoints: boolean;
  AwardedBonusPoints: boolean;
  latestActiveCampaign: CampaignsApiModel | null;
}

export interface CampaignsVideosDTO {
  [key: string]: TableDTO<VideoApiModel>;
}

export interface KPIDTOActiveAnalyticsBlock {
  mainName: string;
  main: KPIApiNames | null;
  sub: KPIApiNames | null;
}
export interface KPIDTO {
  kpis: KPIApiModel;
  kpiValues: KPIValueApiModel[];
  activeAnalyticsBlock: KPIDTOActiveAnalyticsBlock;
  lastUpdated: string;
  isLoading: boolean;
  rangeFilter: RangeFilterDTO | null;
  error: null | any;
  globalAnalytics: GlobalAnalyticsModel;
  isKpisLoading: boolean;
}

export interface RangeFilterDTO {
  from: string;
  to: string;
}

export interface RangeFilter {
  from?: Date | null;
  to?: Date | null;
  label?: string;
}

export interface VenueDTO extends VenueAPIModel {
  isLoading: boolean;
  error: boolean;
  lastUpdated: string;
}

export interface VenueAttributesDTO {
  isLoading: boolean;
  error: boolean;
  lastUpdated: string;
  properties: VenueProperties | null;
}
export interface VenuesListDTO {
  managerId: string;
  venueId: string;
  venueName: string;
  venueLogo: string;
  venueOrgId: string;
  isActive: boolean;
}

export interface UIState {
  sidebarOpen: boolean;
  isCummulativeGraph: boolean;
  emoji: {
    loading: boolean;
    initialized: boolean;
  };
  wasInvitingModalDisplayed: boolean;
  scrollY?: number;
  elementsActiveTabIndex: number;
  isMenuExpanded: boolean;
  isTabletView: boolean;
  isWindowLoaded: boolean;
  isAccountsSidebarOpen: boolean;
  isDistributorsMenuExpanded: boolean;
}
export interface MeDTO {
  id: string;
  createdAt: string;
  email: string;
  name: string;
  photoUrl: string;
  updatedAt: string;
  enabled: boolean;
  isLoading: boolean;
  refId: string;
  role: string;
  auth: boolean;
  authError: boolean;
  resetPasswordError: string;
  accountId: string;
  authStatusCode?: number;
  accounts: number;
  phoneNumber?: string;
  firstName: string;
  lastName: string;
  details?: Record<string, unknown>;
}

export interface RewardsActivitiesDTO {
  activities: ActivityApiModel[];
  calculationResult: RewardsCalculationApiModel | null;
  isLoading: boolean;
  error: boolean;
  lastUpdated: string;
}

export interface RewardsListEditItemDTO extends RewardsListApiModel {
  changed?: boolean;
  created?: boolean;
}

export interface RewardsListDTO {
  initialItems: RewardsListApiModel[];
  editItem: RewardsListApiModel | null;
  editItems: RewardsListEditItemDTO[];
  addedItems: RewardsListEditItemDTO[];
  isLoading: boolean;
  error: boolean;
  lastUpdated: string;
}

// export interface OrgManagementDTO {
//   items: OrgManagementListApiModel[];
//   editItem: OrgManagementListApiModel | null;
//   isLoading: boolean;
//   lastUpdated: string;
// }

export interface ParticipantsDTO {
  participant_id: string;
  participant_name: string;
  participant_phone: string;
  participant_email: string;
  participant_landing_url: string;
}
export interface AccountsDTO {
  account_id: string;
  account_email: string;
  account_name: string;
  account_role_title: string;
}

export interface VenueCreationDTO {
  program_payload: VenueCreationModel;
}
export interface VenueCreationModel {
  program_id: string;
  program_name: string;
  organization_id: string;
  school_name: string;
  program_logo_url: string;
  program_logo_data: string;
  program_primary_color: string;
  program_secondary_color: string;
  campaign_id: string;
  campaign_start_date: string | null;
  campaign_name: string;
  default_campaign_landing_url: string;
  participants: ParticipantsDTO[];
  accounts: AccountsDTO[];
}
export const VenueCreationLoginPayload = {
  program_id: 'prog_123',
  program_name: 'first program',
  organization_id: 'org_123',
  school_name: 'ets',
  program_logo_url: 'https://i.pravatar.cc/300',
  program_logo_data: 'data',
  program_primary_color: '#464C71',
  program_secondary_color: '#000',
  campaign_id: 'comp_123',
  campaign_start_date: '01/02/22',
  campaign_name: 'first compaign',
  default_campaign_landing_url: 'https://www.google.com',
  participants: [
    {
      participant_id: 'part_1',
      participant_name: 'part_1_name',
      participant_phone: '99999999',
      participant_email: 'part1@gmail.com',
      participant_landing_url: 'https://www.google.commmmmmmmmmasd.asdasdads.asdasdads',
    },
  ],
  accounts: [
    {
      account_id: 'acc_1',
      account_email: 'acct_1@gmail.com.asdasda..adsasdadas.adasda',
      account_name: 'acc_1_name',
      account_role_title: 'account_1_role',
    },
    {
      account_id: 'acc_2',
      account_email: 'acct_2@gmail.com',
      account_name: 'acc_2_name',
      account_role_title: 'account_2_role',
    },
    {
      account_id: 'acc_3',
      account_email: 'acct_3@gmail.com',
      account_name: 'acc_3_name',
      account_role_title: 'account_3_role',
    },
  ],
};

export interface VenueCreationStoreModel {
  program_payload: ETSCampaignData;
  campaignId: string;
  filterUrls: string[];
  filterUrlsLoading: boolean;
  subdomain: {
    isLoading: boolean;
    available: boolean;
    checked: boolean;
  };
  createdVenue: {
    payload: VenueAPIModel | null;
    isLoading: boolean;
    created: boolean;
    initiated: boolean;
    error?: any;
  };
  enterpriceRequest: {
    error?: any;
    isLoading: boolean;
    created: boolean;
  };
}
export type VideoFiltersDTO = VideoFilterApiModel[];

export interface User {
  email: string;
  name: string;
  password: string;
}
export interface VenuFilters {
  url: string;
  isActive: boolean;
}

export interface ManagerAccount {
  email: string;
  name: string;
  role: string;
  password: string;
  submited: boolean;
}

export interface Organization {
  companyName: string;
  logo: string;
  TeamName: string;
}
export interface AdminVenueCreationStoreModel {
  market: string;
  organization: Organization | null;
  subdomain: string;
  primaryColor: string;
  secondaryColor: string;
  reward: {
    name: string;
    imageUrl: string;
  };
  filters: VenuFilters[];
  users: ManagerAccount[];
}

export enum UploadProcessStep {
  AddCellPhone,
  AddHandle,
  AddPassword,
  AddEmail,
  UploadVideo,
  PrepareVideo,
  ConversionDone,
  VideoUploading,
  Uploaded,
}

export interface UploadProcessDTO {
  currentStep: UploadProcessStep;
  cellPhone: string;
  handle: string;
  password: string;
  email: string;
  uploadingProgress: number;
  supportedMimeType: string;
  fileSelected: boolean;
  thumbnailFrameurl: Frame | null;
}

export interface PeekAccount {
  id: string;
  accountId: string;
  peekAccountId: string;
  deletedAt: string;
  updatedAt: string;
  createdBy: string;
  lastModifiedBy: string;
}
