import { Box, Button, Typography } from '@mui/material';
import { useStyles } from './IntroVideo.styles';
import { CampaignSummaryIntroVideoStrings } from '../../../../../common/localization/en';
import { useModal } from '../../../../../hooks/useModal';
import IntroVideoModal from './IntroVideoModal/IntroVideoModal';
import { useVenueProperty } from '../../../../../hooks/useVenueProperty';
import { VenueProperties } from '../../../../../api/models/venue';

const IntroVideo = () => {
  const classes = useStyles();
  const { value: introVideo, update: updateVideoUrl } = useVenueProperty<
    VenueProperties['webapp.uwm.intro-video']
  >({
    property: 'webapp.uwm.intro-video',
  });

  const { isOpen, close, open } = useModal();

  return (
    <>
      {isOpen && (
        <IntroVideoModal
          isOpen={isOpen}
          onClose={close}
          videoUrl={introVideo?.videoUrl}
          thumbnailUrl={introVideo?.thumbnailUrl}
          updateVideoUrl={updateVideoUrl}
        />
      )}
      <Box className={classes.container}>
        <Typography className={classes.title}>
          {CampaignSummaryIntroVideoStrings.IntroVideo}
        </Typography>
        <Typography className={classes.text}>
          {CampaignSummaryIntroVideoStrings.ForMortgageProfile}
        </Typography>
        <Button variant="outlined" className={classes.button} onClick={open}>
          {introVideo?.videoUrl
            ? CampaignSummaryIntroVideoStrings.Customize
            : CampaignSummaryIntroVideoStrings.AddVideo}
        </Button>
      </Box>
    </>
  );
};

export default IntroVideo;
