import { FC } from 'react';

export const PauseIconLaunchPad: FC = () => (
  <svg width="98" height="98" viewBox="0 0 98 98" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_b_1666_33710)">
      <circle cx="49.0005" cy="48.6899" r="48.6899" fill="white" fillOpacity="0.9" />
    </g>
    <path
      d="M46.6375 61.4425V36.5575C46.6375 34.195 45.64 33.25 43.12 33.25H36.7675C34.2475 33.25 33.25 34.195 33.25 36.5575V61.4425C33.25 63.805 34.2475 64.75 36.7675 64.75H43.12C45.64 64.75 46.6375 63.805 46.6375 61.4425Z"
      fill="#A9A9A9"
    />
    <path
      d="M64.7508 61.4425V36.5575C64.7508 34.195 63.7533 33.25 61.2333 33.25H54.8808C52.3783 33.25 51.3633 34.195 51.3633 36.5575V61.4425C51.3633 63.805 52.3608 64.75 54.8808 64.75H61.2333C63.7533 64.75 64.7508 63.805 64.7508 61.4425Z"
      fill="#A9A9A9"
    />
    <defs>
      <filter
        id="filter0_b_1666_33710"
        x="-3.68945"
        y="-4"
        width="105.381"
        height="105.38"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="2" />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_1666_33710" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_backgroundBlur_1666_33710"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);
