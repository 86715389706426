import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '822px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  leftSideWrapper: {
    width: '508px',
    padding: '0 20px',
  },
  title: {
    color: theme.palette.primary.main,
    fontFamily: 'Poppins',
    fontSize: '26px',
    fontWeight: '600',
    lineHeight: '65px',
    letterSpacing: '-0.52px',
  },
  subtitle: {
    fontSize: '18px',
    fontFamily: 'Poppins',
    color: '#475467',
    lineHeight: '24px',
  },
  settingsText: {
    fontSize: '18px',
    fontFamily: 'Poppins',
    color: '#101828',
    lineHeight: '28px',
    fontWeight: 600,
    marginTop: '18px',
    marginBottom: '12px',
  },
  sectionWrapper: {
    display: 'flex',
    marginTop: '6px',
  },
  sectionTitle: {
    fontSize: '14px',
    fontFamily: 'Poppins',
    color: theme.palette.primary.main,
    lineHeight: '20px',
    fontWeight: 500,
  },
  sectionValue: {
    fontSize: '16px',
    fontFamily: 'Poppins',
    color: '#344054',
    lineHeight: '20px',
    fontWeight: 500,
  },
  customizeButton: {
    fontFamily: 'Inter',
    width: '116px',
    height: '40px',
    padding: '0',
    borderRadius: '8px',
    marginRight: '12px',
    marginTop: '18px',
    '&:hover': {
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      transform: 'scale(0.99)',
    },
    '&:disabled': {
      backgroundColor: theme.palette.primary.main,
    },

    '& p': {
      fontSize: '14px',
      fontWeight: 600,
    },
  },
  rightSideWrapper: {
    width: '290px',
  },
  previewWrapper: {
    transform: 'scale(0.8)',
    position: 'absolute',
    top: '-30px',
  },
  howAppearText: {
    fontSize: '13px',
    fontFamily: 'Inter',
    color: '#475467',
    lineHeight: '18px',
    fontWeight: 400,
    textAlign: 'center',
  },
}));
