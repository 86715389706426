import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, IconButton, Modal, Tab, Tabs, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import {
  CampaignFulfillmentConfig,
  PatchIncentiveCampaignApiModel,
} from '../../../../../../../api/models/campaigns';
import { MessageFrequency } from '../../../../../../../api/models/rewardFulfillment';
import { replaceMessageBodyWithApiVariables } from '../../../../../../../common/components/SMS/SMS.helper';
import { CommonStrings, ElementsStrings } from '../../../../../../../common/localization/en';
import { useClientType } from '../../../../../../../services/hooks/useClientType';
import { useAppDispatch, useTypedSelector } from '../../../../../../../store';
import { patchIncentiveCampaign } from '../../../../../../../store/slices/campaignsSlice';
import { setEditCampaign } from '../../../../../../../store/slices/createCampaignSlice';
import { useStyles } from './CustomizeRewardMessageModal.styles';
import { RewardImageSectionPreview } from './sections/CustomizeRewardMessagePreview';
import { DeliveryOptionsSection } from './sections/DeliveryOptionsSection/DeliveryOptionsSection';
import { MessageTextSection } from './sections/MessageTextSection/MessageTextSection';
import { RewardImageSection } from './sections/RewardImageSection/RewardImageSection';

interface CustomizeRewardMessageModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSendSampleMessageClick?: () => void;
}

export const CustomizeRewardMessageModal: React.FC<CustomizeRewardMessageModalProps> = ({
  isOpen,
  onClose,
  onSendSampleMessageClick,
}) => {
  const styles = useStyles();

  const [activeTab, setActiveTab] = useState(0);

  const { currentCampaign } = useTypedSelector((state) => state.campaigns);
  const dispatch = useAppDispatch();
  const { isHealthCare } = useClientType();

  useEffect(() => {
    if (isOpen) {
      setActiveTab(0);
    }
  }, [isOpen]);

  useEffect(() => {
    if (currentCampaign) {
      dispatch(
        setEditCampaign({
          campaign: currentCampaign,
          isHealthCare,
        }),
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCampaign, dispatch]);

  const [isCampaignCreating, setIsCampaignCreating] = useState(false);

  const {
    smsRewardMessage,
    isNullReward,
    howToRewardCreators: { cardColor, rewardHeader, contactMethod, contact },
    SMSMethod,
    messageFrequency,
    isError,
  } = useTypedSelector((state) => state.createCampaign);

  const isCampaignEdited = () => {
    if (
      replaceMessageBodyWithApiVariables(smsRewardMessage, true) !==
      currentCampaign?.fulfillmentConfig?.sms.body
    ) {
      return true;
    }

    if (!isNullReward) {
      if (cardColor !== currentCampaign.cardStyle) return true;
      if (rewardHeader !== currentCampaign.rewardHeader) return true;
      if (contact !== currentCampaign.contact) return true;
      if (contactMethod !== currentCampaign.contactMethod) return true;
    }

    const isMultiFulfillment = messageFrequency === MessageFrequency.EVERY;
    if (isMultiFulfillment !== currentCampaign.isMultiFulfillment) return true;
    if (SMSMethod !== currentCampaign.fulfillmentConfig.method) return true;

    return false;
  };

  const canSaveChanges = isNullReward ? isCampaignEdited() : !isError && isCampaignEdited();

  const handleSave = async () => {
    setIsCampaignCreating(true);

    const fulfillmentConfig: CampaignFulfillmentConfig = {
      method: SMSMethod,
      sms: {
        mediaUrl: '',
        body: replaceMessageBodyWithApiVariables(smsRewardMessage, true),
      },
    };

    const rewardData = isNullReward
      ? {}
      : {
          rewardHeader,
          cardStyle: cardColor,
          contact,
          contactMethod,
        };

    const patchData: PatchIncentiveCampaignApiModel = {
      fulfillmentConfig,
      ...rewardData,
      isMultiFulfillment: messageFrequency === MessageFrequency.EVERY,
    };

    if (currentCampaign?.id) {
      await dispatch(
        patchIncentiveCampaign({ campaignId: currentCampaign?.id, values: patchData }),
      );
    }

    setIsCampaignCreating(false);
  };

  return (
    <Modal open={isOpen} onClose={onClose} sx={{ zIndex: 1200 }}>
      <Box className={styles.container}>
        <Box className={styles.closeIcon}>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Typography className={styles.title}>
          {currentCampaign?.isNullReward
            ? ElementsStrings.CustomizeText
            : ElementsStrings.CustomizeReward}
        </Typography>

        <Tabs
          value={activeTab}
          onChange={(_event, newValue) => {
            setActiveTab(newValue);
          }}
        >
          {(isNullReward
            ? ['Message Text', 'Delivery Options']
            : ['Reward Image', 'Message Text', 'Delivery Options']
          ).map((tabTitle, index) => {
            return <Tab value={index} label={tabTitle} sx={{ textTransform: 'none' }} />;
          })}
        </Tabs>
        <Box className={styles.contentContainer}>
          <Box className={styles.sectionsContainer}>
            {isNullReward ? (
              <>
                {activeTab === 0 && <MessageTextSection />}
                {activeTab === 1 && <DeliveryOptionsSection />}
              </>
            ) : (
              <>
                {activeTab === 0 && <RewardImageSection />}
                {activeTab === 1 && <MessageTextSection />}
                {activeTab === 2 && <DeliveryOptionsSection />}
              </>
            )}
          </Box>
          <Box className={styles.previewContainer}>
            <Box className={styles.iphonePreviewContainer}>
              <RewardImageSectionPreview />
            </Box>
            <Box>
              <Button
                fullWidth
                variant="outlined"
                disabled={isCampaignEdited()}
                onClick={() => {
                  onSendSampleMessageClick?.();
                }}
              >
                {ElementsStrings.SendSampleMessage}
              </Button>
            </Box>
          </Box>
        </Box>

        <Box className={styles.actionButtonsContainer}>
          <Button fullWidth variant="outlined" onClick={onClose}>
            {CommonStrings.Cancel}
          </Button>
          <Button
            fullWidth
            variant="contained"
            onClick={() => {
              handleSave();
            }}
            disabled={isCampaignCreating || !canSaveChanges}
          >
            {CommonStrings.ConfirmChanges}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
