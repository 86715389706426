import { Box, Button, CircularProgress, IconButton, Typography } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import React, { useState } from 'react';

import { GridIcon } from './icons/GridIcon';
import { KeywordIcon } from './icons/KeywordIcon';
import { ListIcon } from './icons/ListIcon';
import { HtmlTooltip } from '../../../../common/components/table/Tooltip';
import { ManageClipKeywordsPopup } from './ManageClipKeywordsPopup';
import { ArrowUpIcon } from './icons/ArrowUpIcon';
import { SelectorDropdown } from './SelectorDropdown';
import { useModal } from '../../../../hooks/useModal';
import { LoadingButton } from '@mui/lab';

const Separator = () => {
  return (
    <Box mx="4px" color="#667085" fontSize="14px" lineHeight="24px" fontWeight="400">
      |
    </Box>
  );
};

export interface SortingData {
  field: string;
  direction: 'asc' | 'desc';
}

interface TableToolbarProps {
  itemsShowing: number;
  itemsTotal: number;
  tableEntity?: 'clip' | 'creator';
  sorting?: SortingData;
  onSortingUpdate?: (newSorting: SortingData) => void;
  sortingItems?: { title: string; value: string }[];
  tableView?: 'list' | 'grid';
  onTableViewChange?: (view: 'list' | 'grid') => void;
  searchMode: boolean;
  onSearchResetClick?: () => void;
  searchExportEnabled: boolean;
  onSearchExportClick?: () => Promise<void>;
  onClearSearchClick?: () => void;
  hideKeywords?: boolean;
}

export const TableToolbar: React.FC<TableToolbarProps> = ({
  itemsShowing,
  itemsTotal,
  tableEntity = 'clip',
  sorting,
  onSortingUpdate,
  sortingItems = [
    {
      title: 'Name',
      value: 'userName',
    },
    {
      title: 'Date Created',
      value: 'createdAt',
    },
    {
      title: 'Length',
      value: 'duration',
    },
    {
      title: 'Clip Status',
      value: 'status',
    },
  ],
  tableView = 'list',
  onTableViewChange,
  searchMode,
  onSearchResetClick,
  searchExportEnabled,
  onSearchExportClick,
  onClearSearchClick,
  hideKeywords,
}) => {
  const {
    toggle: toggleKeywordsPopup,
    isOpen: isKeywordsPopupOpen,
    close: closeKeywordsPopup,
  } = useModal();

  const [isExporting, setIsExporting] = useState(false);

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center" minHeight="54px">
      {searchMode ? (
        <Box display="flex" alignItems="center">
          <IconButton onClick={onSearchResetClick} sx={{ borderRadius: '8px' }}>
            <RefreshIcon />
          </IconButton>
          <Separator />
          <Typography mx="12px">
            Search Results: {itemsTotal} {tableEntity}s
          </Typography>
          <Separator />
          <LoadingButton
            loading={isExporting}
            loadingIndicator={<CircularProgress size={20} thickness={5} />}
            onClick={async () => {
              if (!onSearchExportClick) return;

              setIsExporting(true);
              try {
                await onSearchExportClick();
              } catch (error) {
                console.error(error);
              } finally {
                setIsExporting(false);
              }
            }}
            sx={{ mx: '4px', borderRadius: '8px' }}
            disabled={!searchExportEnabled}
          >
            Export
          </LoadingButton>
          <Separator />
          <Button onClick={onClearSearchClick} sx={{ mx: '4px', borderRadius: '8px' }}>
            Clear
          </Button>
        </Box>
      ) : (
        <Typography>
          Showing {itemsShowing} of {itemsTotal} {tableEntity}s
        </Typography>
      )}

      <Box display="flex" justifyContent="center" alignItems="center">
        {tableView === 'grid' && sorting && (
          <>
            <Box display="flex" alignItems="center">
              <SelectorDropdown
                items={sortingItems}
                selectedValue={sorting.field}
                onChange={({ value }) => {
                  onSortingUpdate?.({ ...sorting, field: value });
                }}
              />
              <Box display="flex">
                <IconButton
                  sx={{ ml: '8px', height: '40px', width: '40px', borderRadius: '8px' }}
                  onClick={() => {
                    onSortingUpdate?.({
                      ...sorting,
                      direction: sorting.direction === 'desc' ? 'asc' : 'desc',
                    });
                  }}
                >
                  <Box
                    sx={{
                      transition: 'all 0.2s ease',
                      transform: sorting.direction === 'desc' ? 'rotateX(180deg)' : undefined,
                    }}
                  >
                    <ArrowUpIcon />
                  </Box>
                </IconButton>
              </Box>
            </Box>
            <Separator />
          </>
        )}

        {onTableViewChange && (
          <>
            {tableView === 'grid' ? (
              <HtmlTooltip rounded="" padding="" light={false} title="Switch to List">
                <IconButton onClick={() => onTableViewChange('list')} sx={{ borderRadius: '8px' }}>
                  <ListIcon />
                </IconButton>
              </HtmlTooltip>
            ) : (
              <HtmlTooltip rounded="" padding="" light={false} title="Switch to Grid">
                <IconButton onClick={() => onTableViewChange('grid')} sx={{ borderRadius: '8px' }}>
                  <GridIcon />
                </IconButton>
              </HtmlTooltip>
            )}

            <Separator />
          </>
        )}

        {!hideKeywords && (
          <Box>
            <ManageClipKeywordsPopup show={isKeywordsPopupOpen} onClose={closeKeywordsPopup} />
            <HtmlTooltip rounded="" padding="" light={false} title="Manage Keywords">
              <IconButton
                sx={{ borderRadius: '8px' }}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  toggleKeywordsPopup();
                }}
              >
                <KeywordIcon />
              </IconButton>
            </HtmlTooltip>
          </Box>
        )}
      </Box>
    </Box>
  );
};
