import React from 'react';
import { Box, Typography } from '@mui/material';
import { useStyles } from './RewardCardPreview.styles';
import { useTypedSelector } from '../../../store';
import { HowToRewardCreatorsStrings } from '../../localization/en';
import {
  ContactMethod,
  colorPalette,
} from '../../../pages/NewCampaign/HowToRewardCreators/HowToRewardCreators.helpers';
import { getRewardCardFontSize } from './RewardCardPreview.helpers';
import { format } from 'date-fns';
import backgroundImage from './reward_card_background.png';

interface RewardCardPreviewProps {
  cardColor?: string;
  contact: string | null;
  rewardTitle: string;
  rewardHeader: string;
  logo: string;
  contactMethod?: string;
  scaleFactor?: number;
}

const RewardCardPreview: React.FC<RewardCardPreviewProps> = ({
  rewardHeader,
  rewardTitle,
  cardColor,
  logo,
  contact,
  contactMethod,
  scaleFactor = 1,
}) => {
  const {
    venue: { name },
    venueAttributes: { properties: venueProperties },
  } = useTypedSelector((state) => state.venue);
  const venueBrandColor = venueProperties?.['webapp.config']?.['primary-color'];
  const defaultCardColor =
    colorPalette.flat().find((color) => color === venueBrandColor) || '#D1D1D1';

  const styles = useStyles({ cardColor: cardColor || defaultCardColor });

  const { name: clientName } = useTypedSelector((state) => state.me);

  return (
    <Box
      sx={{ width: `${100 * scaleFactor}%`, height: `${528 * scaleFactor}px`, overflow: 'hidden' }}
    >
      <Box sx={{ transform: `scale(${scaleFactor})`, transformOrigin: 'top left' }}>
        <img
          src={backgroundImage}
          alt=""
          style={{
            zIndex: 1,
            position: 'absolute',
            top: 0,
            left: 0,
            width: '288px',
            height: '528px',
          }}
        />
        <Box
          className={styles.container}
          sx={{
            background: cardColor || defaultCardColor,
          }}
        >
          <Typography
            className={styles.headerText}
            sx={{ fontSize: getRewardCardFontSize(rewardHeader) }}
          >
            {rewardHeader}
          </Typography>
          <Typography className={styles.customerName}>{clientName}</Typography>
          <Typography
            className={styles.rewardTitle}
            sx={{ fontSize: getRewardCardFontSize(rewardTitle) }}
          >
            {rewardTitle}
          </Typography>

          <Box className={styles.infoWrapper} sx={{ color: cardColor || defaultCardColor }}>
            {logo ? (
              <img src={logo} alt="company logo" className={styles.companyLogo} />
            ) : (
              <Typography
                className={styles.venueName}
                sx={{ fontSize: getRewardCardFontSize(rewardTitle) }}
              >
                {name}
              </Typography>
            )}

            <Typography className={styles.infoText}>
              {(contactMethod && contactMethod !== ContactMethod.None) || contact ? (
                <Box className={styles.contactWrapper}>
                  <Typography className={styles.contactHeader}>
                    {HowToRewardCreatorsStrings.RedeemText}
                  </Typography>
                  <Typography className={styles.contact}>{contact}</Typography>
                </Box>
              ) : (
                <div style={{ height: '48px' }}></div>
              )}
            </Typography>
            <Typography className={styles.infoText}>{format(new Date(), 'P')}</Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default RewardCardPreview;
