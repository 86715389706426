import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { httpClient } from '../../services/httpClient/httpClient';
import { getApiUrlForId, searchFilterEndpoints } from '../../api/endpoints';

import {
  VideoFilterApiModel,
  CreatorFilterApiModel,
  SearchTarget,
  SearchFilterApiModel,
  VideoFilterAttribute,
  CreatorFilterAttribute,
  VideoAttributeName,
  SearchOperationType,
  CreatorAttributeName,
} from '../../api/models/searchFilterModels';

interface SearchFilterInitialState {
  videoFilters: VideoFilterApiModel[];
  creatorFilters: CreatorFilterApiModel[];
  activeVideoFilterAttributes: VideoFilterAttribute[];
  activeCreatorFilterAttributes: CreatorFilterAttribute[];
  hasVideos: boolean | undefined;
  search: string;
  isLoading: Boolean;
  isError: Boolean;
  resetTags: number;
}

const initialState: SearchFilterInitialState = {
  videoFilters: [],
  creatorFilters: [],
  activeCreatorFilterAttributes: [],
  activeVideoFilterAttributes: [],
  search: '',
  isLoading: false,
  isError: false,
  hasVideos: undefined,
  resetTags: 0,
};

interface GetSearchFiltersRequest {
  accountId: string;
  target: SearchTarget;
}

export const getSearchFilters = createAsyncThunk(
  'searchFilters/getFilters',
  async (options: GetSearchFiltersRequest, { rejectWithValue }) => {
    try {
      return await httpClient.get<GetSearchFiltersRequest, SearchFilterApiModel[]>({
        url: searchFilterEndpoints.GetSearchFilters,
        requiresToken: true,
        params: options,
      });
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

interface IdRequestOption {
  id: string;
}

export const deleteSearchFilterById = createAsyncThunk(
  'searchFilters/deleteSearchFilterById',
  async (options: IdRequestOption, { rejectWithValue }) => {
    try {
      return await httpClient.delete<string, IdRequestOption>({
        url: getApiUrlForId(searchFilterEndpoints.DeleteSearchFilters, options.id),
        requiresToken: true,
      });
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

interface PostSearchFiltersRequest {
  accountId: string;
  name: string;
  target: SearchTarget;
  filter: {
    attributes: VideoFilterAttribute[] | CreatorFilterAttribute[];
  };
  searchText: string;
}

export const postSearchFilter = createAsyncThunk(
  'searchFilters/postSearchFilter',
  async (options: PostSearchFiltersRequest, { rejectWithValue }) => {
    try {
      return await httpClient.post<
        PostSearchFiltersRequest,
        VideoFilterApiModel | CreatorFilterApiModel
      >({
        url: searchFilterEndpoints.PostSearchFilters,
        requiresToken: true,
        payload: options,
      });
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

const searchFilterSlice = createSlice({
  name: 'searchFilters',
  initialState,
  reducers: {
    setActiveVideoFilterAttributes(state, action: PayloadAction<VideoFilterAttribute[]>) {
      state.activeVideoFilterAttributes = action.payload;
    },
    resetActiveVideoFilterAttributes(state) {
      state.activeVideoFilterAttributes = [];
      state.search = '';
    },
    setActiveCreatorFilterAttributes(state, action: PayloadAction<CreatorFilterAttribute[]>) {
      state.activeCreatorFilterAttributes = action.payload;
    },
    resetActiveCreatorFilterAttributes(state) {
      state.activeCreatorFilterAttributes = state.activeCreatorFilterAttributes.filter((item) => {
        return item.name === 'status';
      });
      state.search = '';
    },
    setFilterSearch(state, action: PayloadAction<string>) {
      state.search = action.payload;
    },
    updateVideosCampaignFilter(state, action: PayloadAction<string[]>) {
      const campaignFilterIdx = state.activeVideoFilterAttributes.findIndex(
        (item) => item.name === VideoAttributeName.CampaignId,
      );

      state.activeVideoFilterAttributes[campaignFilterIdx].value = action.payload;
    },
    removeVideosCampaignFilter(state) {
      state.activeVideoFilterAttributes = state.activeVideoFilterAttributes.filter(
        (filter) => filter.name !== VideoAttributeName.CampaignId,
      );
    },
    updateVideosStatusFilter(state, action: PayloadAction<string>) {
      const statusFilterIdx = state.activeVideoFilterAttributes.findIndex(
        (item) => item.name === VideoAttributeName.Status,
      );

      state.activeVideoFilterAttributes[statusFilterIdx].value = action.payload;
    },
    removeVideosStatusFilter(state) {
      state.activeVideoFilterAttributes = state.activeVideoFilterAttributes.filter(
        (filter) => filter.name !== VideoAttributeName.Status,
      );
    },
    updateVideosDateFilter(state, action: PayloadAction<(string | null)[]>) {
      const dateFilterFromIdx = state.activeVideoFilterAttributes.findIndex(
        (item) =>
          item.name === VideoAttributeName.CreatedAt &&
          item.operation === SearchOperationType.GreaterThan,
      );
      const dateFilterToIdx = state.activeVideoFilterAttributes.findIndex(
        (item) =>
          item.name === VideoAttributeName.CreatedAt &&
          item.operation === SearchOperationType.LessThan,
      );

      state.activeVideoFilterAttributes[dateFilterFromIdx].value = action.payload[0] as any;
      state.activeVideoFilterAttributes[dateFilterToIdx].value = action.payload[1] as any;
    },
    removeVideosDateFilter(state) {
      state.activeVideoFilterAttributes = state.activeVideoFilterAttributes.filter(
        (filter) => filter.name !== VideoAttributeName.CreatedAt,
      );
    },
    removeVideosLengthFilter(state) {
      state.activeVideoFilterAttributes = state.activeVideoFilterAttributes.filter(
        (filter) => filter.name !== VideoAttributeName.Length,
      );
    },
    setHasVideos(state, action: PayloadAction<boolean | undefined>) {
      state.hasVideos = action.payload;
    },
    updateCreatorVerificationFilter(state, { payload }: PayloadAction<boolean>) {
      const verificationFilterIdx = state.activeCreatorFilterAttributes.findIndex(
        (item) => item.name === CreatorAttributeName.PhoneNumberVerified,
      );

      state.activeCreatorFilterAttributes[verificationFilterIdx].value = payload;
    },
    removeCreatorVerificationFilter(state) {
      state.activeCreatorFilterAttributes = state.activeCreatorFilterAttributes.filter(
        (filter) => filter.name !== CreatorAttributeName.PhoneNumberVerified,
      );
    },
    removeCreatorOptedInFilter(state) {
      state.activeCreatorFilterAttributes = state.activeCreatorFilterAttributes.filter(
        (filter) => filter.name !== CreatorAttributeName.OptedIn,
      );
    },
    updateCreatorDateFilter(state, action: PayloadAction<(string | null)[]>) {
      const dateFilterFromIdx = state.activeCreatorFilterAttributes.findIndex(
        (item) =>
          item.name === CreatorAttributeName.LastVideoCreatedAt &&
          item.operation === SearchOperationType.GreaterThan,
      );
      const dateFilterToIdx = state.activeCreatorFilterAttributes.findIndex(
        (item) =>
          item.name === CreatorAttributeName.LastVideoCreatedAt &&
          item.operation === SearchOperationType.LessThan,
      );

      state.activeCreatorFilterAttributes[dateFilterFromIdx].value = action.payload[0] as any;
      state.activeCreatorFilterAttributes[dateFilterToIdx].value = action.payload[1] as any;
    },
    removeCreatorDateFilter(state) {
      state.activeCreatorFilterAttributes = state.activeCreatorFilterAttributes.filter(
        (filter) => filter.name !== CreatorAttributeName.LastVideoCreatedAt,
      );
    },
    removeCreatorClipsCountFilter(state) {
      state.activeCreatorFilterAttributes = state.activeCreatorFilterAttributes.filter(
        (filter) => filter.name !== CreatorAttributeName.ClipsCount,
      );
    },
    updateCreatorRewardedFilter(state, { payload }: PayloadAction<'Yes' | 'No'>) {
      const operation =
        payload === 'Yes' ? SearchOperationType.GreaterThan : SearchOperationType.LessThan;
      const value = payload === 'Yes' ? 0 : 1;

      const rewardedFilterIdx = state.activeCreatorFilterAttributes.findIndex(
        (item) => item.name === CreatorAttributeName.RewardsFulfilled,
      );

      state.activeCreatorFilterAttributes[rewardedFilterIdx].operation = operation;
      state.activeCreatorFilterAttributes[rewardedFilterIdx].value = value;
    },
    removeCreatorRewardedFilter(state) {
      state.activeCreatorFilterAttributes = state.activeCreatorFilterAttributes.filter(
        (filter) => filter.name !== CreatorAttributeName.RewardsFulfilled,
      );
    },
    resetTags(state) {
      state.resetTags = state.resetTags + 1;
    },
  },
  extraReducers: (reducersBuilder) => {
    reducersBuilder.addCase(getSearchFilters.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });
    reducersBuilder.addCase(getSearchFilters.pending, (state) => {
      state.isLoading = true;
    });
    reducersBuilder.addCase(getSearchFilters.fulfilled, (state, { payload }) => {
      state.isError = false;
      state.isLoading = false;
      const result = payload.filter(Boolean).sort((a, b) => {
        // @ts-ignore
        return new Date(b.createdAt) - new Date(a.createdAt);
      });

      state.videoFilters = result.filter(
        (item) => item.target === SearchTarget.VIDEOS,
      ) as VideoFilterApiModel[];

      state.creatorFilters = result.filter(
        (item) => item.target === SearchTarget.USERS,
      ) as CreatorFilterApiModel[];
    });

    reducersBuilder.addCase(postSearchFilter.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });
    reducersBuilder.addCase(postSearchFilter.pending, (state) => {
      state.isLoading = true;
    });
    reducersBuilder.addCase(postSearchFilter.fulfilled, (state) => {
      state.isError = false;
      state.isLoading = false;
    });

    reducersBuilder.addCase(deleteSearchFilterById.pending, (state, { payload }) => {
      state.isLoading = true;
    });
    reducersBuilder.addCase(deleteSearchFilterById.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });
    reducersBuilder.addCase(deleteSearchFilterById.fulfilled, (state, { payload }) => {
      state.creatorFilters = state.creatorFilters.filter((item) => item.id !== payload.id);
      state.videoFilters = state.videoFilters.filter((item) => item.id !== payload.id);
    });
  },
});

export const {
  setActiveVideoFilterAttributes,
  resetActiveVideoFilterAttributes,
  setFilterSearch,
  updateVideosCampaignFilter,
  removeVideosCampaignFilter,
  updateVideosStatusFilter,
  removeVideosStatusFilter,
  updateVideosDateFilter,
  removeVideosDateFilter,
  removeVideosLengthFilter,
  setActiveCreatorFilterAttributes,
  setHasVideos,
  resetActiveCreatorFilterAttributes,
  updateCreatorVerificationFilter,
  removeCreatorVerificationFilter,
  removeCreatorOptedInFilter,
  updateCreatorDateFilter,
  removeCreatorDateFilter,
  removeCreatorClipsCountFilter,
  updateCreatorRewardedFilter,
  removeCreatorRewardedFilter,
  resetTags,
} = searchFilterSlice.actions;
export default searchFilterSlice.reducer;
