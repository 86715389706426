import { FC, useEffect } from 'react';
import { Box } from '@mui/material';
import { useStyles } from './MainContent.styles';
import { Redirect, Route, Switch } from 'react-router-dom';
import { WebsiteRoutes } from '../../../../common/constants/routes';
import {
  getCampaignById,
  getCampaignCountData,
  getCampaignInvitations,
  getInvitesCounts,
  getLastSendBatchStats,
} from '../../../../store/slices/campaignsSlice';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useTypedSelector } from '../../../../store';
import { CampaignUsersTable } from '../../ExistingCampaignUsersTable/CampaignUsersTable';
import { Elements } from './Elements/Elements';
import { Clips } from './Clips/Clips';
import { InviteTables } from './InviteTables/InviteTables';
import { getApiUrlForId } from '../../../../api/endpoints';
import NoCreators from './Creators/NoCreators';
import { CampaignVisitorsTable } from '../../VIsitorsTable/VisitorsTable';
import NoVisitors from '../../VIsitorsTable/Tables/AllVisitors/NoVisitors';

export const MainContent: FC = () => {
  const dispatch = useAppDispatch();
  const classes = useStyles();

  const { id: campaignId } = useParams<{ id: string }>();
  const { countData } = useTypedSelector((state) => state.campaigns);

  useEffect(() => {
    if (campaignId) {
      dispatch(getCampaignById(campaignId));
      dispatch(getCampaignCountData({ campaignId }));
      dispatch(getCampaignInvitations({ campaignId }));
      dispatch(getInvitesCounts({ campaignId }));
      dispatch(getLastSendBatchStats({ campaignId }));
    }
  }, [campaignId, dispatch]);

  return (
    <Box className={classes.container}>
      <Switch>
        <Route path={WebsiteRoutes.CampaignDetailsEssentials}>
          <Elements />
        </Route>
        <Route path={WebsiteRoutes.CampaignDetailsInvites}>
          <InviteTables />
        </Route>
        <Route path={WebsiteRoutes.CampaignDetailsVisitors}>
          {countData.users > 0 ? <CampaignVisitorsTable /> : <NoVisitors />}
        </Route>
        <Route path={WebsiteRoutes.CampaignDetailsCreators}>
          {countData.activeUsers > 0 ? <CampaignUsersTable /> : <NoCreators />}
        </Route>
        <Route path={WebsiteRoutes.CampaignDetailsClips}>
          <Clips />
        </Route>
        <Route path={WebsiteRoutes.CampaignDetails}>
          <Redirect to={getApiUrlForId(WebsiteRoutes.CampaignDetailsEssentials, campaignId)} />
        </Route>
      </Switch>
    </Box>
  );
};
