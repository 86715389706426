import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '826px',
    display: 'flex',
  },
  leftSideWrapper: {
    width: '510px',
    paddingLeft: '20px',
  },
  rightSideWrapper: {
    width: '100%',
    paddingTop: '12px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    position: 'relative',
  },
  title: {
    fontFamily: 'Poppins',
    fontSize: '30px',
    fontWeight: 600,
    lineHeight: '72px',
    letterSpacing: '-0.6px',
    color: theme.palette.primary.main,
    width: '510px',
  },

  subtitle: {
    fontSize: '18px',
    fontFamily: 'Poppins',
    color: '#475467',
    lineHeight: '162%',
    width: '460px',
  },
  linkText: {
    fontSize: '18px',
    fontFamily: 'Poppins',
    color: theme.palette.primary.main,
    lineHeight: '162%',
    fontWeight: 600,
  },

  settingsText: {
    fontSize: '18px',
    fontFamily: 'Poppins',
    color: '#101828',
    lineHeight: '28px',
    fontWeight: 600,
    marginTop: '28px',
  },
  previewWrapper: {
    display: 'flex',
    marginTop: '14px',
  },
  imageWrapper: {
    width: '135px',
  },
  textPreviewWrapper: {
    width: '331px',
    paddingLeft: '25px',
  },
  previewText: {
    fontSize: '14px',
    fontFamily: 'Poppins',
    color: theme.palette.primary.main,
    lineHeight: '20px',
    fontWeight: 500,
    marginBottom: '10px',
  },
  inviteImage: {
    width: '135px',
    height: 'auto',
  },
  inviteMessage: {
    resize: 'none',
    width: '260px !important',
    height: '130px !important',
    scrollBehavior: 'auto',
    overflow: 'auto !important',
    '&::-webkit-scrollbar': {
      width: '6px',
      borderRadius: '10px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#D9D9D9',
      borderRadius: '6px',
    },
  },
  buttonsWrapper: {
    display: 'flex',
    width: 'fit-content',
    marginTop: '20px',
  },
  customizeButton: {
    fontFamily: 'Inter',
    width: '116px',
    height: '40px',
    padding: '0',
    borderRadius: '8px',
    marginRight: '12px',
    '&:hover': {
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      transform: 'scale(0.99)',
    },
    '&:disabled': {
      backgroundColor: theme.palette.primary.main,
    },

    '& p': {
      fontSize: '14px',
      fontWeight: 600,
    },
  },
  sendMessageButton: {
    fontFamily: 'Inter',
    width: '194px',
    height: '40px',
    padding: '0',
    borderRadius: '8px',
    border: '1px solid #D0D5DD',
    background: '#fff',
    '&:hover': {
      background: '#fff',
      border: '1px solid #D0D5DD',
    },
    '&:active': {
      transform: 'scale(0.99)',
    },
    '&:disabled': {
      backgroundColor: theme.palette.common.white,
    },

    '& p': {
      fontSize: '14px',
      fontWeight: 600,
      color: '#344054',
    },
  },
  howAppearText: {
    fontSize: '13px',
    fontFamily: 'Inter',
    color: '#475467',
    lineHeight: '18px',
    fontWeight: 400,
    textAlign: 'center',
    width: '230px',
  },
  phonePreviewWrapper: {
    transform: 'scale(0.5)',
    position: 'absolute',
    top: '-166px',
  },
  messageBoxWrapper: {
    pointerEvents: 'all',
    zIndex: 2,
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
  },
  inviteImagePhone: {
    width: '300px',
  },
}));
