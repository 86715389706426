import { useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { ClientTypes } from '../../api/models/common';
import { VenueProperties, WebAppSignUpConfig } from '../../api/models/venue';
import { RegisterVenueRequest, SVADMINRegisterVenueRequest } from '../../api/models/venueCreation';
import {
  SV_DEFAULT_INCENTIVE_IMAGE,
  SV_DEFAULT_WEBAPP_CARD,
  TRUBLU_DEFAULT_INCENTIVE_IMAGE,
  TRUBLU_DEFAULT_WEBAPP_CARD,
  UserRoles,
} from '../../common/constants/constants';
import {
  DEFAULT_BRAND_COLOR,
  DEFAULT_REWARD_SUBTITLE,
  DEFAULT_REWARD_SUBTITLE_TRUBLU,
  DEFAULT_REWARD_TITLE,
  DEFAULT_REWARD_TITLE_TRUBLU,
  DEFAULT_STORY_CTA_BUTTON,
} from '../../common/constants/defaults';
import { UnprotectedRoutes } from '../../common/constants/routes';
// import { useFTE } from '../../hooks/useFTE';
import { useAppDispatch, useTypedSelector } from '../../store';
// import { authRequest, getUserAccounts } from '../../store/slices/meSlice';
import { RegisterVenue, SVAdminRegisterVenue } from '../../store/slices/venueCreationSlice';
// import { setSelectedVenue } from '../../store/slices/venueSlice';
// import { CongratsModal } from './CongratsModal';
import { useStyles } from './signupSuccess.helper';
import { httpClient } from '../../services/httpClient/httpClient';
import { SocialVenuLogo } from '../../common/assets/newDesign/Sidebar/SocialVenuLogo';
import { Box } from '@mui/material';
import { isMobile } from 'react-device-detect';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export const QuickSignupSuccess = () => {
  const classes = useStyles();
  const query = useQuery();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { isLoading, created, error } = useTypedSelector(
    (state) => state.venueCreation.createdVenue,
  );
  const checkoutSessionId = query.get('session_id');
  const formData = localStorage.getItem('signupForm');
  const formValues = formData ? JSON.parse(formData) : {};
  const industry = localStorage.getItem('industry');
  const { role } = useTypedSelector((state) => state.me);
  const isSVAdmin = role === UserRoles.admin;

  const clientType = useRef((formValues?.clientType as ClientTypes) || ClientTypes.MARKETING)
    .current;
  const isTruBlu = clientType === ClientTypes.TRUBLU;
  const isHealthCare = isTruBlu || clientType === ClientTypes.SV_HEALTH;

  /*  const getLogoImageUrl = (bottomText?: string) => {
    if (!isTruBlu) return BASE_SV_VENUE_LOGO;
    if (!bottomText) return BASE_TRUBLU_VENUE_LOGO;

    const baseLogoUrl = BASE_TRUBLU_VENUE_LOGO;
    const cloudinaryLogoLink = googleStorageToCloudinary(baseLogoUrl);

    const fontSize = '128px';
    const textColor = 'white';
    const bottomTextOffsetPX = 80;

    const logoUrl = setPropertyToCloudinaryUrl(
      cloudinaryLogoLink,
      `l_text:Arial_${fontSize}_bold:${encodeURIComponent(bottomText)},g_south,y_-${
        2 * bottomTextOffsetPX
      },co_${textColor},bo_${bottomTextOffsetPX}px_solid_transparent`,
    );

    return logoUrl;
  };*/

  const logoUrl = '';

  const businessAddress = formValues
    ? `${formValues.address} ${formValues.city}, ${formValues.state} ${formValues.zip}`
    : '';

  const ctaBtnText = `Visit ${
    formValues?.practiceName ? formValues.practiceName : formValues?.companyName
  }`;

  const baseRegisterVenueRequestBody: Omit<RegisterVenueRequest, 'stripCheckoutSessionId'> = {
    businessAddress,
    name: (formValues?.practiceName as string) || (formValues?.companyName as string) || '',
    websiteUrl: (formValues?.companyWebsite as string) || '',
    referralCode: (formValues?.planCode as string) || 'BASIC',
    industry: industry || null,
    soldBy: (formValues?.soldBy as string) || undefined,
    clientType: (formValues?.clientType as ClientTypes) || ClientTypes.MARKETING,
    managerAccount: {
      email: (formValues?.email as string) || '',
      firstName: (formValues?.firstName as string) || '',
      lastName: (formValues?.lastName as string) || '',
      password: (formValues?.password as string) || '',
      phoneNumber: (formValues?.phone as string) || '',
    },
    logoUrl: logoUrl,
    properties: {
      onboarding_attributes: {
        email_system: formValues?.emailSystem,
        sms_system: formValues?.smsSystem,
        referrer: formValues?.aboutUs,
      },
      'webapp.config': {
        'logo-url': logoUrl,
        'primary-color': DEFAULT_BRAND_COLOR,
        'filter-logo-enabled': true,
      },
      'webapp.cards-welcome': {
        mobile: {
          original: isTruBlu ? TRUBLU_DEFAULT_WEBAPP_CARD : SV_DEFAULT_WEBAPP_CARD,
          cropped: isTruBlu ? TRUBLU_DEFAULT_WEBAPP_CARD : SV_DEFAULT_WEBAPP_CARD,
        },
        tablet: {
          original: isTruBlu ? TRUBLU_DEFAULT_WEBAPP_CARD : SV_DEFAULT_WEBAPP_CARD,
          cropped: isTruBlu ? TRUBLU_DEFAULT_WEBAPP_CARD : SV_DEFAULT_WEBAPP_CARD,
        },
        desktop: {
          original: isTruBlu ? TRUBLU_DEFAULT_WEBAPP_CARD : SV_DEFAULT_WEBAPP_CARD,
          cropped: isTruBlu ? TRUBLU_DEFAULT_WEBAPP_CARD : SV_DEFAULT_WEBAPP_CARD,
        },
        title: isHealthCare ? DEFAULT_REWARD_TITLE_TRUBLU : DEFAULT_REWARD_TITLE,
        subtitle: isHealthCare ? DEFAULT_REWARD_SUBTITLE_TRUBLU : DEFAULT_REWARD_SUBTITLE,
      },
      'webapp.incentive.config': {
        title: isHealthCare ? DEFAULT_REWARD_TITLE_TRUBLU : DEFAULT_REWARD_TITLE,
        action: isHealthCare ? DEFAULT_REWARD_SUBTITLE_TRUBLU : DEFAULT_REWARD_SUBTITLE,
        imageUrl: isTruBlu ? TRUBLU_DEFAULT_INCENTIVE_IMAGE : SV_DEFAULT_INCENTIVE_IMAGE,
        points: 100,
        activities: [
          { activityType: 'CREATE_VIDEO', points: 25, status: true },
          { activityType: 'SHARE', points: 10, status: true },
          { activityType: 'CALL_TO_ACTION', points: 50, status: true },
          { activityType: 'BONUS_POINTS', points: 100, status: true },
        ],
      },
      'webapp.default-story-details': {
        ctaBtnText: ctaBtnText.length > 30 ? DEFAULT_STORY_CTA_BUTTON : ctaBtnText,
        ctaBtnLinkUrl: (formValues?.companyWebsite as string) || '',
      },
      'webapp.signUp.config': {
        phoneRequired: true,
        nameRequired: true,
        emailRequired: !isTruBlu,
      } as WebAppSignUpConfig,
      'dashboard.config': {
        dashboardLogo: logoUrl,
      },
      'dashboard.QRCode.config': {
        logo: logoUrl,
        brandColor: DEFAULT_BRAND_COLOR,
      },
      legal_name: (formValues?.companyName as string) || '',
      'company-address': {
        address: formValues?.address,
        city: formValues?.city,
        state: formValues?.state,
        zip: formValues?.zip,
      } as VenueProperties['company-address'],
      'quickstart.config': {
        currentRoute: '/quickstart/welcome',
        isQuickStartInProgress: true,
      },
    },
  };

  const registerVenueRequestBody: RegisterVenueRequest = {
    ...baseRegisterVenueRequestBody,
    stripCheckoutSessionId: checkoutSessionId || '',
    quickFormRegistration: true,
  };

  const svAdminregisterVenueRequestBody: SVADMINRegisterVenueRequest = {
    ...baseRegisterVenueRequestBody,
    quickFormRegistration: true,
  };

  //https://storage.googleapis.com/sv-dev-assets/14eef41f-8001-4c43-9a02-838dfecdf837/7b92f74f-2ff5-47ab-844a-838ff8550b12.jpg

  useEffect(() => {
    if (checkoutSessionId && !isLoading && !created) {
      if (!httpClient.isLoggedIn()) {
        dispatch(RegisterVenue(registerVenueRequestBody)).then(() => {
          localStorage.setItem('signupForm', '');
        });
      } else {
        dispatch(SVAdminRegisterVenue(registerVenueRequestBody)).then(() => {
          localStorage.setItem('signupForm', '');
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkoutSessionId, dispatch]);

  // TODO: Both useEffects will trigger for enterprise plan, race condition fix this.
  useEffect(() => {
    if (!checkoutSessionId && isSVAdmin && !isLoading && !created) {
      dispatch(SVAdminRegisterVenue(svAdminregisterVenueRequestBody)).then(() => {
        localStorage.setItem('signupForm', '');
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSVAdmin, dispatch, checkoutSessionId]);

  useEffect(() => {
    if (!isSVAdmin && !checkoutSessionId && !created) {
      history.push(UnprotectedRoutes.SignUp);
    }
  }, [checkoutSessionId, history, isSVAdmin, created]);

  useEffect(() => {
    if (error) {
      console.error(error);
    }
  }, [error]);

  //   if (!isSVAdmin && !checkoutSessionId) {
  //     return null;
  //   }

  /*  const handleContactUsClick = () => {
    const a = document.createElement('a');
    a.href = `mailto:${ContactUsString.SupportEmail1}?cc=${ContactUsString.SupportEmail2}&subject=${ContactUsString.ErrorSignUp}`;
    a.click();
  };*/

  return (
    <>
      <div className={classes.QuicksignUpWrapper}>
        <Box
          style={
            isMobile
              ? {
                  borderRadius: '15px',
                  background: 'white',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  padding: '112px 40px 82px 40px',
                  margin: '40px',
                  width: '408px',
                  marginTop: '-500px',
                  scale: '1.4',
                }
              : {
                  borderRadius: '15px',
                  background: 'white',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  padding: '62px 40px 82px 40px',
                  margin: '40px',
                  width: '598px',
                  marginTop: '-300px',
                }
          }
        >
          <div style={{ marginBottom: '20px' }}>
            <SocialVenuLogo />
          </div>
          <p className={classes.boldparagraph}>Welcome to SocialVenu!</p>
          <p className={classes.paragraph}>Your payment is complete.</p>
          <p className={classes.boldparagraph}>
            We'll contact you by the next business day to follow-up
          </p>
          <strong></strong>
          <p className={classes.paragraph}>
            Please check your email for instructions on completing your account profile.
          </p>
        </Box>
      </div>
    </>
  );
};
