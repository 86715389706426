import { Box, CircularProgress, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { CampaignInvitation } from '../../../../../../../../api/models/campaigns';
import { FSModal } from '../../../../../../../../common/components/modal/Modal';
import { useTypedSelector } from '../../../../../../../../store';
import { getUnsentInvitationsAPICall } from '../../../../../../../../store/slices/campaignsSlice';
import { useSendInvitesTab } from '../../useSendInvitesTab';
import { useReviewInvitesModalStyles } from './ReviewInvitesModal.styles';
import { ReviewInvitesTable } from './ReviewInvitesTable';

interface ReviewInvitesModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const ReviewInvitesModal: React.FC<ReviewInvitesModalProps> = ({ isOpen, onClose }) => {
  const [preparedInvites, setPreparedInvited] = useState<CampaignInvitation[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const classes = useReviewInvitesModalStyles();

  const { currentCampaign } = useTypedSelector((state) => state.campaigns);
  const campaignId = currentCampaign?.id;

  const data = useSendInvitesTab();

  const fetchPreparedInvites = async () => {
    if (!campaignId) return;
    setIsLoading(true);

    try {
      const res = await getUnsentInvitationsAPICall({
        campaignId,
        pageable: { page: 0, size: data.preparedInvites, sort: ['createdAt', 'asc'] },
      });

      setPreparedInvited(res.items);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isLoading) return;

    fetchPreparedInvites();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignId]);

  return (
    <FSModal
      onClose={onClose}
      modalIsOpen={isOpen}
      width="720px"
      rounded="24px"
      closeBtn
      padding="14px"
    >
      <Box className={classes.container}>
        <Typography className={classes.title}>Review Customers</Typography>

        {isLoading ? <CircularProgress /> : <ReviewInvitesTable invites={preparedInvites} />}
      </Box>
    </FSModal>
  );
};
