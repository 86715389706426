import { Box, Button, CircularProgress, Divider, Typography } from '@mui/material';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useStyles } from './Distributors.style';
import { DistributorsStrings, QuickStartStrings } from '../../localization/en';
import { useAppDispatch, useTypedSelector } from '../../../store';
import {
  GbpLocation,
  clearGbpAuthUrl,
  disconnectAccount,
  getGbpAuthorizeUrl,
} from '../../../store/slices/gbpSlice';
import { useQuery } from '../../../hooks/useQuery';
import { useHistory, useLocation } from 'react-router-dom';
import { useVenueProperty } from '../../../hooks/useVenueProperty';
import { VenueProperties } from '../../../api/models/venue';
import { SyncedBusiness } from './SyncedBusiness/SyncedBusiness';
import { getAllSocialVerse, putEditSocialVerse } from '../../../store/slices/socialVerseSlice';
import { useModal } from '../../../hooks/useModal';
import { ConfirmSyncModal } from '../ConfirmSyncModal/ConfirmSyncModal';
import { ConsentModal } from './ConsentModal/ConsentModal';
import { GoogleIcon } from '../../assets/GoogleIcon';
import { setSelectedVenue } from '../../../store/slices/venueSlice';
import { SvToggleItem } from '../ConfirmSyncModal/SelectItem/SvToggle';
import { ErrorCircleOutlined } from '../../assets/newDesign/ErrorCircleOutlined';
import { useGbpSync } from '../../../services/hooks/useGbpSync';
import { getCampaigns } from '../../../store/slices/campaignsSlice';
import { useClientType } from '../../../services/hooks/useClientType';
import UWMConfirmSyncModal from '../UWMConfirmSyncModal/UWMConfirmSyncModal';
import LocationSelect from './LocationSelect/LocationSelect';
import {
  MAX_STEP_INDEX,
  useQuickStartLaunchPad,
} from '../../../pages/QuickStart/QuickStartLaunchPadModal/useQuickStartLaunchPad';
import {
  GOOGLE_BUSINESS_PROFIE_OWNERSHIP_URL,
  GOOGLE_BUSINESS_PROFIE_VERIFY_URL,
} from '../../constants/constants';
import AnimatedQsMarker, { QSStep } from '../AnimatedQsMarker/AnimatedQsMarker';

export const Distributors: FC = () => {
  const classes = useStyles();
  const { items: campaigns } = useTypedSelector((state) => state.campaigns);

  const { value: companyAddress } = useVenueProperty<VenueProperties['company-address']>({
    property: 'company-address',
  });

  const history = useHistory();
  const query = useQuery();
  const location = useLocation();
  const state = query.get('state');
  const code = query.get('code');
  const { isUwmClient } = useClientType();

  const dispatch = useAppDispatch();
  const { id: venueId } = useTypedSelector((state) => state.venue.venue);
  const { authUrl, isLoading, isConfirmLocationError, connectedAccount } = useTypedSelector(
    (state) => state.gpbSlice,
  );

  useEffect(() => {
    if (state && state !== venueId) {
      const accountId = JSON.parse(state).account;
      dispatch(setSelectedVenue({ id: accountId }));
    }
  }, [state, venueId, dispatch]);

  useEffect(() => {
    if (venueId) {
      dispatch(getCampaigns({ accountId: venueId, pageable: { page: 0 } }));
    }
  }, [dispatch, venueId]);

  const { socialVerseAll } = useTypedSelector((state) => state.SocialVerseSlice);

  const liveSocialVerses = useMemo(() => {
    return socialVerseAll?.filter((sv) => sv.lastSeenLocationUrl && sv.numVideos > 0) || [];
  }, [socialVerseAll]);

  const [svToggleItems, setSvToggleItems] = useState<SvToggleItem[]>([]);

  useEffect(() => {
    setSvToggleItems(
      liveSocialVerses.map((socialVerse) => ({
        socialVerse,
        isEnabled: true,
      })),
    );
  }, [liveSocialVerses]);

  const selectedLiveSocialVerses = useMemo(() => {
    return svToggleItems.filter((item) => item.isEnabled).map((item) => item.socialVerse);
  }, [svToggleItems]);

  const [isLocationSelect, setIsLocationSelect] = useState(false);
  const [isSignInError, setIsSignInError] = useState(false);
  const [isGoogleBtnActive, setIsGoogleBtnActive] = useState(false);
  const [uploadedVideosCount, setUploadedVideosCount] = useState(0);
  const {
    isOpen: isConfirmSyncModalOpen,
    open: openConfirmSyncModal,
    close: closeConfirmSyncModal,
  } = useModal();

  const {
    isQSMarkerVisible,
    hideQSMarker,
    isStarted: isQsStarted,
    currentTopicStep,
    moveToNextItem,
    isFinished,
    currentTargetIndex,
    isLaunchpadVisible,
  } = useQuickStartLaunchPad();

  const {
    isOpen: isConsentModalOpen,
    open: openConsentModal,
    close: closeConsentModal,
  } = useModal();

  const handleCloseLocationSelect = () => {
    setIsLocationSelect(false);
  };

  const handleSignIn = () => {
    localStorage.setItem('isGbpSyncStarted', String(true));
    dispatch(getGbpAuthorizeUrl({ accountId: venueId }));
  };

  useEffect(() => {
    if (venueId) {
      dispatch(getAllSocialVerse({ accountId: venueId, withVideos: true }));
    }
  }, [dispatch, venueId]);

  const signInGbp = useGbpSync(
    (isError) => {
      setIsSignInError(isError);
    },
    openConsentModal,
    () => {
      setIsLocationSelect(true);
    },
  );

  useEffect(() => {
    if (
      connectedAccount &&
      localStorage.getItem('isGbpSyncStarted') === String(true) &&
      liveSocialVerses.length > 0
    ) {
      const svIdsString = localStorage.getItem('selectedSvIds');
      const svIds = svIdsString ? JSON.parse(svIdsString) : [];
      const socialVesesToSync = liveSocialVerses.filter((sv) => svIds.includes(sv.id));

      const videosToSyncCount = socialVesesToSync.reduce((acc, curr) => {
        return acc + curr.numVideos;
      }, 0);

      setUploadedVideosCount(videosToSyncCount);

      socialVesesToSync.forEach((socialverse) => {
        dispatch(
          putEditSocialVerse({
            id: socialverse.id,
            data: {
              ...socialverse,
              gbpSyncEnabled: true,
            },
          }),
        );
      });
      localStorage.setItem('isGbpSyncStarted', String(false));
    }
  }, [connectedAccount, liveSocialVerses, dispatch]);

  const clearQueryParams = useCallback(() => {
    query.delete('code');
    query.delete('scope');
    query.delete('state');
    history.replace({
      search: query.toString(),
    });
  }, [history, query]);

  useEffect(() => {
    if (state && code && venueId && companyAddress) {
      signInGbp(state, code, isUwmClient);
      clearQueryParams();
    }
  }, [
    state,
    code,
    dispatch,
    signInGbp,
    clearQueryParams,
    venueId,
    companyAddress,
    campaigns,
    history,
    location.search,
    isUwmClient,
  ]);

  const handleSelectGbpLocation = (location: GbpLocation) => {
    signInGbp(state, code, isUwmClient, location);
  };

  useEffect(() => {
    if (!isQsStarted || isFinished || currentTargetIndex === MAX_STEP_INDEX || isLaunchpadVisible)
      return;

    const elementsToBlock: NodeListOf<
      HTMLAnchorElement | HTMLButtonElement | HTMLDivElement
    > = document.querySelectorAll('button, a, [data-qs-button="false"], div[role="button"]');

    elementsToBlock.forEach((element) => {
      if (element.dataset.qsButton === 'true') return;

      element.onmouseover = () => {
        element.style.cursor = 'not-allowed';
      };

      if (element instanceof HTMLAnchorElement) {
        element.onclick = (event) => {
          event.preventDefault();
        };
        return;
      }

      if (element instanceof HTMLButtonElement) {
        element.disabled = true;
        return;
      }
    });
  }, [isQsStarted, currentTopicStep, isFinished, currentTargetIndex, isLaunchpadVisible]);

  const qsSteps: QSStep[] = [
    {
      title: QuickStartStrings.GoogleTitle,
      text: QuickStartStrings.GoogleText,
      onClick: moveToNextItem,
      width: '200px',
      hideMarkerOnClick: true,
      isLastStep: true,
    },
  ];

  return (
    <Box className={classes.container}>
      {isUwmClient && (
        <UWMConfirmSyncModal
          isOpen={isConfirmSyncModalOpen}
          onClose={closeConfirmSyncModal}
          onConfirm={() => {
            dispatch(clearGbpAuthUrl());
          }}
          url={authUrl}
        />
      )}
      {authUrl && !isUwmClient && (
        <ConfirmSyncModal
          isOpen={isConfirmSyncModalOpen}
          onClose={closeConfirmSyncModal}
          onConfirm={() => {
            closeConfirmSyncModal();
            dispatch(clearGbpAuthUrl());
            localStorage.setItem(
              'selectedSvIds',
              JSON.stringify(selectedLiveSocialVerses.map((sv) => sv.id)),
            );
          }}
          url={authUrl}
          svToggleItems={svToggleItems}
          setSvToggleItems={setSvToggleItems}
        />
      )}
      <ConsentModal
        isOpen={isConsentModalOpen}
        videosUploadedCount={uploadedVideosCount}
        onClose={() => {
          closeConsentModal();
        }}
      />

      <Box className={classes.topWrapper}>
        <Typography className={classes.description}>{DistributorsStrings.Descr}</Typography>
      </Box>
      <Divider className={classes.divider} />
      <Box className={classes.bottomWrapper}>
        <Box className={classes.googleProfileWrapper}>
          <img src="/GoogleProfile.png" className={classes.googleProfileIcon} alt="google icon" />
          <Box ml="10px">
            <Typography className={classes.googleProfileTitle}>
              {DistributorsStrings.GoogleProfileTitle}
            </Typography>
            <Typography className={classes.googleProfileDescr}>
              {DistributorsStrings.GoogleProfileDescr}
            </Typography>
          </Box>
        </Box>
        {!connectedAccount && (
          <Box
            className={`${classes.signInWrapper} ${
              (isSignInError || isConfirmLocationError) && classes.errorWrapper
            }`}
          >
            {isLocationSelect ? (
              <LocationSelect
                onCancel={handleCloseLocationSelect}
                onSyncProfile={handleSelectGbpLocation}
              />
            ) : (
              <>
                {isSignInError && (
                  <Box>
                    <Typography className={classes.errorTitle}>
                      <ErrorCircleOutlined />
                      {DistributorsStrings.ErrorTitle}
                    </Typography>
                    <Typography className={classes.ensureText}>
                      {DistributorsStrings.EnsureText}
                    </Typography>
                    <ul className={classes.list}>
                      <li className={classes.listItem}>
                        {DistributorsStrings.GoogleAccountIsLinked}{' '}
                        <a
                          href={GOOGLE_BUSINESS_PROFIE_OWNERSHIP_URL}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <i>{DistributorsStrings.Claimed}</i>{' '}
                          {DistributorsStrings.GoogleBusinessProfile}
                        </a>
                      </li>
                      <li className={classes.listItem}>
                        {DistributorsStrings.MatchesGoogleProfile}{' '}
                        <a
                          href={GOOGLE_BUSINESS_PROFIE_VERIFY_URL}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <i>{DistributorsStrings.Verified}</i> {DistributorsStrings.WithGoogle}
                        </a>
                      </li>
                    </ul>
                  </Box>
                )}
                {isConfirmLocationError && (
                  <Box>
                    <Typography className={classes.errorTitle}>
                      <ErrorCircleOutlined />
                      {DistributorsStrings.ErrorTitleConnected}
                    </Typography>
                    <Typography className={classes.ensureText}>
                      {DistributorsStrings.TryText}
                    </Typography>
                    <ul className={classes.list}>
                      <li className={classes.listItem}>{DistributorsStrings.DisconnectYour}</li>
                      <li className={classes.listItem}>{DistributorsStrings.ConnectTo}</li>
                    </ul>
                  </Box>
                )}
                <Box
                  className={classes.googleBtnWrapper}
                  sx={{
                    background: isGoogleBtnActive ? 'white !important' : '',
                  }}
                >
                  <Box className={classes.qsWrapper}>
                    <Button
                      className={classes.signInButton}
                      onClick={() => {
                        openConfirmSyncModal();
                        handleSignIn();
                        if (isQSMarkerVisible) hideQSMarker();
                      }}
                      onMouseDown={() => {
                        setIsGoogleBtnActive(true);
                      }}
                      onMouseLeave={() => {
                        setIsGoogleBtnActive(false);
                      }}
                      onMouseUp={() => {
                        setIsGoogleBtnActive(false);
                      }}
                      startIcon={
                        isLoading ? (
                          <CircularProgress className={classes.progress} size={22} />
                        ) : (
                          <GoogleIcon className={classes.googleIcon} />
                        )
                      }
                    >
                      {DistributorsStrings.Connect}
                    </Button>
                    {isQSMarkerVisible && (
                      <Box className={classes.qsMarker}>
                        <AnimatedQsMarker steps={qsSteps} arrowPosition="left" direction="column" />
                      </Box>
                    )}
                  </Box>
                </Box>
                {(isSignInError || isConfirmLocationError) && (
                  <Box className={classes.contactWrapper}>
                    <Typography display="inline" className={classes.contactText} color="#646E8A">
                      {DistributorsStrings.ContactStart}
                    </Typography>
                    <a
                      href="mailto:support@socialvenu.com"
                      className={classes.contactText}
                      color="#1769FF"
                      style={{
                        textDecoration: 'underline',
                      }}
                    >
                      {DistributorsStrings.CustomerCare}
                    </a>
                    <Typography display="inline" className={classes.contactText} color="#646E8A">
                      {DistributorsStrings.ContactEnd}
                    </Typography>
                  </Box>
                )}
              </>
            )}
          </Box>
        )}
        {connectedAccount && (
          <Box className={classes.syncedBusinessWrapper}>
            <SyncedBusiness
              connectedAccount={connectedAccount}
              onDisconnect={() => {
                dispatch(
                  disconnectAccount({
                    accountId: venueId,
                  }),
                );
              }}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};
