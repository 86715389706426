import { LoadingButton } from '@mui/lab';
import { Box, Modal, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { FC, useCallback, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { CustomTextField } from '../../../../common/components/customTextField/CustomTextField';
import { Toggle } from '../../../../common/components/toggle/CustomToggle';
import { CommonEventNames } from '../../../../common/constants/events/common';
import { ErrorsStrings, SmsImportUserStrings } from '../../../../common/localization/en';
import { useTrackEvent } from '../../../../hooks/useTrackEvent';
import { useClientType } from '../../../../services/hooks/useClientType';
import { handleChangeWithPattern } from '../../../../services/utilities';
import { useAppDispatch, useTypedSelector } from '../../../../store';
import {
  addUsersToCampaign,
  createInvitation,
  getCampaignCountData,
  setLatestAddedCampaignUserId,
} from '../../../../store/slices/campaignsSlice';
import { validationSchema } from '../../../integrations/SmsInvite/Sections/ImportUsers/AddUsersManually/AddUsersManually.helpers';
import { useStyles } from './AddIndividualCustomerModal.styles';
import { SMSInvitationType } from '../../../../common/components/SMSInvitationTypeSelector/SMSInvitationTypeSelector';
import { createUser, getHeroes } from '../../../../store/slices/heroesSlice';
import { Close, ReportProblemRounded } from '@mui/icons-material';
// import { CampaignInvitation } from '../../../../api/models/campaigns';
import ExistingNumberModal from './ExistingNumberModal';
import { useModal } from '../../../../hooks/useModal';
import UploadVIdeoSelector from '../../../../common/components/UploadVideoSelector/UploadVideoSelector';
import { UploadVideoSelectOption } from '../CampaignSummary.helper';

interface AddIndividualCustomerModalProps {
  isOpen: boolean;
  onClose: () => void;
  campaignId: string;
  withInvite?: boolean;
  onUploadVideoOptionChange?: (value: UploadVideoSelectOption) => void;
  uploadVideoOption?: string;
  onCustomerAdded?: (value: string) => void;
}

export const AddIndividualCustomerModal: FC<AddIndividualCustomerModalProps> = ({
  isOpen,
  onClose,
  campaignId,
  withInvite = true,
  onUploadVideoOptionChange,
  uploadVideoOption,
  onCustomerAdded,
}) => {
  const classes = useStyles();
  const { isHealthCare } = useClientType();
  const { trackEvent } = useTrackEvent();
  const dispatch = useAppDispatch();
  const { addToast } = useToasts();
  const { page, size, sort, search = '' } = useTypedSelector((state) => state.heroes);

  const { id: venueId } = useTypedSelector((state) => state.venue.venue);

  const [isLoading, setIsLoading] = useState(false);
  const [isOptInTouched, setIsOptInTouched] = useState(false);

  const {
    isOpen: isExistingNumberOpen,
    close: closeExistingNumber,
    open: openExistingNumber,
  } = useModal();

  const initialValues = {
    firstName: '',
    lastName: '',
    phoneNumber: '',
    optedIn: false,
    optIn: '',
    attributes: {
      additionalProp1: '',
      additionalProp2: '',
      additionalProp3: '',
    },
    refId: '',
    email: '',
    transactionDate: '',
    derivedTransactionDate: '',
    smsInvitationType: SMSInvitationType.SEND_IMMEDIATELY,
  };

  const {
    values,
    handleChange,
    errors,
    isValid,
    touched,
    handleBlur,
    submitForm,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues,
    validationSchema,
    validateOnBlur: true,
    validateOnChange: true,
    validateOnMount: true,
    onSubmit: () => {
      setIsLoading(true);

      handleImportSubmit(values, values.smsInvitationType === SMSInvitationType.SEND_IMMEDIATELY);
    },
  });

  const handlePhoneNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleChangeWithPattern({
      event,
      prevVal: values.phoneNumber,
      pattern: '(###) ###-####',
      setVal: (val: string) => {
        setFieldValue('phoneNumber', val);
      },
    });
  };

  const getFieldError = (field: keyof typeof initialValues) => {
    return touched[field] ? (errors[field] as string) : undefined;
  };

  const handleImportSubmit = useCallback(
    async (
      record: { firstName: string; lastName: string; phoneNumber: string },
      autoApprove: boolean,
    ) => {
      if (withInvite) {
        const invitationResponse = await dispatch(
          createInvitation({
            campaignId,
            ...record,
            transactionDate: new Date().toISOString(),
          }),
        );

        if ((invitationResponse as any).error) {
          openExistingNumber();
          setIsLoading(false);
          return;
        }

        if (autoApprove) {
          // const invitation = invitationResponse.payload as CampaignInvitation;

          // await dispatch(sendInvitations({ campaignId, invitationIds: [invitation.id] }));
          addToast(
            `${record.firstName} ${record.lastName} ${SmsImportUserStrings.HasBeenInvited}`,
            {
              appearance: 'success',
              autoDismiss: true,
            },
          );
        } else {
          addToast(
            `${record.firstName} ${record.lastName} ${SmsImportUserStrings.SuccessSaveForLater}`,
            {
              appearance: 'success',
              autoDismiss: true,
            },
          );
        }

        onCustomerAdded?.(`${record.firstName} ${record.lastName}`);
        setIsLoading(false);
      } else {
        try {
          const user = await createUser({
            venueId,
            email: undefined,
            firstName: record.firstName,
            lastName: record.lastName,
            phoneNumber: record.phoneNumber || '',
            accountId: venueId,
          });

          await dispatch(addUsersToCampaign({ campaignId, payload: { userIds: [user.id] } }));
          dispatch(setLatestAddedCampaignUserId(user.id));
          dispatch(
            getHeroes({
              search,
              accountId: venueId,
              // status: `${UserStatuses.active}`,
              pageable: { page, size, sort },
              hasVideos: false,
            }),
          );

          addToast(`${record.firstName} ${record.lastName} ${SmsImportUserStrings.HasBeenAdded}`, {
            appearance: 'success',
            autoDismiss: true,
          });
        } catch (err) {
          if ((err as Error).message === 'User with the given phone number already exists') {
            openExistingNumber();
            setIsLoading(false);
            return;
          }
        }

        setIsLoading(false);
      }

      onCustomerAdded?.(`${record.firstName} ${record.lastName}`);
      onClose();
      resetForm();
      setIsOptInTouched(false);
      dispatch(getCampaignCountData({ campaignId }));
    },
    [
      withInvite,
      onClose,
      resetForm,
      dispatch,
      campaignId,
      openExistingNumber,
      addToast,
      venueId,
      search,
      page,
      size,
      sort,
      onCustomerAdded,
    ],
  );

  const getSubmitButtonTitle = () => {
    if (withInvite) {
      return isHealthCare
        ? SmsImportUserStrings.InvitePatient
        : SmsImportUserStrings.InviteCustomer;
    }

    return isHealthCare ? SmsImportUserStrings.AddPatient : SmsImportUserStrings.AddCustomer;
  };

  return (
    <>
      <ExistingNumberModal isOpen={isExistingNumberOpen} onClose={closeExistingNumber} />
      <Modal
        open={isOpen}
        onClose={() => {
          resetForm();
          onClose();
        }}
        style={{
          background: 'rgba(26, 21, 56, 0.5)',
          backdropFilter: 'blur(2.5px)',
          width: '100%',
        }}
        sx={{
          '& .MuiBackdrop-root': {
            backgroundColor: 'transparent !important',
          },
        }}
      >
        <Box className={classes.inputsWrapper}>
          <button
            onClick={() => {
              resetForm();
              onClose();
            }}
            className={classes.closeIcon}
          >
            <Close />
          </button>
          <Typography className={classes.title}>
            {isHealthCare
              ? SmsImportUserStrings.PatientInformation
              : SmsImportUserStrings.CreatorInformation}
          </Typography>
          <Box className={classes.fieldsWrapper}>
            <Box className={classes.fieldWrapper}>
              <Box className={classes.inputWrapper}>
                <CustomTextField
                  label={SmsImportUserStrings.FirstName}
                  value={values.firstName}
                  name="firstName"
                  handleChange={(e) => {
                    handleChange(e);
                    trackEvent(CommonEventNames.add_users_manually_first_name_input_typed, {
                      value: e.target.value,
                    });
                  }}
                  error={getFieldError('firstName')}
                  labelTextClassName={classes.inputLabel}
                  inputClassName={classes.input}
                  placeholder={SmsImportUserStrings.FirstNamePlaceholder}
                  onBlur={handleBlur}
                />
              </Box>
              <Box className={classes.inputWrapper}>
                <CustomTextField
                  label={SmsImportUserStrings.LastName}
                  value={values.lastName}
                  name="lastName"
                  handleChange={(e) => {
                    handleChange(e);
                    trackEvent(CommonEventNames.add_users_manually_last_name_input_typed, {
                      value: e.target.value,
                    });
                  }}
                  error={getFieldError('lastName')}
                  labelTextClassName={classes.inputLabel}
                  inputClassName={classes.input}
                  placeholder={SmsImportUserStrings.LastNamePlaceholder}
                  onBlur={handleBlur}
                />
              </Box>
            </Box>
            <Box className={classes.fieldWrapper}>
              <Box className={classes.inputWrapper}>
                <CustomTextField
                  label={SmsImportUserStrings.CellNumber}
                  value={values.phoneNumber}
                  name="phoneNumber"
                  handleChange={(e) => {
                    handlePhoneNumberChange(e);
                    trackEvent(CommonEventNames.add_users_manually_cell_number_input_typed, {
                      value: e.target.value,
                    });
                  }}
                  error={getFieldError('phoneNumber')}
                  labelTextClassName={classes.inputLabel}
                  inputClassName={classes.input}
                  placeholder={SmsImportUserStrings.PhonePlaceholder}
                  onBlur={handleBlur}
                />
              </Box>
              <Box className={classes.inputWrapper}>
                {withInvite ? (
                  <Box className={classes.optInInviteWrapper}>
                    <Typography className={classes.optInLabel}>
                      {isHealthCare ? SmsImportUserStrings.OptInTrublu : SmsImportUserStrings.OptIn}
                    </Typography>
                    <Toggle
                      name="optedIn"
                      value={values.optedIn}
                      onChange={(checked) => {
                        setFieldValue('optedIn', checked);
                        setIsOptInTouched(true);
                        trackEvent(CommonEventNames.add_users_manually_opt_in_status_changed, {
                          value: checked ? 'checked' : 'unchecked',
                        });
                      }}
                      className={classes.toggle}
                    />
                  </Box>
                ) : (
                  <UploadVIdeoSelector
                    value={uploadVideoOption || 'No'}
                    onChange={(value: any) => {
                      onUploadVideoOptionChange && onUploadVideoOptionChange(value);
                    }}
                  />
                )}
              </Box>
            </Box>
            {!withInvite && (
              <Box className={classes.optInWrapper}>
                <Typography className={classes.optInLabel}>
                  {isHealthCare ? SmsImportUserStrings.OptInTrublu : SmsImportUserStrings.OptIn}
                </Typography>
                <Toggle
                  name="optedIn"
                  value={values.optedIn}
                  onChange={(checked) => {
                    setFieldValue('optedIn', checked);
                    setIsOptInTouched(true);
                    trackEvent(CommonEventNames.add_users_manually_opt_in_status_changed, {
                      value: checked ? 'checked' : 'unchecked',
                    });
                  }}
                  className={classes.toggle}
                />
              </Box>
            )}
            {isOptInTouched && errors['optedIn'] && (
              <Box display="flex" alignItems="center" marginTop="10px">
                <ReportProblemRounded
                  color="error"
                  style={{
                    height: '16px',
                    width: '16px',
                    marginRight: '8px',
                    marginLeft: 'auto',
                  }}
                />
                <Typography fontSize="11.5px" color="#FF6340">
                  {ErrorsStrings.FieldIsRequired}
                </Typography>
              </Box>
            )}
          </Box>

          <LoadingButton
            loading={isLoading}
            disabled={isLoading || !isValid}
            variant="contained"
            color="primary"
            size="large"
            style={{ borderRadius: '8px', margin: '32px 24px 0 24px', width: 'calc(100% - 48px)' }}
            fullWidth
            onClick={() => {
              submitForm();
              trackEvent(CommonEventNames.add_users_manually_import_data_button_click);
            }}
            id="add-individual-customer-button"
          >
            {getSubmitButtonTitle()}
          </LoadingButton>
        </Box>
      </Modal>
    </>
  );
};
