import { FC } from 'react';

export const PlayIconLaunchPad: FC = () => (
  <svg width="98" height="98" viewBox="0 0 98 98" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_b_1523_60776)">
      <circle cx="49.0005" cy="48.6899" r="48.6899" fill="white" fillOpacity="0.9" />
    </g>
    <path
      d="M32.0645 48.5755V39.9595C32.0645 28.8402 39.9236 24.3576 49.5293 29.8881L56.981 34.1961L64.4327 38.5041C74.0383 44.0347 74.0383 53.1164 64.4327 58.6469L56.981 62.9549L49.5293 67.2629C39.9236 72.7935 32.0645 68.2526 32.0645 57.1915V48.5755Z"
      fill="#A9A9A9"
    />
    <defs>
      <filter
        id="filter0_b_1523_60776"
        x="-3.68945"
        y="-4"
        width="105.381"
        height="105.38"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="2" />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_1523_60776" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_backgroundBlur_1523_60776"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);
