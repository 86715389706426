import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { useParams, useRouteMatch } from 'react-router-dom';
import {
  convertApiPageToFrontEndPage,
  convertFrontEndPageToApiPage,
} from '../../../../services/utilities';
import { useAppDispatch, useTypedSelector } from '../../../../store';
import { createUserDataTableContent } from '../../../../services/helpers/tableMappers';
import {
  fetchUsersBySearchFilterApiCall,
  getHeroes,
  getUsersBySearchFilter,
  goToSelectedPage,
  reset,
  setUserDataSorting,
  updateSearch,
  updateSize,
} from '../../../../store/slices/heroesSlice';
import { updateSize as updatePageSize } from '../../../../store/slices/videosSlice';
import { CommonTable } from '../../../../common/components/table/CommonTable';
import { ClientTypes } from '../../../../api/models/common';
import { useToasts } from 'react-toast-notifications';
import { approveReward } from '../../../../store/slices/userDetailsSlice';
import {
  NoFilterResultsStrings,
  HeroesPageStrings,
  NotFoundPageStrings,
  VenueCreationWizardStrings,
} from '../../../../common/localization/en';
import { setActiveFilter } from '../../../../store/slices/searchFiltersSlice';
import { useUsersTablesStyles } from './HeroesTable.style';
import { useClientType } from '../../../../services/hooks/useClientType';
import { useTrackEvent } from '../../../../hooks/useTrackEvent';
import { CreatorsTableEventNames } from '../../../../common/constants/events/creatorsTableEvents';
import { Box, Button, Grid } from '@mui/material';
import { AddCreatorIcon } from '../../../../common/assets/AddCreatorIcon';
import InviteCreatorModal from '../../../../common/components/InviteCreatorModal/InviteCreatorModal';
import { AddIndividualCustomerModal } from '../../../rewards/CampaignSummary/Modals/AddIndividualCustomerModal';
import { CampaignsApiModel } from '../../../../api/models/campaigns';
import { WebsiteRoutes } from '../../../../common/constants/routes';
import { UserStatuses } from '../../../../api/models/users';
import { getCampaigns } from '../../../../store/slices/campaignsSlice';
import { AddAndUploadModal } from '../../../rewards/CampaignSummary/Modals/AddAndUploadModal/AddAndUploadModal';
import { UploadVideoSelectOption } from '../../../rewards/CampaignSummary/CampaignSummary.helper';
import { useModal } from '../../../../hooks/useModal';
import {
  getSearchFilters,
  resetActiveCreatorFilterAttributes,
} from '../../../../store/slices/searchFilterSlice';
import { CreatorAttributeName, SearchTarget } from '../../../../api/models/searchFilterModels';
import { EmptyResultsPreview } from '../../../../common/components/EmptyResultsPreview/EmptyResultsPreview';
import { TableToolbar } from '../../../content/tableSection/TableToolbar/TableToolbar';
import { useJsonToCSV } from '../../../../hooks/useJsonToCSV';
import { userHeaders } from '../../../filters/CreatedFilter.helper';
import formatDate from 'date-fns/format';

interface HeroesTableProps {
  showActionBar: boolean;
  activeTab: number;
  tableType: 'active' | 'pending' | 'deactivated';
}

export const HeroesTable: FC<HeroesTableProps> = ({ showActionBar, activeTab, tableType }) => {
  const styles = useUsersTablesStyles();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { addToast } = useToasts();
  const match = useRouteMatch();
  const {
    isLoading,
    items: creatorItems,
    error,
    totalPages,
    page,
    size,
    sort,
    totalItems,
  } = useTypedSelector((state) => state.heroes);

  const { userDetails } = useTypedSelector((state) => state.userDetails);
  const { activeCreatorFilterAttributes, search, hasVideos } = useTypedSelector(
    (state) => state.searchFilter,
  );
  const accountId = useTypedSelector((state) => state.venue.venue.id);
  const { activeFilter } = useTypedSelector((state) => state.SearchFilterSlice);
  const clientType = useTypedSelector((state) => state.venue.venue.clientType);
  const { activeCampaign, items } = useTypedSelector((state) => state.campaigns);
  const [isRedeemPointsModal, setIsRedeemPointsModal] = useState(false);
  const [isInviteCreatorModalOpen, setIsInviteCreatorModalOpen] = useState(false);
  const [isCreatorInfoOpen, setIsCreatorInfoOpen] = useState(false);
  const params = useParams<{ id: string }>();
  const { isHealthCare } = useClientType();
  const [isMounted, setIsMounted] = useState(false);
  const [uploadVideoOption, setUploadVideoOption] = useState<UploadVideoSelectOption>('No');
  const [addedUserName, setAddedUserName] = useState('');
  const { isOpen: isUploadModal, open: openUploadModal, close: closeUploadModal } = useModal(false);

  const activeCampaigns = items
    .filter((campaign) => campaign.endedAt === null)
    .sort((a, b) => {
      return new Date(b.startedAt).getTime() - new Date(a.startedAt).getTime();
    });

  const [selectedCampaign, setSelectedCampaign] = useState(activeCampaigns[0]);
  const [activeInviteOption, setActiveInviteOption] = useState(0);

  const { trackEvent } = useTrackEvent();

  const toggleRedeemPointsModal = useCallback(async () => {
    setIsRedeemPointsModal(!isRedeemPointsModal);
  }, [isRedeemPointsModal]);

  const approveRewardHandler = useCallback(
    async (userId) => {
      const response = await dispatch(approveReward({ id: userId }));
      if (response.payload) {
        addToast(VenueCreationWizardStrings.RewardApprovedMessage, {
          appearance: 'success',
          autoDismissTimeout: 2500,
        });

        toggleRedeemPointsModal();
        return;
      }
      addToast(NotFoundPageStrings.Description2, { appearance: 'error' });
      dispatch(
        getUsersBySearchFilter({
          venueId: accountId,
          filter: activeCreatorFilterAttributes,
          hasVideos,
          pageable: {
            page,
            size,
            sort,
          },
          search,
        }),
      );

      toggleRedeemPointsModal();
    },
    [
      dispatch,
      addToast,
      accountId,
      activeCreatorFilterAttributes,
      hasVideos,
      page,
      size,
      sort,
      search,
      toggleRedeemPointsModal,
    ],
  );

  useEffect(() => {
    if (!selectedCampaign) {
      setSelectedCampaign(activeCampaigns[0]);
    }
  }, [activeCampaigns, selectedCampaign]);

  useEffect(() => {
    dispatch(getCampaigns({ accountId, pageable: { page: 0, size: 10000 } }));

    dispatch(
      getSearchFilters({
        accountId,
        target: SearchTarget.USERS,
      }),
    );
  }, [dispatch, accountId]);

  useEffect(() => {
    return () => {
      dispatch(setActiveFilter(null));
      dispatch(reset());
    };
  }, [dispatch]);

  useEffect(() => {
    if (!isMounted) {
      dispatch(reset());
      setIsMounted(true);
      return;
    }

    if (accountId) {
      dispatch(
        getUsersBySearchFilter({
          venueId: accountId,
          filter: activeCreatorFilterAttributes,
          hasVideos,
          pageable: {
            page,
            size,
            sort,
          },
          search,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, page, size, sort, search, accountId, userDetails, activeCreatorFilterAttributes]);

  useEffect(() => {
    if (history.location.search === '?new') {
      setIsInviteCreatorModalOpen(true);
      history.replace(WebsiteRoutes.Heroes);
    }
  }, [history]);

  const userDataTableContents = createUserDataTableContent({
    items: creatorItems,
    activeCampaign,
    venuType: clientType || ClientTypes.SALES,
    approveRewardHandler,
    toggleRedeemPointsModal,
    isRedeemPointsModal,
    renderCheckbox: activeFilter ? true : false,
    isHealthCare,
    tableType,
  });

  const onGoToPage = (targetPage: number) => {
    const convertedTargetPage = convertFrontEndPageToApiPage(targetPage);

    if (convertedTargetPage >= totalPages) {
      return;
    }

    dispatch(goToSelectedPage(convertedTargetPage));
  };

  const onSort = (name: string) => {
    dispatch(setUserDataSorting(name));

    trackEvent(CreatorsTableEventNames.SortTableClick.replace('name', name));
  };

  const onClickRow = (id: string) => {
    history.push(match.path.replace(':id', id));

    trackEvent(CreatorsTableEventNames.RowClick, { selectedUserId: id });
  };

  const changeSize = (size: number) => {
    dispatch(updateSize(size));
    dispatch(updatePageSize(size));
  };

  const handleSelectCampaign = (campaign: CampaignsApiModel) => {
    setSelectedCampaign(campaign);
  };

  const handleInviteCreatorNextStep = () => {
    setIsInviteCreatorModalOpen(false);
    setIsCreatorInfoOpen(true);
  };

  const handleUploadModalClose = () => {
    dispatch(
      getHeroes({
        search,
        accountId,
        status: UserStatuses.active,
        pageable: { page, size, sort },
        hasVideos: true,
      }),
    );
    closeUploadModal();
    setUploadVideoOption('No');
  };

  const showUploadModal = selectedCampaign?.id && uploadVideoOption === 'Yes';

  const nonDefaultFilters = useMemo(() => {
    return activeCreatorFilterAttributes.filter((item) => item.name !== 'status');
  }, [activeCreatorFilterAttributes]);

  const { jsonToCSV } = useJsonToCSV();

  const showTableToolbar = !!search || !!nonDefaultFilters.length;

  return (
    <>
      {showUploadModal && (
        <AddAndUploadModal
          campaignId={selectedCampaign.id}
          isOpen={isUploadModal}
          onClose={handleUploadModalClose}
          name={addedUserName}
          selectedCampaign={selectedCampaign}
        />
      )}

      <AddIndividualCustomerModal
        isOpen={isCreatorInfoOpen}
        onClose={() => {
          setIsCreatorInfoOpen(false);
          setActiveInviteOption(0);
          if (uploadVideoOption === 'Yes') {
            openUploadModal();
          }
        }}
        campaignId={selectedCampaign?.id}
        withInvite={activeInviteOption === 0}
        uploadVideoOption={uploadVideoOption}
        onUploadVideoOptionChange={(value) => {
          setUploadVideoOption(value);
        }}
        onCustomerAdded={(value) => setAddedUserName(value)}
      />
      <InviteCreatorModal
        isOpen={isInviteCreatorModalOpen}
        onClose={() => {
          setIsInviteCreatorModalOpen(false);
          setActiveInviteOption(0);
        }}
        onNextStep={handleInviteCreatorNextStep}
        campaigns={activeCampaigns}
        selectedCampaign={selectedCampaign}
        onCampaignSelect={handleSelectCampaign}
        activeInviteOption={activeInviteOption}
        onInviteOptionChange={(index: number) => setActiveInviteOption(index)}
      />

      {showTableToolbar && (
        <TableToolbar
          tableEntity="creator"
          itemsShowing={items.length}
          itemsTotal={totalItems}
          searchMode={true}
          hideKeywords
          onSearchResetClick={() => {
            dispatch(
              getUsersBySearchFilter({
                venueId: accountId,
                filter: activeCreatorFilterAttributes,
                hasVideos,
                pageable: {
                  page,
                  size,
                  sort,
                },
                search,
              }),
            );
          }}
          searchExportEnabled={totalItems < 100}
          onSearchExportClick={async () => {
            const data = await fetchUsersBySearchFilterApiCall({
              venueId: accountId,
              filter: activeCreatorFilterAttributes,
              hasVideos,
              pageable: {
                page: 0,
                size: 100,
                sort,
              },
              search,
            });

            jsonToCSV({
              data: data.items ?? [],
              headers: userHeaders,
              filename: `SocialV_Creators_${formatDate(new Date(), 'MMM-dd-y')}`,
            });
          }}
          onClearSearchClick={() => {
            dispatch(resetActiveCreatorFilterAttributes());
          }}
        />
      )}

      <Box className={styles.contentWrapper}>
        {showActionBar && (
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            className={styles.topBarWrapper}
          >
            {tableType === 'active' && (
              <>
                <Button
                  variant="outlined"
                  className={styles.addCreatorButton}
                  sx={{
                    top: showTableToolbar ? '-124px' : '-70px',
                  }}
                  startIcon={<AddCreatorIcon />}
                  onClick={() => setIsInviteCreatorModalOpen(true)}
                >
                  {isHealthCare ? HeroesPageStrings.AddPatient : HeroesPageStrings.AddCreator}
                </Button>
              </>
            )}
          </Grid>
        )}
        <Box className={styles.UsersTableStyles}>
          <CommonTable
            selectedRowId={params?.id || ''}
            content={userDataTableContents}
            page={convertApiPageToFrontEndPage(page)}
            sort={sort}
            totalItems={totalItems}
            totalPages={totalPages}
            isLoading={isLoading}
            noContent={error}
            tablePadding="0"
            goToPage={onGoToPage}
            onSortHeaderClick={onSort}
            onClickRow={onClickRow}
            size={size}
            onSizeChange={changeSize}
            activeFilter={Boolean(activeFilter)}
            withSearchBar={false}
            searchBarProps={{
              adaptiveWidth: true,
              entity: 'heroes',
              updateSearch,
            }}
            withCustomSearchBar={true}
            emptyTableCustomPreview={
              <EmptyResultsPreview
                title={NoFilterResultsStrings.NoFilterResultsCreatorsTitle}
                subtitle={NoFilterResultsStrings.NoFilterResultsSubTitle}
              />
            }
            showEmptyTableCustomPreview={
              (activeCreatorFilterAttributes.filter(
                (filter) => filter?.name !== CreatorAttributeName.Status,
              ).length > 0 ||
                search.length > 0) &&
              creatorItems.length === 0
            }
          />
        </Box>
      </Box>
    </>
  );
};
