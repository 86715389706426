import { CSSProperties, FC, useRef } from 'react';
import { makeStyles } from '@mui/styles';
import { Typography } from '@mui/material';
import TooltipMUI from '@mui/material/Tooltip';

import { TooltipCircle } from './CommonTable.style';

interface Props {
  padding: string;
  rounded: string;
  light: boolean;
  pointerEvents?: boolean;
  marginBottom: number;
  style?: CSSProperties;
  maxWidth?: string;
  width?: string;
}
const useStyles = makeStyles<any, Props>((theme) => ({
  arrow: ({ light }) => ({
    color: light ? theme.palette.common.white : '#344054',
    '&:before': {
      border: light ? `1px solid #e1e5ee` : 'none',
    },
  }),
  tooltip: ({ light, pointerEvents, maxWidth, width }) => ({
    backgroundColor: light ? theme.palette.common.white : '#344054',
    color: light ? '#667085' : theme.palette.common.white,
    maxWidth: maxWidth ? maxWidth : light ? 235 : 220,
    width: width,
    fontSize: 12,
    border: light ? '1px solid #dadde9' : 'none',
    padding: light ? '10px 16px' : '8px 4px',
    borderRadius: light ? '10px' : '2px',
    textAlign: 'left',
    pointerEvents: pointerEvents ? 'auto' : 'none',
  }),
  icon: {
    verticalAlign: 'middle',

    '&:hover': {
      fill: theme.palette.info.light,
    },
  },
  popper: ({ marginBottom, style }) =>
    style
      ? {
          marginBottom: `${marginBottom - 10}px !important`,
          ...style,
        }
      : {},
}));

export function HtmlTooltip({
  followCursor = true,
  style,
  placement = 'top',
  disablePortal = false,
  light,
  isOpen,
  maxWidth,
  ...props
}: {
  children: JSX.Element;
  title: JSX.Element | string;
  rounded: string;
  padding: string;
  light: boolean;
  isOpen?: boolean;
  interactive?: boolean;
  followCursor?: boolean;
  style?: CSSProperties;
  pointerEvents?: boolean;
  placement?: SharedTooltipProps['placement'];
  maxWidth?: string;
  disablePortal?: boolean;
  width?: string;
}) {
  const ref = useRef();
  const classes = useStyles({
    rounded: props.rounded,
    padding: props.padding,
    light: light,
    pointerEvents: props.pointerEvents,
    marginBottom: Number((ref?.current as any)?.getBoundingClientRect().top || 0) * 0.1,
    style: style,
    maxWidth: maxWidth,
    width: props.width,
  });

  return (
    <TooltipMUI
      ref={ref}
      arrow
      placement={placement}
      classes={classes}
      {...props}
      followCursor={followCursor}
      disableInteractive={!props.interactive}
      open={isOpen}
      PopperProps={{
        disablePortal: disablePortal,
      }}
    />
  );
}

interface SharedTooltipProps {
  text?: string;
  rounded?: string;
  padding?: string;
  icon?: JSX.Element;
  light?: boolean;
  isOpen?: boolean;
  followCursor?: boolean;
  maxWidth?: string;
  borderRadius?: string;
  disablePortal?: boolean;
  width?: string;
  fontSize?: string;
  fontWeight?: number;
  placement?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top';
  iconClassName?: string;
}

export interface TooltipTitleProps extends SharedTooltipProps {
  content?: never;
  title: string;
}

export interface TooltipContentProps extends SharedTooltipProps {
  content: JSX.Element;
  title?: never;
}

type TooltipProps = TooltipTitleProps | TooltipContentProps;

// TODO: Move to shared components -> /common/components/Tooltip
export const Tooltip: FC<TooltipProps> = ({
  title,
  content,
  text,
  icon,
  rounded = '0',
  padding = '0',
  isOpen,
  followCursor,
  placement = 'top',
  maxWidth,
  width,
  light,
  disablePortal,
  fontSize,
  fontWeight,
}) => {
  const isLight =
    light !== undefined
      ? light
      : content
      ? true
      : title
      ? title.split(' ').length > 4
        ? true
        : false
      : false;

  return (
    <HtmlTooltip
      rounded={rounded}
      padding={padding}
      placement={placement}
      title={
        title ? (
          <>
            <Typography variant="body2" color="inherit" fontSize={fontSize} fontWeight={fontWeight}>
              {title}
            </Typography>
            {text && (
              <>
                <br />
                <Typography variant="caption" color="inherit">
                  {text}
                </Typography>
              </>
            )}
          </>
        ) : content ? (
          content
        ) : (
          ''
        )
      }
      light={isLight}
      isOpen={isOpen}
      followCursor={followCursor}
      maxWidth={maxWidth}
      disablePortal={disablePortal}
      width={width}
    >
      {icon ? (
        icon
      ) : (
        <TooltipCircle>
          <span>?</span>
        </TooltipCircle>
      )}
    </HtmlTooltip>
  );
};
