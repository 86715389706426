import { Close } from '@mui/icons-material';
import { Box, Modal, Typography } from '@mui/material';
import { FC, useEffect } from 'react';
import VideoUploader from '../../../../../common/components/VideoUploader/VideoUploader';
import { useTrackEvent } from '../../../../../hooks/useTrackEvent';
import { useStyles } from './AddAndUploadModal.styles';
import { useAppDispatch, useTypedSelector } from '../../../../../store';
import { setLatestAddedCampaignUserId } from '../../../../../store/slices/campaignsSlice';
import { CreatorsTableEventNames } from '../../../../../common/constants/events/creatorsTableEvents';
import { CampaignsApiModel } from '../../../../../api/models/campaigns';

interface AddAndUploadModalProps {
  isOpen: boolean;
  onClose: () => void;
  onUploaded?: () => void;
  campaignId: string;
  name: string;
  selectedCampaign?: CampaignsApiModel;
  withInvite?: boolean;
}

export const AddAndUploadModal: FC<AddAndUploadModalProps> = ({
  isOpen,
  onClose,
  campaignId,
  selectedCampaign,
  withInvite = true,
  name,
  onUploaded,
}) => {
  const classes = useStyles();
  const { trackEvent } = useTrackEvent();
  const dispatch = useAppDispatch();

  const { currentCampaign } = useTypedSelector((state) => state.campaigns);
  const disableFileUploader = currentCampaign && !!currentCampaign.endedAt;
  const latestAddedCampaignUserId = useTypedSelector(
    (state) => state.campaigns.latestAddedCampaignUserId,
  );

  useEffect(() => {
    return () => {
      dispatch(setLatestAddedCampaignUserId(undefined));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Modal
        open={isOpen}
        onClose={() => {
          onClose();
        }}
        style={{
          background: 'rgba(26, 21, 56, 0.5)',
          backdropFilter: 'blur(2.5px)',
          width: '100%',
        }}
        sx={{
          '& .MuiBackdrop-root': {
            backgroundColor: 'transparent !important',
          },
        }}
      >
        <Box className={classes.inputsWrapper}>
          <button
            onClick={() => {
              onClose();
              trackEvent(CreatorsTableEventNames.CreatorDetailsModalStoryCloseClick);
            }}
            className={classes.closeIcon}
          >
            <Close />
          </button>
          <Typography className={classes.title}>Upload Clip (optional)</Typography>
          <Box className={classes.fieldsWrapper}>
            {latestAddedCampaignUserId && (
              <VideoUploader
                userId={latestAddedCampaignUserId}
                onFileSelect={() => {}}
                isCampaignUser={true}
                campaignId={campaignId}
                disabled={!!disableFileUploader}
                inviteModal
                userName={name}
                currentCampaign={selectedCampaign || currentCampaign}
                closeModal={onClose}
                onUploaded={onUploaded}
              />
            )}
          </Box>
        </Box>
      </Modal>
    </>
  );
};
