import { Box, Typography } from '@mui/material';
import { format } from 'date-fns';
import React from 'react';

interface SendInviteTimeoutCardProps {
  nextSendDate: Date;
}

export const SendInviteTimeoutCard: React.FC<SendInviteTimeoutCardProps> = ({ nextSendDate }) => {
  return (
    <Box
      sx={{
        width: '100%',
        borderRadius: '20px',
        border: '3px solid #0BA5ECB2',
        background: '#F0F9FF',
        padding: '24px',
      }}
    >
      <Typography
        sx={{
          textAlign: 'center',
          fontSize: '28px',
          color: '#026AA2',
          fontWeight: '500',
          lineHeight: '100%',
          fontFamily: 'Inter',
          marginBottom: '6px',
        }}
      >
        Next Send Date
      </Typography>
      <Typography
        sx={{
          textAlign: 'center',
          fontSize: '68px',
          color: '#026AA2',
          fontWeight: '800',
          lineHeight: '100%',
          fontFamily: 'Inter',
        }}
      >
        {nextSendDate ? format(new Date(nextSendDate), 'MMM dd') : null}
      </Typography>
    </Box>
  );
};
