const VIDEO_CONTENT_inspirational = `Hey [Customer Name], thank you for being a valued customer of [Company Name]!

Become a prolific video creator & inspire others! Record a video & share it using this link:`;

const SHOWCASE_inspirational = `Hey [Customer Name], thank you for being a valued customer of [Company Name]!

Create one of our most viewed videos & inspire others! Record a video & share it using this link:`;

const SHARING_inspirational = `Hey [Customer Name], thank you for being a valued customer of [Company Name]!

Create one of our most popular videos & inspire others! 

Record a video & share it using this link:`;

const VIDEO_CONTENT_experiential = `Hey [Customer Name], thank you for being a valued customer of [Company Name]!

Be a prolific video creator & inspire others with your memorable experiences!

Record a video & share it using this link:`;

const SHOWCASE_experiential = `Hey [Customer Name], thank you for being a valued customer of [Company Name]!

Create one of our most viewed videos & inspire others with your memorable experience!

Record a video & share it using this link:`;

const SHARING_experiential = `Hey [Customer Name], thank you for being a valued customer of [Company Name]!

Create one of our most popular videos & inspire others with your memorable experience!

Record a video & share it using this link:`;

const VIDEO_CONTENT_funny = `Hey [Customer Name], thank you for being a valued customer of [Company Name]!

Be a prolific video creator & inspire others with your sense of humor!

Record a video & share it using this link:`;

const SHOWCASE_funny = `Hey [Customer Name], thank you for being a valued customer of [Company Name]!

Create one of our most viewed videos & inspire others with your sense of humor!

Record a video & share it using this link:`;

const SHARING_funny = `Hey [Customer Name], thank you for being a valued customer of [Company Name]!

Create one of our most popular videos & inspire others with your sense of humor!

Record a video & share it using this link:`;

const defaultInvite = `Hi [Customer Name], it's [Company Name]. We’d love to hear YOUR story! Share your experience with a video clip. 

Your voice matters to us!

Tap here to upload your clip:`;

export const awareness_default_sms_invite: Record<string, string> = {
  VIDEO_CONTENT_inspirational,
  SHOWCASE_inspirational,
  SHARING_inspirational,

  VIDEO_CONTENT_experiential,
  SHOWCASE_experiential,
  SHARING_experiential,

  VIDEO_CONTENT_funny,
  SHOWCASE_funny,
  SHARING_funny,

  defaultInvite,
};

export const awareness_default_sms_invite_TruBlu: Record<string, string> = {
  VIDEO_CONTENT_inspirational: VIDEO_CONTENT_inspirational.replaceAll(
    '[Customer Name]',
    '[Patient Name]',
  )
    .replaceAll('[Company Name]', '[Practice Name]')
    .replaceAll('customer', 'patient'),
  SHOWCASE_inspirational: SHOWCASE_inspirational.replaceAll('[Customer Name]', '[Patient Name]')
    .replaceAll('[Company Name]', '[Practice Name]')
    .replaceAll('customer', 'patient'),
  SHARING_inspirational: SHARING_inspirational.replaceAll('[Customer Name]', '[Patient Name]')
    .replaceAll('[Company Name]', '[Practice Name]')
    .replaceAll('customer', 'patient'),

  VIDEO_CONTENT_experiential: VIDEO_CONTENT_experiential.replaceAll(
    '[Customer Name]',
    '[Patient Name]',
  )
    .replaceAll('[Company Name]', '[Practice Name]')
    .replaceAll('customer', 'patient'),
  SHOWCASE_experiential: SHOWCASE_experiential.replaceAll('[Customer Name]', '[Patient Name]')
    .replaceAll('[Company Name]', '[Practice Name]')
    .replaceAll('customer', 'patient'),
  SHARING_experiential: SHARING_experiential.replaceAll('[Customer Name]', '[Patient Name]')
    .replaceAll('[Company Name]', '[Practice Name]')
    .replaceAll('customer', 'patient'),

  VIDEO_CONTENT_funny: VIDEO_CONTENT_funny.replaceAll('[Customer Name]', '[Patient Name]')
    .replaceAll('[Company Name]', '[Practice Name]')
    .replaceAll('customer', 'patient'),
  SHOWCASE_funny: SHOWCASE_funny.replaceAll('[Customer Name]', '[Patient Name]')
    .replaceAll('[Company Name]', '[Practice Name]')
    .replaceAll('customer', 'patient'),
  SHARING_funny: SHARING_funny.replaceAll('[Customer Name]', '[Patient Name]')
    .replaceAll('[Company Name]', '[Practice Name]')
    .replaceAll('customer', 'patient'),

  defaultInvite: defaultInvite
    .replaceAll('[Customer Name]', '[Patient Name]')
    .replaceAll('[Company Name]', '[Practice Name]')
    .replaceAll('customer', 'patient'),
};
