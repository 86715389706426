import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useSendInvitesTabStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '732px',
  },
  title: {
    color: theme.palette.primary.main,
    fontSize: '30px',
    fontWeight: '600',
    textAlign: 'center',
  },
  subtitle: {
    color: '#475467',
    fontSize: '18px',
    textAlign: 'center',
    marginBottom: '30px',
  },
  inviteCardsContainer: {
    display: 'flex',
    gap: '30px',
  },
  tipsContainer: {
    marginTop: '26px',
  },
  tipsTitle: {
    color: '#475467',
    fontSize: '20px',
    fontWeight: '600',
  },
  tipsList: {
    listStyleType: 'disc',
    paddingLeft: '24px',
  },
  tipListItem: {
    color: '#475467',
    fontSize: '18px',
    fontWeight: '400',
    margin: '8px 0',
  },
  tipListItemLink: {
    padding: 0,
    fontSize: '18px',
  },
  buttonContainer: {
    marginTop: '34px',
  },
}));
