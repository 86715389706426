import { Box, Button, Modal, Typography } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import { MESSAGE_MAX_CHARACTERS } from '../../../pages/integrations/SmsInvite/SmsInvite.helper';
import { useSmsTemplateVariables } from '../../../services/hooks/useSmsTemplateVariables';
import { useAppDispatch, useTypedSelector } from '../../../store';
import { resetNewCampaign, setInviteMediaUrl } from '../../../store/slices/createCampaignSlice';
import { theme } from '../../../theme';
import {
  CommonStrings,
  CustomizeInvitationsModalStrings,
  ErrorsStrings,
} from '../../localization/en';
import { InvitationSMSImageUploader } from '../ImageUploader/uploaders/InvitationSMSImageUploader';
import { MessageBuilder } from '../MessageBuilder/MessageBuilder';
import {
  LocalMessageVariables,
  replaceMessageBodyWithApiVariables,
  replaceMessageBodyWithLocalVariables,
} from '../SMS/SMS.helper';
import { useModalsStyles } from './CusomizeInvitationsModal.style';
import { getCampaignById, patchIncentiveCampaign } from '../../../store/slices/campaignsSlice';
import { InvitationSMSConfig, PatchIncentiveCampaignApiModel } from '../../../api/models/campaigns';
import { IPhoneHalfPreview } from '../IPhonePreview/IPhoneHalfPreview';
import { MessageBox } from '../IPhonePreview/IPhoneSMSPreview';
import { useClientType } from '../../../services/hooks/useClientType';

type CustomizeInvitationsModalProps = {
  isOpenModal: boolean;
  onClose: () => void;
};

export const CustomizeInvitationsModal: FC<CustomizeInvitationsModalProps> = ({
  isOpenModal,
  onClose,
}) => {
  const styles = useModalsStyles();
  const { currentCampaign, isLoading } = useTypedSelector((state) => state.campaigns);
  const { inviteMediaUrl } = useTypedSelector((state) => state.createCampaign);
  const { isHealthCare } = useClientType();

  const dispatch = useAppDispatch();

  const [smsInviteMessage, setSmsInviteMessage] = useState('');

  const templateVariables = useSmsTemplateVariables({
    ignoreVariables: currentCampaign?.isNullReward ? [LocalMessageVariables.RewardName] : [],
    rewardName: currentCampaign?.rewardTitle || '',
  });

  const inviteMessagePreview = replaceMessageBodyWithLocalVariables(
    smsInviteMessage || '',
    isHealthCare,
    true,
  );

  useEffect(() => {
    dispatch(setInviteMediaUrl(currentCampaign?.invitationMsgConfig?.mediaUrl || ''));
    setSmsInviteMessage(currentCampaign?.invitationMsgConfig?.body!);
  }, [dispatch, currentCampaign]);

  const handleUpdate = async () => {
    if (currentCampaign) {
      const invitationMsgConfig: InvitationSMSConfig = {
        mediaUrl: inviteMediaUrl,
        body: replaceMessageBodyWithApiVariables(smsInviteMessage, true),
      };

      const patchData: PatchIncentiveCampaignApiModel = {
        invitationMsgConfig,
      };

      await dispatch(patchIncentiveCampaign({ campaignId: currentCampaign.id, values: patchData }));
      dispatch(getCampaignById(currentCampaign.id));
      dispatch(resetNewCampaign());
    }
  };

  const handleConfirmClick = async () => {
    await handleUpdate();
    onClose();
  };

  const isMessageChanged =
    currentCampaign?.invitationMsgConfig?.body !== smsInviteMessage && smsInviteMessage !== '';

  const isMediaChanged = currentCampaign?.invitationMsgConfig?.mediaUrl !== inviteMediaUrl;
  const isContentChanged = isMessageChanged || isMediaChanged;

  const handleClose = () => {
    setSmsInviteMessage(currentCampaign?.invitationMsgConfig?.body!);
    dispatch(setInviteMediaUrl(currentCampaign?.invitationMsgConfig?.mediaUrl || ''));
    onClose();
  };

  return (
    <Modal
      open={isOpenModal}
      style={{
        background: 'rgba(26, 21, 56, 0.5)',
        backdropFilter: 'blur(2.5px)',
        width: '100%',
      }}
      sx={{
        '& .MuiBackdrop-root': {
          backgroundColor: 'transparent !important',
        },
      }}
    >
      <Box className={styles.modalContent}>
        <Typography className={styles.title}>
          {CustomizeInvitationsModalStrings.ModalTitle}
        </Typography>
        <Box className={styles.content}>
          <Box className={styles.invitationContent}>
            <Typography className={styles.invitationTitle}>
              {CustomizeInvitationsModalStrings.Title}
            </Typography>

            <InvitationSMSImageUploader
              title={'Select an Image'}
              subtitle={'Adding a familiar picture boosts video creation.'}
              imagePreviewWidth="202px"
              imagePreviewHeight="162px"
              alignment="flex-start"
              imagePadding={5}
              editInvitationModal
            />

            <Box className={styles.messageWrapper}>
              <MessageBuilder
                buttons={Object.keys(templateVariables).map((key) => ({
                  title: key,
                  value: key,
                }))}
                message={inviteMessagePreview}
                disabled={false}
                onMessageChange={async (message: string) => {
                  setSmsInviteMessage(message);
                }}
                borderRadius={'5px'}
                background={`${theme.palette.primary.main}0f`}
                squareBrackets
                errorText={!smsInviteMessage?.trim() ? ErrorsStrings.FieldIsRequired : ''}
                limit={MESSAGE_MAX_CHARACTERS}
                withUploadImageButton={false}
                isMms={!!inviteMediaUrl}
                maxHeight="150px"
              />
            </Box>
          </Box>
          <Box>
            <Box className={styles.phonePreviewWrapper} style={{ minWidth: '295px' }}>
              <IPhoneHalfPreview height={900}>
                {inviteMediaUrl && (
                  <img className={styles.inviteImagePhone} src={inviteMediaUrl} alt="invite" />
                )}
                <Box className={styles.messageBoxWrapper}>
                  <MessageBox
                    width="330px"
                    fontSize={18}
                    message={inviteMessagePreview}
                    linkTitle={CustomizeInvitationsModalStrings.PersonalizeLink}
                    boldSubstrings={Object.values(LocalMessageVariables)}
                    showStartBlock={false}
                  />
                </Box>
              </IPhoneHalfPreview>
            </Box>
          </Box>
        </Box>
        <Box className={styles.buttons}>
          <Button fullWidth variant="outlined" onClick={handleClose}>
            {CommonStrings.Cancel}
          </Button>
          <Button
            fullWidth
            variant="contained"
            onClick={handleConfirmClick}
            disabled={isLoading || !isContentChanged}
          >
            {CustomizeInvitationsModalStrings.ConfirmChangesButton}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
